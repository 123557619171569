import React from "react";
import GradientIcon from "../../../Assets/img/gradient.png";

const CondicionalsMultipleChoice = (props) => {
  const handleBackgroundHexColor = (hexcolor) => {
    const r = parseInt(hexcolor.substr(1, 2), 16);
    const g = parseInt(hexcolor.substr(3, 2), 16);
    const b = parseInt(hexcolor.substr(5, 2), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    const textColor = yiq >= 128 ? "black" : "white";

    props.handleColor(props.questionNumber, props.index, hexcolor, textColor);
  };

  return (
    <div key={props.index} className="options">
      {props.isNumerical && (
        <div
          className="editOnlyColor"
          onClick={() => props.handleClickInput("inputColor" + props.index)}
        >
          <img src={GradientIcon} alt="icon gradiente color" />
          <input
            id={"inputColor" + props.index}
            onChange={(e) => handleBackgroundHexColor(e.target.value)}
            type="color"
            value={props.option.color}
          />
        </div>
      )}
      <div
        className="option"
        style={{
          backgroundColor: props.option.color,
          color:
            props.option.textColor !== undefined
              ? props.option.textColor
              : "white",
        }}
      >
        {props.option.value}
      </div>
    </div>
  );
};

export default CondicionalsMultipleChoice;
