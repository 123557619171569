import React from "react";
import { Route, Redirect } from "react-router-dom";
import { Auth } from "../Services/AuthService";
import Loading from "../Components/Loading";

export default class LoginRoute extends React.Component {
  state = {
    loading: true,
    isAuthenticated: false,
  };

  componentDidMount = () => {
    Auth.onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          loading: false,
          isAuthenticated: true,
        });
      } else {
        this.setState({
          loading: false,
          isAuthenticated: false,
        });
      }
    });
  };

  render() {
    const { component: Component, ...rest } = this.props;
    if (this.state.loading) {
      return <Loading />;
    } else {
      return (
        <Route
          {...rest}
          render={(props) => (
            <>
              {this.state.isAuthenticated && (
                <Redirect
                  to={{
                    pathname: "/projects",
                    state: { from: this.props.location },
                  }}
                />
              )}
              <Component {...this.props} />
            </>
          )}
        />
      );
    }
  }
}
