import React, { useContext, useEffect, useRef, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import ChatbotBody from "../../Components/ChatbotBody/";
import Loading from "../../Components/Loading";
import ProjectsService from "../../Services/ProjectsService";

const BotMaker = () => {
  let { projectId } = useParams();
  const { setChatbot, userInformation } = useContext(Context);
  const [link, setLink] = useState(null);
  const [loading, setLoading] = useState(true);
  const [project, setProject] = useState(null);
  const [usedNumbers, setUsedNumbers] = useState([]);

  const pdfRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => pdfRef.current,
  });

  useEffect(() => {
    const getProjectInformation = async () => {
      let validationProjectIdExists = [];

      if (userInformation.subscription !== "IdssManager") {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element === projectId ? element : null;
          }
        );
      } else {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element.idProject === projectId ? element : null;
          }
        );
      }

      if (
        validationProjectIdExists.length > 0 ||
        userInformation.subscription === "IdssManager"
      ) {
        const userProject = await ProjectsService.getSpecificProject(projectId);
        setProject(userProject[0]);

        let chatbotParsed = JSON.parse(userProject[0].json);
        setChatbot(chatbotParsed);
        setLink(
          userProject[0].link !== undefined ? userProject[0].link : projectId
        );

        if (userProject[0].usedNumbers === undefined) {
          let arrayNumbers = [];
          for (let i = 0; i < chatbotParsed.questions.length; i++) {
            arrayNumbers.push(chatbotParsed.questions[i].number);
          }
          setUsedNumbers(arrayNumbers);
        } else {
          setUsedNumbers(userProject[0].usedNumbers);
        }
        setLoading(false);
      }
    };

    if (userInformation) {
      getProjectInformation();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div id="compBotmaker" ref={pdfRef}>
        <ChatbotBody
          generatePdf={() => handlePrint()}
          project={project}
          projectId={projectId}
          projectLink={link}
          setUsedNumbers={setUsedNumbers}
          usedNumbers={usedNumbers}
        />
      </div>
    );
  }
};

export default BotMaker;
