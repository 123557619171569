import React from "react";
import "./style.scss";
import { Modal, ModalHeader } from "reactstrap";
import Afirmation from "../../../Assets/img/iconsPreset/maybe-50.png";
import Agree from "../../../Assets/img/iconsPreset/handshake-50.png";
import ANS from "../../../Assets/img/iconsPreset/age-50.png";
import CES from "../../../Assets/img/iconsPreset/weight-50.png";
import CSAT from "../../../Assets/img/iconsPreset/satisfaction-50 (1).png";
import CSATNUM from "../../../Assets/img/iconsPreset/satisfaction-50.png";
import filterByQuestion from "./presets";
import IBGE from "../../../Assets/img/iconsPreset/age-50 (1).png";
import Importance from "../../../Assets/img/iconsPreset/podium-50.png";
import knowledge from "../../../Assets/img/iconsPreset/brain-50.png";
import NPS from "../../../Assets/img/iconsPreset/speed-50 (1).png";

const ModalPresets = (props) => {
  const toggle = () => {
    props.setModal(!props.modal);
  };

  const handleSetQuestion = (typeOfPreset) => {
    let arrayTemp = [...props.questionValues.questions];

    const presets = filterByQuestion(typeOfPreset)[0];
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      ...presets,
    };

    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });

    toggle();
  };

  return (
    <Modal
      className="compModalPresets"
      isOpen={props.modal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">
        <div className="containerPreset">
          <h3>Indicadores</h3>
          <div className="presetContent">
            <div className="boxPresets">
              <div className="preset" onClick={() => handleSetQuestion("NPS")}>
                <img alt="NPS" src={NPS} />
                <div className="groupTitles">
                  <p className="pTitle">NPS</p>
                  <p className="pSubtitle">Lealdade / Saldo de Promoção</p>
                  <p className="pSubtitle">(Net Promoter Score)</p>
                </div>
              </div>
              <div className="preset" onClick={() => handleSetQuestion("CES")}>
                <img alt="CES" src={CES} />
                <div className="groupTitles">
                  <p className="pTitle">CES</p>
                  <p className="pSubtitle">Nível de Esforço</p>
                  <p className="pSubtitle">(Customer Effort Score)</p>
                </div>
              </div>
            </div>
            <div className="boxPresets">
              <div className="preset" onClick={() => handleSetQuestion("CSAT")}>
                <img alt="CSAT" src={CSAT} />
                <div className="groupTitles">
                  <p className="pTitle">CSAT</p>
                  <p className="pSubtitle">Satisfação Geral</p>
                  <p className="pSubtitle">(Escala Objetiva)</p>
                </div>
              </div>
              <div
                className="preset"
                onClick={() => handleSetQuestion("CSATNUM")}
              >
                <img alt="CSAT Numérico" src={CSATNUM} />
                <div className="groupTitles">
                  <p className="pTitle">CSAT</p>
                  <p className="pSubtitle">Satisfação Geral</p>
                  <p className="pSubtitle">(Escala Numérica)</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerPreset">
          <h3>Escala de Likert</h3>
          <div className="presetContent">
            <div className="boxPresets">
              <div
                className="preset"
                onClick={() => handleSetQuestion("Concordancia")}
              >
                <img alt="Concordância" src={Agree} />
                <div className="groupTitles">
                  <p className="pTitle">Concordância</p>
                </div>
              </div>
              <div
                className="preset"
                onClick={() => handleSetQuestion("Importancia")}
              >
                <img alt="Importância" src={Importance} />
                <div className="groupTitles">
                  <p className="pTitle">Importância</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerPreset">
          <h3>Caracterização Idade</h3>
          <div className="presetContent">
            <div className="boxPresets">
              <div className="preset" onClick={() => handleSetQuestion("IBGE")}>
                <img alt="IBGE" src={IBGE} />
                <div className="groupTitles">
                  <p className="pTitle">Faixa Etária</p>
                  <p className="pSubtitle">(IBGE)</p>
                </div>
              </div>
              <div className="preset" onClick={() => handleSetQuestion("ANS")}>
                <img alt="ANS" src={ANS} />
                <div className="groupTitles">
                  <p className="pTitle">Faixa Etária</p>
                  <p className="pSubtitle">(ANS)</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="containerPreset">
          <h3>Outros</h3>
          <div className="presetContent">
            <div className="boxPresets">
              <div
                className="preset"
                onClick={() => handleSetQuestion("Conhecimento")}
              >
                <img alt="Conhecimento" src={knowledge} />
                <div className="groupTitles">
                  <p className="pTitle">Nível de Conhecimento</p>
                </div>
              </div>
              <div
                className="preset"
                onClick={() => handleSetQuestion("Afirmativa")}
              >
                <img alt="Afirmativa" src={Afirmation} />
                <div className="groupTitles">
                  <p className="pTitle">Pergunta Afirmativa</p>
                  <p className="pSubtitle">(Sim/Não/Não sei)</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalPresets;
