import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/Context";
import parse from "html-react-parser";
import BoxAreaReports from "../../Components/BoxAreaReports";
import ChartReport from "../../Components/ChartReport";
import LineChart from "../../Components/ChartReport/AnswerChart";
import UpDown from "../../Assets/img/iconsSort/upDown.png";
import FilterOutline from "../../Assets/img/filter-outline.svg";
import ReportService from "../../Services/ReportService";
import "./style.scss";
import ModalSortData from "../Modals/ModalSortData";
import ModalFilterQuestion from "../Modals/ModalFilterQuestion";
import ProjectsService from "../../Services/ProjectsService";
import Loader from "react-loader-spinner";

const getWindowDimensions = () => {
  const { innerWidth: width, outerHeight: height } = window;
  return {
    width,
    height,
  };
};

const ChartsAndTables = (props) => {
  const {
    changeKeysToEmojis,
    dataFirebase,
    dateMin,
    dateMax,
    handleShowTable,
    projectId,
    reportData,
    engagementData,
    setEngagementData,
    showTables,
    setTypeOfChart,
    selectedQuestion,
    setSelectedQuestion,
    typeOfChart,
  } = props;
  const {
    userInformation,
    userProjectsQtdAnswers,
    setDataTableXlsx,
    handleTotalInteractions,
    getNumberQuestionInDictionary,
    loadingEngagement,
    setLoadingEngagement,
  } = useContext(Context);
  const [filterConsolidate, setFilterConsolidate] = useState("day");
  const [windowDimensions, setWindowDimensions] = useState(null);
  const [loading, setLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState([]);
  const [reportChartsRef, setReportChartsRef] = useState("");
  const history = useHistory();

  const [openModal, setOpenModal] = useState(false);
  const [sortType, setSortType] = useState("");
  const [filterOptions, setFilterOptions] = useState([]);
  const [dataQuestions, setDataQuestions] = useState([]);
  const [allComments, setAllComments] = useState([]);
  const [showModalFilterQuestion, setShowModalFilterQuestion] = useState(false);
  const [questionStringModal, setQuestionStringModal] = useState("");

  useEffect(() => {
    const getReportCharts = async (dataFirebaseTmp) => {
      let response = await ReportService.getReportCharts(projectId);
      let charts = { answers: "LineChart" };
      if (!response) {
        for (const question in dataFirebaseTmp.questions) {
          charts = {
            ...charts,
            [dataFirebaseTmp.questions[question].number]: "BarChart",
          };
        }
        await ReportService.createReportCharts(charts, projectId);
        setTypeOfChart(charts);
        response = await ReportService.getReportCharts(projectId);
        setReportChartsRef(response.docRef);
      } else {
        setTypeOfChart(response.data);
        setReportChartsRef(response.docRef);
      }

      const allCommentsJson = await ReportService.getComments(response.docRef);
      setDataQuestions(allCommentsJson);
    };

    const getDataTableXlsx = async () => {
      let data = await getTablesToXlsx();
      setDataTableXlsx(data);
    };

    if (userInformation && dataFirebase) {
      getReportCharts(dataFirebase).then(() => {
        getDataTableXlsx();
      });
    }
  }, [
    dataFirebase,
    userInformation,
    projectId,
    setTypeOfChart,
    allComments,
    setDataTableXlsx,
  ]);

  useEffect(() => {
    const getFirebaseFilters = async () => {
      let response = await ReportService.getReportCharts(projectId);

      if (response.docRef) {
        let filtersFirebase = await ReportService.getFilter(response.docRef);

        if (filtersFirebase) {
          setFilterOptions(filtersFirebase);
        }
      }
    };
    return getFirebaseFilters();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    const handleResize = () => {
      let dimensions = getWindowDimensions();
      setWindowDimensions(dimensions);
      setLoading(false);
    };

    window.addEventListener("resize", handleResize);
    return handleResize();
  }, []);

  useEffect(() => {
    const getDataQuestion = async () => {
      if (selectedQuestion) {
        await getDataWithFilterQuestion(selectedQuestion, true);
      }
    };

    getDataQuestion();
    // eslint-disable-next-line
  }, []);

  const getTablesToXlsx = async () => {
    let tables = document.getElementsByClassName("table");
    let tablesRowsConsolidated = [];

    for (let i = 0; i < tables.length; i++) {
      let trHeader = tables[i].tHead.children;
      let trBody = tables[i].tBodies[0].children;
      let title = (
        <tr>
          <td>{tables[i].getAttribute("name")}</td>
        </tr>
      );

      tablesRowsConsolidated = [
        ...tablesRowsConsolidated,
        title,
        ...Array.from(trHeader).map((item) => parse(item.outerHTML)),
        ...Array.from(trBody).map((item) => parse(item.outerHTML)),
        <tr></tr>,
        <tr></tr>,
      ];
    }

    let array = [];
    for (let i = 0; i < tablesRowsConsolidated.length; i++) {
      let objTemp = { ...tablesRowsConsolidated[i], key: i };
      array.push(objTemp);
    }
    return array;
  };

  const handleFilterChange = (questionString, options) => {
    const filters = { ...filterOptions, [questionString]: options };
    setFilterOptions(filters);
    ReportService.updateFilters(reportChartsRef, filters);
  };

  const showChartsAndTables = () => {
    let contentToRender = [];

    for (const key in dataFirebase.questions) {
      let questionString =
        dataFirebase.questions[key].number < 10
          ? `q_0${dataFirebase.questions[key].number}`
          : `q_${dataFirebase.questions[key].number}`;

      let dataToChart = null;

      switch (dataFirebase.questions[key].type) {
        case "insertImage":
          contentToRender.push(
            <BoxAreaReports
              reportChartsRef={reportChartsRef}
              hideFilter={true}
              key={key}
              questionPosition={Number(key) + 1}
              question={dataFirebase.questions[key]}
              setTypeOfChart={setTypeOfChart}
              typeOfChart={typeOfChart}
              dataQuestions={dataQuestions}
              allComments={allComments}
              setAllComments={setAllComments}
            >
              <div className="chartArea">
                <img alt="robot" src={dataFirebase.questions[key].options} />
              </div>
            </BoxAreaReports>
          );
          break;

        case "insertParagraph":
          contentToRender.push(
            <BoxAreaReports
              reportChartsRef={reportChartsRef}
              hideFilter={true}
              key={key}
              questionPosition={Number(key) + 1}
              question={dataFirebase.questions[key]}
              setTypeOfChart={setTypeOfChart}
              typeOfChart={typeOfChart}
              dataQuestions={dataQuestions}
              allComments={allComments}
              setAllComments={setAllComments}
            />
          );
          break;

        case "insertVideo":
          contentToRender.push(
            <BoxAreaReports
              reportChartsRef={reportChartsRef}
              hideFilter={true}
              key={key}
              questionPosition={Number(key) + 1}
              question={dataFirebase.questions[key]}
              setTypeOfChart={setTypeOfChart}
              typeOfChart={typeOfChart}
              dataQuestions={dataQuestions}
              allComments={allComments}
              setAllComments={setAllComments}
            >
              <div className="chartArea videowrapper">
                <iframe
                  allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  frameBorder="0"
                  height="auto"
                  src={dataFirebase.questions[key].options.replace(
                    "watch?v=",
                    "v/"
                  )}
                  title="Mídia enviada pelo usuário"
                  width="100%"
                />
              </div>
            </BoxAreaReports>
          );
          break;

        case "closeAnswer":
        case "likeDislike":
        case "menuInteractive":
        case "multipleChoice":
        case "numericalScale":
        case "emojiScale":
        case "starThreeScale":
        case "starFiveScale":
          dataToChart = organizeStructuredData(
            questionString,
            key,
            reportData[questionString]
          );
          const selectedOptions = filterOptions[questionString];
          if (selectedOptions?.length > 0) {
            dataToChart = dataToChart.filter((option, i) => {
              return selectedOptions.some((opt) => opt.index === i);
            });

            const totalQtd = dataToChart.reduce((acc, cc) => {
              return acc + Number(cc.qtd);
            }, 0);

            dataToChart.forEach((data) => {
              data.value =
                totalQtd === 0
                  ? 0
                  : ((Number(data.qtd) / totalQtd) * 100).toFixed(2);
            });
          }

          const question = dataFirebase.questions[key];
          if (selectedOptions) {
            question.options?.forEach((element, i) => {
              element.checked = selectedOptions.some((opt) => opt.index === i);
            });
          }
          dataToChart = sortTable(dataToChart, questionString);

          contentToRender.push(
            <BoxAreaReports
              reportChartsRef={reportChartsRef}
              key={key}
              questionPosition={Number(key) + 1}
              question={question}
              setTypeOfChart={setTypeOfChart}
              typeOfChart={typeOfChart}
              setSelectedOptions={(options) =>
                handleFilterChange(questionString, options)
              }
              questionString={questionString}
              dataQuestions={dataQuestions}
              allComments={allComments}
              setAllComments={setAllComments}
            >
              {makeAChart(dataFirebase.questions[key], dataToChart)}
              {makeATable(
                dataFirebase.questions[key],
                dataToChart,
                questionString
              )}
            </BoxAreaReports>
          );
          break;
        case "openAnswerCellPhone":
        case "openAnswerCPF":
        case "openAnswerDate":
        case "openAnswerEmail":
        case "openAnswerFreeText":
        case "openAnswerHour":
        case "openAnswerOnlyNumbers":
        case "openAnswerPhone":
          dataToChart = organizeOpenData(
            questionString,
            reportData[questionString]
          );
          dataToChart = sortTable(dataToChart, questionString);

          contentToRender.push(
            <BoxAreaReports
              reportChartsRef={reportChartsRef}
              key={key}
              questionPosition={Number(key) + 1}
              question={dataFirebase.questions[key]}
              setTypeOfChart={setTypeOfChart}
              typeOfChart={typeOfChart}
              dataQuestions={dataQuestions}
            >
              {makeAChart(dataFirebase.questions[key], dataToChart)}
              {makeATable(
                dataFirebase.questions[key],
                dataToChart,
                questionString
              )}
            </BoxAreaReports>
          );
          break;
        default:
          break;
      }
    }
    return contentToRender;
  };

  const invertDate = (dateToInvert) => {
    if (dateToInvert === null) {
      return null;
    }

    let dateInArray = [];
    if (dateToInvert.includes("/")) {
      dateInArray = dateToInvert.split("/");
    } else {
      dateInArray = dateToInvert.split("-");
    }

    dateInArray.reverse();

    return dateInArray.join("-");
  };

  const makeAChart = (questionData, dataToChart) => {
    let regexNps = /Em uma escala de 0 a 10 o quanto você recomendaria.*/;
    let regexCsat =
      /Em uma escala de 1 a 10 o quanto você avalia a sua satisfação geral.*/;
    let regexCes = /Como você avalia a facilidade que teve para.*/;

    let typeOfChartToShow = typeOfChart[questionData.number];
    if (
      typeOfChartToShow === "lineChart" &&
      questionData.message.match(regexNps)
    ) {
      typeOfChartToShow = "BarChart";
    } else if (
      typeOfChartToShow === "lineChart" &&
      questionData.message.match(regexCsat)
    ) {
      typeOfChartToShow = "BarChart";
    } else if (
      typeOfChartToShow === "lineChart" &&
      questionData.message.match(regexCes)
    ) {
      typeOfChartToShow = "BarChart";
    }

    return (
      <div
        className="chartArea"
        style={{
          position: "relative",
          width:
            windowDimensions.width < 1100
              ? windowDimensions.width * 0.85
              : windowDimensions.width * 0.5,
        }}
      >
        <ChartReport
          chartType={typeOfChartToShow}
          data={dataToChart}
          question={questionData}
        />
      </div>
    );
  };

  const getTotalForChart = (dataTable) => {
    return dataTable["year"].reduce((acc, object) => {
      return acc + parseInt(object.qtd);
    }, 0);
  };

  const makeAChartAnswer = (dataTable) => {
    const arrayToChart =
      filterConsolidate === "total"
        ? [
            {
              date: "Total",
              qtd: getTotalForChart(dataTable),
            },
          ]
        : dataTable[filterConsolidate].map((element) => {
            return {
              date: invertDate(element["date"]),
              qtd: Number.parseInt(element["qtd"]),
            };
          });

    return (
      <div
        className="chartArea"
        style={{
          width:
            windowDimensions.width < 1100
              ? windowDimensions.width * 0.85
              : windowDimensions.width * 0.5,
        }}
      >
        <LineChart data={arrayToChart} />
      </div>
    );
  };

  const sortTable = (data, questionString) => {
    let sortArray = data;
    const selected =
      sortOrder.find((x) => x.questionString === questionString) || {};

    if (selected.sortType === "alphabetic") {
      if (selected.btnValue === "up") {
        sortArray = data
          .sort((a, b) => a.option.localeCompare(b.option))
          .reverse();
      } else {
        sortArray = data.sort((a, b) => a.option.localeCompare(b.option));
      }
    } else if (selected.sortType === "numerical") {
      if (selected.btnValue === "up") {
        sortArray = data.sort(
          (a, b) => parseFloat(b.value) - parseFloat(a.value)
        );
      } else {
        sortArray = data.sort(
          (a, b) => parseFloat(a.value) - parseFloat(b.value)
        );
      }
    }
    return sortArray;
  };

  const openModalFunction = (questionString, type) => {
    setOpenModal(!openModal);
    setSortType(type);
    setQuestionStringModal(questionString);
  };

  const makeATableRows = (dataToChart, showTablesPosition) => {
    let arrayToTable = [];
    let totalAnswers = dataToChart.reduce((total, { qtd }) => {
      return total + Number(qtd);
    }, 0);

    let totalPercent = dataToChart.reduce((total, { value }) => {
      return total + Number(value);
    }, 0);

    if (showTablesPosition) {
      arrayToTable = dataToChart.map((element, index) => {
        return (
          <tr key={index}>
            <td>{element.option}</td>
            <td>{element.qtd}</td>
            <td>{`${element.value}%`}</td>
          </tr>
        );
      });

      arrayToTable.push(
        <tr key={"Total"}>
          <td>
            <strong>Total</strong>
          </td>
          <td>
            <strong>{totalAnswers}</strong>
          </td>
          <td>
            <strong>{`${Math.round(totalPercent)}%`}</strong>
          </td>
        </tr>
      );
    } else {
      let showTotal = true;
      arrayToTable = dataToChart.map((element, index) => {
        if (index > 14) {
          showTotal = false;
        }
        return (
          <tr key={index} style={index > 14 ? { display: "none" } : null}>
            <td className="optionText">{element.option}</td>
            <td>{element.qtd}</td>
            <td>{`${element.value}%`}</td>
          </tr>
        );
      });

      arrayToTable.push(
        <tr key={"Total"} style={showTotal ? null : { display: "none" }}>
          <td>
            <strong>Total</strong>
          </td>
          <td>
            <strong>{totalAnswers}</strong>
          </td>
          <td>
            <strong>{`${Math.round(totalPercent)}%`}</strong>
          </td>
        </tr>
      );
    }

    return arrayToTable;
  };

  const makeATable = (questionData, dataToChart, questionString) => {
    switch (questionData.type) {
      case "insertImage":
      case "insertVideo":
      case "insertParagraph":
        return;
      default:
        break;
    }

    let arrayToTable = dataToChart.map((element, index) => {
      return (
        <tr key={index}>
          <td>{element.option}</td>
          <td>{element.qtd}</td>
          <td>{`${element.value}%`}</td>
        </tr>
      );
    });

    return (
      <>
        <table className="table" name={questionData.message}>
          <thead>
            <tr className="btnArea">
              <td>
                <div>
                  Opção
                  <button
                    className="btnSort"
                    onClick={(e) =>
                      openModalFunction(questionString, e.target.name)
                    }
                  >
                    <img
                      name="alphabetic"
                      src={UpDown}
                      alt="Ordenar Numericamente"
                    />
                  </button>
                </div>
              </td>
              <td>
                <div>
                  Qtd
                  <button
                    className="btnSort"
                    onClick={(e) =>
                      openModalFunction(questionString, e.target.name)
                    }
                  >
                    <img
                      name="numerical"
                      src={UpDown}
                      alt="Ordenar Numericamente"
                    />
                  </button>
                </div>
              </td>
              <td>%</td>
            </tr>
          </thead>
          <tbody>
            {makeATableRows(dataToChart, showTables[questionData.number])}
          </tbody>
        </table>
        {arrayToTable.length > 15 ? (
          <button
            className="buttonShowTable"
            onClick={() => handleShowTable(questionData.number)}
          >
            {showTables[questionData.number] === false
              ? "Ver Mais ⇩"
              : "Ver Menos ⇧"}
          </button>
        ) : null}
      </>
    );
  };

  const organizeStructuredData = (
    questionString,
    questionPosition,
    answerValues
  ) => {
    let arrayToChart = [];

    for (const key in dataFirebase.questions[questionPosition].options) {
      arrayToChart.push({
        option: changeKeysToEmojis(
          dataFirebase.questions[questionPosition].options[key].value
        ),
        qtd: "0",
        value: "0",
      });
    }

    for (const key in answerValues) {
      if (answerValues[key][questionString] !== "Total") {
        let questionExists = null;
        let stringChangedToEmoji = changeKeysToEmojis(
          answerValues[key][questionString]
        );
        for (const index in arrayToChart) {
          if (arrayToChart[index].option === stringChangedToEmoji) {
            questionExists = index;
          }
        }

        if (questionExists) {
          arrayToChart[questionExists] = {
            option: stringChangedToEmoji,
            qtd: answerValues[key].qtd,
            value: Math.round(Number(answerValues[key].percent) * 100) / 100,
          };
        } else {
          arrayToChart.push({
            option: stringChangedToEmoji,
            qtd: answerValues[key].qtd,
            value: Math.round(Number(answerValues[key].percent) * 100) / 100,
          });
        }
      }
    }
    return arrayToChart;
  };

  const organizeOpenData = (questionString, answerValues) => {
    let arrayToChart = [];

    for (const key in answerValues) {
      if (answerValues[key][questionString] !== "Total") {
        arrayToChart.push({
          option: answerValues[key][questionString],
          qtd: answerValues[key].qtd,
          value: Number(answerValues[key].percent).toFixed(2),
        });
      }
    }

    arrayToChart = arrayToChart.sort((a, b) => {
      return b.value - a.value;
    });

    return arrayToChart;
  };

  const handleWarningNotShowAnswer = () => {
    const filter = filterConsolidate === "total" ? "year" : filterConsolidate;

    let totalAnswer = engagementData["answers"][filter]
      .map((element) => {
        return Number.parseInt(element["qtd"]);
      })
      .reduce((a, b) => a + b, 0);

    if (userProjectsQtdAnswers[projectId] > totalAnswer) {
      return (
        <div className="notShowingAnswers">
          Existem respostas não mostradas.
          <br />
          <span onClick={() => history.push("/plans")}> Clique aqui</span> para
          fazer upgrade!
        </div>
      );
    }
  };

  const getDataWithFilterQuestion = async (question, existFilter) => {
    setLoadingEngagement(true);

    try {
      const qtdAnswers = handleTotalInteractions();
      let questionNumber = null;

      if (existFilter) {
        questionNumber = getNumberQuestionInDictionary(dataFirebase, question);
      }

      const dataToSend = JSON.stringify({
        userEmail: userInformation.email,
        campaign: projectId,
        qtdAnswers: typeof qtdAnswers === "number" ? qtdAnswers : 1000000,
        ...(existFilter && { filterQuestion: questionNumber }),
        ...(dateMin && { dateMin }),
        ...(dateMax && { dateMax }),
      });

      const response = await ProjectsService.getEngagement(dataToSend);
      setEngagementData(response);
    } finally {
      setLoadingEngagement(false);
    }
  };

  const handleNewFilterQuestion = async () => {
    await ProjectsService.setFilterQuestion(projectId, selectedQuestion);
    getDataWithFilterQuestion(selectedQuestion, true);
  };

  const handleClearFilterQuestion = async () => {
    setSelectedQuestion("");
    getDataWithFilterQuestion("", false);
    await ProjectsService.setFilterQuestion(projectId, "");
  };

  if (loading) {
    return null;
  } else {
    return (
      <section className="compChartsAndTable">
        <ModalFilterQuestion
          isOpen={showModalFilterQuestion}
          setIsOpen={setShowModalFilterQuestion}
          questions={dataFirebase.questions}
          selectedQuestion={selectedQuestion}
          setSelectedQuestion={setSelectedQuestion}
          onClickSave={handleNewFilterQuestion}
          onClickClear={handleClearFilterQuestion}
        />
        <ModalSortData
          openModal={openModal}
          setOpenModal={setOpenModal}
          sortOrder={sortOrder}
          setSortOrder={setSortOrder}
          questionString={questionStringModal}
          sortType={sortType}
        />
        <BoxAreaReports
          reportChartsRef={reportChartsRef}
          key={"q_00"}
          questionString={"q_00"}
          question={{ type: "engagement" }}
          hideFilter={true}
        >
          {handleWarningNotShowAnswer()}
          <div className="engajamentoButtons">
            <div
              className={
                filterConsolidate === "day"
                  ? "buttonSelected"
                  : "buttonNotSelected"
              }
              onClick={() => setFilterConsolidate("day")}
            >
              Dia
            </div>
            <div
              className={
                filterConsolidate === "month"
                  ? "buttonSelected"
                  : "buttonNotSelected"
              }
              onClick={() => setFilterConsolidate("month")}
            >
              Mês
            </div>
            <div
              className={
                filterConsolidate === "year"
                  ? "buttonSelected"
                  : "buttonNotSelected"
              }
              onClick={() => setFilterConsolidate("year")}
            >
              Ano
            </div>
            <div
              className={
                filterConsolidate === "total"
                  ? "buttonSelected"
                  : "buttonNotSelected"
              }
              onClick={() => setFilterConsolidate("total")}
            >
              Total
            </div>
          </div>
          <div className="buttonFilter">
            <img src={FilterOutline} alt="Ícone de filtro" />
            <button onClick={() => setShowModalFilterQuestion(true)}>
              <span>
                {selectedQuestion ? selectedQuestion : "Filtrar pergunta"}
              </span>
            </button>
          </div>
          {loadingEngagement ? (
            <div className="loadingEngagement">
              <div>
                <Loader type="Oval" color="#609ef1" height="80" width="80" />
              </div>
              <p>Carregando dados...</p>
            </div>
          ) : engagementData?.answers.day.length > 0 ? (
            makeAChartAnswer(engagementData["answers"])
          ) : (
            <div className="boxNotAnswer">Ainda não há respondentes</div>
          )}
        </BoxAreaReports>
        {engagementData?.answers.day.length > 0 && (
          <>
            <BoxAreaReports
              reportChartsRef={reportChartsRef}
              key={"introduction"}
              question={{
                type: "introduction",
                message: reportData["intro"].value,
              }}
              hideFilter={true}
            />
            {showChartsAndTables()}
          </>
        )}
      </section>
    );
  }
};

export default ChartsAndTables;
