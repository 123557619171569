import Routes from "./Routes/routes";

const App = () => {
  return (
    <>
      <Routes />
    </>
  );
}

export default App;
