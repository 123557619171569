import React, { useContext, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/Context";
import { FaStoreAlt, FaPalette, FaArrowLeft } from "react-icons/fa";
import { BsChatSquareTextFill } from "react-icons/bs";
import { RiCustomerService2Fill } from "react-icons/ri";
import Hazo from "../../Assets/img/256PX-01.png";
import ModalCancelPlanConfirmation from "../../Components/Modals/ModalCancelPlanConfirmation";

const ManagerPlan = () => {
  const { handleTotalInteractions, totalAnswers, userInformation } =
    useContext(Context);
  const history = useHistory();
  const [
    modalModalCancelPlanConfirmation,
    setModalModalCancelPlanConfirmation,
  ] = useState(false);

  if (userInformation) {
    return (
      <div className="compManagerPlan">
        <div className="content">
          <ModalCancelPlanConfirmation
            modal={modalModalCancelPlanConfirmation}
            setModal={setModalModalCancelPlanConfirmation}
          />
          <div className="icon">
            <button className="btn-icon">
              <FaArrowLeft onClick={() => history.push("/projects")} />
            </button>
          </div>

          <img alt="hazo logo" className="hazoImage" src={Hazo} />
          <div className="hazotext">Hazo</div>
          <div className="interactionsArea">
            <p className="interactions">
              Total de Interações: {totalAnswers}/{handleTotalInteractions()}
            </p>
            {/* <p className="interactions">
              Interações do mês atual: {totalAnswers}
            </p>
            <p className="interactions">Validade: Outubro/2022</p> */}
          </div>
          <hr />
          <div className="plan-content">
            <button className="button" onClick={() => history.push("/plans")}>
              Upgrade
            </button>
            <p className="planTitle">
              Plano Atual: {userInformation.subscription}
            </p>
            <div className="planArea">
              <div className="descritionArea">
                <BsChatSquareTextFill />
                <div>{handleTotalInteractions()} Interações / Respostas</div>
              </div>
              <div className="descritionArea">
                <FaPalette />
                <div>Customização (Avatar, logo, e cor do cabeçalho)</div>
              </div>
              <div className="descritionArea">
                <FaStoreAlt />
                <div>Link Personalizado ex: hazo.me/nome-do-seu-projeto</div>
              </div>
              <div className="descritionArea">
                <RiCustomerService2Fill />
                <div>Suporte por Whatsapp com resposta em até 24 horas</div>
              </div>
            </div>
            <button
              className="onlyUnderline"
              onClick={() => setModalModalCancelPlanConfirmation(true)}
            >
              Cancelar Plano
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ManagerPlan;
