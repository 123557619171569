import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, Input } from "reactstrap";
import FiRrSearch from "../../../Assets/img/fi-rr-search.png";
import "./style.scss";

const ModalFilterQuestion = ({
  isOpen,
  setIsOpen,
  questions,
  selectedQuestion,
  setSelectedQuestion,
  onClickSave,
  onClickClear,
}) => {
  const [searchText, setSearchText] = useState("");
  const [formatedQuestions, setFormatedQuestions] = useState([]);
  const filterQuestions = formatedQuestions.filter((question) =>
    question.toLowerCase().includes(searchText.toLowerCase())
  );

  const toggle = () => setIsOpen(!isOpen);

  const handleClear = () => {
    onClickClear();
    toggle();
  };

  const handleSave = () => {
    onClickSave();
    toggle();
  };

  useEffect(() => {
    if (questions.length) {
      let dataQuestions = [];

      questions.forEach((question, index) => {
        const questionMessage = `P${index + 1} - ${question.message}`;
        dataQuestions.push(questionMessage);
      });

      setFormatedQuestions(dataQuestions);
    }
    // eslint-disable-next-line
  }, []);

  if (!isOpen) return null;

  return (
    <Modal
      className="compModalFilterQuestion"
      isOpen={isOpen}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <p className="titleModal">Filtrar pergunta</p>
      <hr />
      <main>
        <div className="inputSearch">
          <Input
            className="input"
            placeholder="Pesquisar..."
            onChange={({ target }) => setSearchText(target.value)}
          />
          <img src={FiRrSearch} alt="Ícone de pesquisa" />
        </div>
        <div className="listQuestions">
          {filterQuestions.map((question, index) => (
            <div className="question" key={index}>
              <input
                type="radio"
                name="question"
                id={question}
                value={question}
                checked={selectedQuestion === question}
                onChange={({ target }) => setSelectedQuestion(target.value)}
              />
              <label htmlFor={question}>{question}</label>
            </div>
          ))}
        </div>
      </main>
      <footer>
        <button onClick={handleClear}>Limpar</button>
        <button onClick={handleSave}>Salvar</button>
      </footer>
    </Modal>
  );
};

export default ModalFilterQuestion;
