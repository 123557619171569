import { useContext, useState } from "react";
import { PayPalButtons } from "@paypal/react-paypal-js";
import { Context } from "../../../Context/Context";
import { useHistory } from "react-router-dom";
import Hazo from "../../../Assets/img/256PX-01.png";
import HazoError from "../../../Assets/img/256PX-02.png";
import ModalWarning from "../../Modals/ModalWarning";
import PaypalService from "../../../Services/PaypalService";
import UserService from "../../../Services/UserService";

const ButtonCheckout = (props) => {
  const { product } = props;
  const { updateUserInformation, userInformation } = useContext(Context);
  const [modalWarning, setModalWarning] = useState(false);
  const [modalWarningMessage, setModalWarningMessage] = useState("");
  const history = useHistory();

  const handleApprove = async (orderId) => {
    if (
      userInformation.idSubscriptionPaypal !== undefined &&
      userInformation.idSubscriptionPaypal !== null
    ) {
      await PaypalService.cancelSubscription(
        userInformation.idSubscriptionPaypal,
        "Upgrade"
      );
    }

    await UserService.setAccountPlan(product.plan, orderId, product.planType);
    await updateUserInformation();
    history.push("/projects");
  };

  const handleError = () => {
    setModalWarningMessage(
      <>
        <img src={HazoError} alt="camaleão-hazo" />
        <h3>Lamento aconteceu um problema</h3>
        <button onClick={() => setModalWarning(false)}>Ok</button>
      </>
    );
    setModalWarning(true);
  };

  const handleUserAlreadyAPlan = () => {
    setModalWarningMessage(
      <>
        <img src={Hazo} alt="camaleão-hazo" />
        <h3>Você já assinou este plano</h3>
        <button onClick={() => setModalWarning(false)}>Ok</button>
      </>
    );
    setModalWarning(true);
  };

  return (
    <>
      <ModalWarning modal={modalWarning} setModal={setModalWarning}>
        {modalWarningMessage}
      </ModalWarning>
      <PayPalButtons
        style={{
          color: "silver",
          layout: "horizontal",
          height: 48,
          tagline: false,
          shape: "pill",
        }}
        createSubscription={(data, actions) => {
          return actions.subscription.create({
            plan_id: product.planId,
          });
        }}
        onApprove={async (data) => {
          await handleApprove(data.subscriptionID);
        }}
        onError={(err) => {
          handleError();
        }}
        onClick={(data, actions) => {
          if (userInformation.subscription === product.plan) {
            handleUserAlreadyAPlan();
            return actions.reject();
          } else {
            return actions.resolve();
          }
        }}
      />
    </>
  );
};

export default ButtonCheckout;
