import Axios from "axios";
require("dotenv").config();

export default class LogoService {
  static logoSave = async (image) => {
    let formData = new FormData();
    formData.append("img", image);
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "multipart/form-data",
      method: "post",
      contentType: false,
      url: `${process.env.REACT_APP_BASE_URL_API}new_logo/`,
      data: formData,
    }).then((res) => {
      response = res.data;
    });
    return response;
  };
}
