import "./style.scss";
import { IoBarChartSharp, IoEllipseSharp } from "react-icons/io5";
import { useState } from "react";
import ModalCharts from "../Modals/ModalCharts";
import FilterContainer from "../FilterContainer";
import { VscFilterFilled } from "react-icons/vsc";
import { Tooltip } from "reactstrap";
import ChartComment from "../ChartComment";

const BoxAreaReports = (props) => {
  const [modalChartOpen, setModalChartOpen] = useState(false);
  const [modalFilter, setModalFilter] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  const handleNotificationState = () => {
    const haveSelectedOptions = props.question.options?.some(
      (opt) => opt.checked === true
    );

    if (haveSelectedOptions) {
      return (
        <span
          id="filterNotification"
          onMouseOver={() => setTooltipOpen(true)}
          onMouseOut={() => setTooltipOpen(false)}
          className="notificationIcon"
        >
          <IoEllipseSharp />
          <Tooltip
            innerClassName="redBackgroundNotification"
            placement="top"
            isOpen={tooltipOpen}
            target={"filterNotification"}
          >
            <span>Existem filtros aplicados</span>
          </Tooltip>
        </span>
      );
    }
  };

  const typeNotAllowed = [
    "insertImage",
    "insertParagraph",
    "insertVideo",
    "openAnswerCellPhone",
    "openAnswerCPF",
    "openAnswerCPF",
    "openAnswerDate",
    "openAnswerEmail",
    "openAnswerFreeText",
    "openAnswerOnlyNumbers",
    "openAnswerPhone",
  ];

  const handleTypeAndTitle = (question) => {
    switch (question.type) {
      case "closeAnswer":
        return `P${props.questionPosition} - Pergunta Fechada - ${question.message}`;
      case "emojiScale":
        return `P${props.questionPosition} - Escala Emoji - ${question.message}`;
      case "insertImage":
        return `P${props.questionPosition} - Inserir Imagem - ${question.message}`;
      case "insertVideo":
        return `P${props.questionPosition} - Inserir Vídeo - ${question.message}`;
      case "insertLink":
        return `P${props.questionPosition} - Inserir Link - ${question.message}`;
      case "insertParagraph":
        return `P${props.questionPosition} - Inserir Parágrafo - ${question.message}`;
      case "likeDislike":
        return `P${props.questionPosition} - Like e Dislike - ${question.message}`;
      case "menuInteractive":
        return `P${props.questionPosition} - Menu Interativo - ${question.message}`;
      case "multipleChoice":
        return `P${props.questionPosition} - Multipla Escolha - ${question.message}`;
      case "numericalScale":
        return `P${props.questionPosition} - Escala Numerica - ${question.message}`;
      case "openAnswerFreeText":
        return `P${props.questionPosition} - Aberta / Texto Livre - ${question.message}`;
      case "openAnswerOnlyNumbers":
        return `P${props.questionPosition} - Aberta / Apenas Números - ${question.message}`;
      case "openAnswerEmail":
        return `P${props.questionPosition} - Aberta / Email - ${question.message}`;
      case "openAnswerPhone":
        return `P${props.questionPosition} - Aberta / Telefone - ${question.message}`;
      case "openAnswerCellPhone":
        return `P${props.questionPosition} - Aberta / Celular - ${question.message}`;
      case "openAnswerCPF":
        return `P${props.questionPosition} - Aberta / CPF - ${question.message}`;
      case "openAnswerDate":
        return `P${props.questionPosition} - Aberta / Data - ${question.message}`;
      case "openAnswerHour":
        return `P${props.questionPosition} - Aberta / Hora - ${question.message}`;
      case "starThreeScale":
      case "starFiveScale":
        return `P${props.questionPosition} - Escala Estrela - ${question.message}`;
      case "introduction":
        return question.message;
      case "engagement":
        return `Engajamento - Volume de respostas`;
      default:
        return null;
    }
  };

  const typeNotShowComment = ["engagement", "introduction", "insertParagraph"];

  const openModalFilter = () => {
    setModalFilter(true);
  };

  return (
    <div className="compBoxAreaReports">
      <ModalCharts
        reportChartsRef={props.reportChartsRef}
        chartNumber={props.question.number}
        modal={modalChartOpen}
        setModal={setModalChartOpen}
        setTypeOfChart={props.setTypeOfChart}
        typeOfChart={props.typeOfChart}
      />
      {props.question.options !== undefined && (
        <FilterContainer
          setModalFilter={setModalFilter}
          setSelectedOptions={props.setSelectedOptions}
          modalFilter={modalFilter}
          data={props.question}
        />
      )}
      <div className="titleArea">
        <div className="boxChartTitle">
          {handleTypeAndTitle(props.question)}
        </div>
        {!props.hideFilter && (
          <div className="chartBtnFilter">
            <button
              alt="config item"
              onClick={() => setModalChartOpen(true)}
              className="btnFilter"
            >
              <IoBarChartSharp />
            </button>
            <div className="btnFilterContainer">
              {handleNotificationState()}
              {!typeNotAllowed.includes(props.question.type) ? (
                <button className="btnFilter" onClick={() => openModalFilter()}>
                  <VscFilterFilled />
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
      {props.children}
      {!typeNotShowComment.includes(props.question.type) && (
        <ChartComment
          dataQuestions={props.dataQuestions}
          chartNumber={props.question.number}
          reportChartsRef={props.reportChartsRef}
          allComments={props.allComments}
          setAllComments={props.setAllComments}
        />
      )}
    </div>
  );
};

export default BoxAreaReports;
