import React, { useContext, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/Context";
import { Modal, ModalHeader } from "reactstrap";
import Archive from "../../../Assets/img/archive.png";
import Check from "../../../Assets/img/check.png";
import MenuDuplicate from "../../../Assets/img/menu_duplicate.png";
import MenuEdit from "../../../Assets/img/menu_edit.png";
import MenuResults from "../../../Assets/img/menu_results.png";
import MenuShare from "../../../Assets/img/menu_share.png";
import MenuTest from "../../../Assets/img/menu_test.png";
import MenuTrash from "../../../Assets/img/menu_trash.png";
import ModalConfimationProjectDelete from "../ModalConfimationProjectDelete";
import ProjectsService from "../../../Services/ProjectsService";
import X from "../../../Assets/img/x.png";

const ModalMenuProjects = (props) => {
  const {
    project,
    modal,
    userProjects,
    setUserProjects,
    userOthersProjects,
    setUserOthersProjects,
    setModal,
    handleModalRenameDuplicateProject,
  } = props;
  const toggle = () => {
    setModal(!modal);
  };
  const { saveProject, userInformation } = useContext(Context);
  const [editingTitle, setEditingTitle] = useState(false);
  const [modalConfimation, setModalConfirmation] = useState(false);
  const [titleProject, setTitleProject] = useState("");
  const history = useHistory();

  const handleDelete = () => {
    let arrayTemp = [];
    for (let i = 0; i < userProjects.length; i++) {
      if (userProjects[i].idProject !== project.idProject) {
        arrayTemp.push(userProjects[i]);
      }
    }
    setUserProjects(arrayTemp);
    ProjectsService.deleteProject(userInformation, project.idProject);
    setModalConfirmation(false);
    toggle();
  };

  const handleSave = async () => {
    let projectTemp = project;
    projectTemp.nameProject = titleProject;

    let jsonParsed = JSON.parse(projectTemp.jsonProject);
    jsonParsed.project_name = titleProject;
    projectTemp.jsonProject = JSON.stringify(jsonParsed);

    let arrayToSet = userProjects.map((element) => {
      return element.idProject === projectTemp.idProject
        ? projectTemp
        : element;
    });

    setUserProjects(arrayToSet);
    await saveProject(
      projectTemp.idProject,
      projectTemp.jsonProject,
      projectTemp.nameProject
    );
  };

  const handleMoveProjectToProject = async () => {
    await ProjectsService.setUpdateFolder(project.idProject, "projects");
    let newUserOthersProjects = userOthersProjects.filter((element) => {
      return element.idProject !== project.idProject ? element : null;
    });

    let projectRemoved = userOthersProjects.filter((element) => {
      return element.idProject === project.idProject ? element : null;
    });

    projectRemoved[0].folder = "projects";
    setUserOthersProjects(newUserOthersProjects);
    setUserProjects([...userProjects, projectRemoved[0]]);
    toggle();
  };

  const handleMoveProjectToOthers = async () => {
    await ProjectsService.setUpdateFolder(project.idProject, "others");
    let newUserProjects = userProjects.filter((element) => {
      return element.idProject !== project.idProject ? element : null;
    });

    let projectRemoved = userProjects.filter((element) => {
      return element.idProject === project.idProject ? element : null;
    });
    projectRemoved[0].folder = "others";
    setUserOthersProjects([...userOthersProjects, projectRemoved[0]]);
    setUserProjects(newUserProjects);
    toggle();
  };

  const handleEditTrue = () => {
    setTitleProject(project.nameProject);
    setEditingTitle(true);
  };

  const handleCancel = () => {
    setTitleProject(project.nameProject);
    setEditingTitle(false);
  };

  if (project === null) {
    return null;
  } else {
    return (
      <Modal
        className="ModalMenuProjects"
        isOpen={modal}
        centered={true}
        toggle={toggle}
      >
        <ModalConfimationProjectDelete
          modal={modalConfimation}
          setModal={setModalConfirmation}
          handleDelete={handleDelete}
        />
        <ModalHeader toggle={toggle}></ModalHeader>
        <div className="content">
          <hr className="hrTitle" />
          {editingTitle ? (
            <div className="projectTitle">
              <input
                value={titleProject}
                placeholder="Digite aqui o nome do projeto"
                onChange={(event) => {
                  setTitleProject(event.target.value);
                }}
              />
              <img
                alt="fechar"
                className="compEditQuestionMenuIcon"
                src={X}
                onClick={() => handleCancel()}
              />
              <img
                alt="confirmar"
                className="compEditQuestionMenuIcon"
                src={Check}
                onClick={async () => {
                  await handleSave();
                  setEditingTitle(false);
                }}
              />
            </div>
          ) : (
            <div className="projectTitle">
              <b>{project.nameProject}</b>
              <img
                alt="NPS"
                className="projectTitleIcon"
                onClick={() => handleEditTrue()}
                src={MenuEdit}
              />
            </div>
          )}

          <hr className="hrTitle" />
          <div className="menuRow">
            <div
              className="boxPresets"
              onClick={() => history.push(`/botmaker/${project.idProject}`)}
            >
              <img alt="NPS" src={MenuEdit} />
              <div className="groupTitles">
                <p className="pTitle">Editar</p>
              </div>
            </div>
            <div
              className="boxPresets"
              onClick={() => history.push(`/share/${project.idProject}`)}
            >
              <img alt="CSAT" src={MenuShare} />
              <div className="groupTitles">
                <p className="pTitle">Compartilhar</p>
              </div>
            </div>
            <div
              className="boxPresets"
              onClick={() => history.push(`/report/${project.idProject}`)}
            >
              <img alt="CES" src={MenuResults} />
              <div className="groupTitles">
                <p className="pTitle">Resultados</p>
              </div>
            </div>
          </div>
          <hr className="hrTitle" style={{ opacity: 0.4 }} />
          <div className="menuRow">
            <div
              className="boxPresets"
              onClick={() => handleModalRenameDuplicateProject()}
            >
              <img alt="CES" src={MenuDuplicate} />
              <div className="groupTitles">
                <p className="pTitle">Duplicar</p>
              </div>
            </div>
            <a
              className="boxPresets"
              href={`${process.env.REACT_APP_BASE_URL}testing/${
                project.link !== undefined ? project.link : project.idProject
              }`}
              rel="noreferrer"
              target="_blank"
            >
              <img alt="CES" src={MenuTest} />
              <div className="groupTitles">
                <p className="pTitle">Testar</p>
              </div>
            </a>
            <div
              className="boxPresets"
              onClick={() => setModalConfirmation(true)}
            >
              <img alt="CES" src={MenuTrash} />
              <div className="groupTitles">
                <p className="pTitle">Excluir</p>
              </div>
            </div>
          </div>
          <hr className="hrTitle" style={{ opacity: 0.4 }} />
          <div className="menuRow">
            <div
              className="boxPresets"
              onClick={() => {
                if (
                  project.folder === undefined ||
                  project.folder === "projects"
                ) {
                  handleMoveProjectToOthers();
                } else {
                  handleMoveProjectToProject();
                }
              }}
            >
              <img alt="Archive" src={Archive} />
              <div className="groupTitles">
                {project.folder === undefined ||
                project.folder === "projects" ? (
                  <p className="pTitle">Mover Para Outros</p>
                ) : (
                  <p className="pTitle">Mover Para Projetos</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default ModalMenuProjects;
