import { firebaseFirestore } from "../Config/firebase.js";
require("dotenv").config();

export default class ReportsService {
  static getReportCharts = async (project) => {
    let querySnapshot = await firebaseFirestore
      .collection("report_charts")
      .where("id", "==", project)
      .get();
    let dataToReturn = [];

    querySnapshot.forEach((document) => {
      dataToReturn.push({
        data: JSON.parse(document.data().json),
        docRef: document.id,
      });
    });

    return dataToReturn[0];
  };

  static updateReportCharts = async (docRef, data) => {
    await firebaseFirestore
      .collection("report_charts")
      .doc(docRef)
      .update({
        json: JSON.stringify(data),
      });
  };

  static getComments = async (docRef) => {
    const project = await firebaseFirestore
      .collection("report_charts")
      .doc(docRef)
      .get();

    const comments = project.data().comment;
    return comments;
  };

  static updateComment = async (docRef, data) => {
    const project = await firebaseFirestore
      .collection("report_charts")
      .doc(docRef)
      .get();

    const comment = project.data().comment;
    const newList = [];

    if (!comment) {
      newList.push(data);
    } else {
      const exists = comment?.some((c) => data.number === c.number);
      if (!exists) {
        newList.push(data);
      }
      comment?.forEach((c) => {
        if (data.number === c.number) {
          newList.push(data);
        } else {
          newList.push(c);
        }
      });
    }

    await firebaseFirestore.collection("report_charts").doc(docRef).update({
      comment: newList,
    });
  };

  static createReportCharts = async (data, idProject) => {
    await firebaseFirestore
      .collection("report_charts")
      .doc()
      .set({
        id: idProject,
        json: JSON.stringify(data),
      });
  };

  static getFilter = async (docRef) => {
    const project = await firebaseFirestore
      .collection("report_charts")
      .doc(docRef)
      .get();

    const filters = project.data().filter;
    return filters;
  };

  static updateFilters = async (docRef, data) => {
    await firebaseFirestore.collection("report_charts").doc(docRef).update({
      filter: data,
    });
  };
}
