import React from "react";
import Dislike from "../../../Assets/img/dislike.svg";
import InputColor from "../InputColor";
import Like from "../../../Assets/img/like.svg";

const LikeDislike = (props) => {
  return (
    <div>
      <div className="options">
        Opção 1:
        <img className="likeDislikeIcon" alt="like" src={Like} />
        (Like/Curti/Gostei)
      </div>
      <div className="options">
        Opção 2:
        <img className="likeDislikeIcon" alt="dislike" src={Dislike} />
        (Dislike/Não Curti/Não Gostei)
      </div>
      {props.questionValues.questions[props.questionNumber].options.map(
        (option, index) => {
          if (index > 1) {
            return (
              <div key={index} className="options">
                <div>Opção {index + 1}:</div>
                <InputColor
                  option={option}
                  index={index}
                  questionNumber={props.questionNumber}
                  handleColor={props.handleColor}
                  setTooltipOpen={props.setTooltipOpen}
                  handleOptionValue={props.handleOptionValue}
                  handleClickInput={props.handleClickInput}
                  handleKeyDown={props.handleKeyDown}
                  handleRemoveOption={props.handleRemoveOption}
                  moveDownOption={props.moveDownOption}
                  moveUpOption={props.moveUpOption}
                />
              </div>
            );
          }
          return null;
        }
      )}
      <button
        className="buttonAddOption"
        onClick={() => props.handleAddOption(props.questionNumber)}
      >
        Adicionar Opção
      </button>
    </div>
  );
};

export default LikeDislike;
