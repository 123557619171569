import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Modal, ModalHeader, Tooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/Context";

const ModalRenameDuplicateProject = (props) => {
  const { duplicateProject } = useContext(Context);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const history = useHistory();
  const [newProjectName, setNewProjectName] = useState(null);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const toggle = () => {
    props.setModal(!props.modal);
    if (tooltipOpen) {
      toggleTooltip();
    }
  };

  const handleDuplicate = async () => {
    let response = await duplicateProject(props.project, newProjectName);
    history.push(`/botmaker/${response}`);
  };

  useEffect(() => {
    const handleProjectName = () => {
      setNewProjectName("Copia " + props.project.nameProject);
    };

    if (props.project !== null) {
      handleProjectName();
    }
    // eslint-disable-next-line
  }, [props.project]);

  return (
    <Modal
      className="compModalRenameDuplicateProject"
      isOpen={props.modal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">
        <Tooltip placement="top" isOpen={tooltipOpen} target="inputTitle">
          Dê um nome para cópia do projeto
        </Tooltip>
        <div className="divTitle">Dê um nome para cópia do projeto</div>
        <hr className="hrTitle" />
        <input
          id="inputTitle"
          className="inputTitle"
          placeholder="Digite aqui o nome do seu projeto"
          value={newProjectName}
          onChange={(event) => setNewProjectName(event.target.value)}
        />
        <div className="buttonArea">
          <div className="buttonNotSelected" onClick={() => handleDuplicate()}>
            Duplicar
          </div>{" "}
          <div className="buttonNotSelected" onClick={toggle}>
            Cancelar
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalRenameDuplicateProject;
