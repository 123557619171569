import "./style.scss";
import { useContext } from "react";
import { Context } from "../../Context/Context";
import { useHistory } from "react-router-dom";
import Home from "../../Assets/img/home_icon.png";

const ProjectsNavigation = (props) => {
  const {
    controllerOthersRequest,
    handleTotalOthersProjects,
    projectSelected,
    setControllerOthersRequest,
    setProjectSelected,
    totalAnswers,
  } = props;
  const history = useHistory();
  const { handleTotalInteractions } = useContext(Context);

  const monthNames = [
    "Jan",
    "Fev",
    "Mar",
    "Abr",
    "Mai",
    "Jun",
    "Jul",
    "Ago",
    "Set",
    "Out",
    "Nov",
    "Dez",
  ];

  const handleQtdMaxAnswers = () => {
    let maxAnswers = handleTotalInteractions();
    return `${totalAnswers}/${maxAnswers}`;
  };

  const showCurrentMonth = () => {
    const currentDate = new Date();
    return `${monthNames[currentDate.getMonth()]}/${currentDate.getFullYear()}`;
  };

  return (
    <div className="navigationArea">
      <div className="totalAnswersArea">
        <div
          className={totalAnswers > handleTotalInteractions() ? "textRed" : ""}
        >
          {`Interações ${showCurrentMonth()}:  ${handleQtdMaxAnswers()}`}
        </div>
        {totalAnswers > handleTotalInteractions() ? (
          <div className="notShowingAnswers">
            Existem respostas não mostradas.
            <br />
            <span onClick={() => history.push("/plans")}>
              {" "}
              Clique aqui
            </span>{" "}
            para fazer upgrade!
          </div>
        ) : null}
      </div>
      <div className="navigation">
        <img alt="home" src={Home} onClick={() => history.push("/projects")} />
        <div className="buttonArea">
          <div
            className={
              projectSelected === "projects"
                ? "buttonDefault buttonSelected"
                : "buttonDefault"
            }
            onClick={() => setProjectSelected("projects")}
          >
            Principais
          </div>
          <div
            className={
              projectSelected === "projects" ? "triangleDown" : "triangleHide"
            }
          ></div>
        </div>
        <div className="buttonArea">
          <div
            className={
              projectSelected === "others"
                ? "buttonDefault buttonSelected"
                : "buttonDefault"
            }
            onClick={() => {
              if (controllerOthersRequest) {
                handleTotalOthersProjects();
                setControllerOthersRequest(false);
              }
              setProjectSelected("others");
            }}
          >
            Outros
          </div>
          <div
            className={
              projectSelected === "others" ? "triangleDown" : "triangleHide"
            }
          ></div>
        </div>

        <div className="buttonArea">
          <div
            className={
              projectSelected === "templates"
                ? "buttonDefault buttonSelected"
                : "buttonDefault"
            }
            onClick={() => setProjectSelected("templates")}
          >
            Templates
          </div>
          <div
            className={
              projectSelected === "templates" ? "triangleDown" : "triangleHide"
            }
          ></div>
        </div>
      </div>
    </div>
  );
};

export default ProjectsNavigation;
