import React, { useContext, useState } from "react";
import "./style.scss";
import { Modal, ModalHeader, Tooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/Context";
import UserService from "../../../Services/UserService";
import Loading from "../../Loading";

const ModalNewProject = (props) => {
  const { createNewProject, setUserInformation } = useContext(Context);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const history = useHistory();
  const [projectName, setProjectName] = useState("");
  const [loading, setLoading] = useState(false);

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const toggle = () => {
    props.setModal(!props.modal);
    if (tooltipOpen) {
      toggleTooltip();
    }
  };

  const handleCreate = async () => {
    let nameOfProject = projectName;
    if (projectName === "") {
      if (!tooltipOpen) {
        toggleTooltip();
      }
    } else {
      setLoading(true);
      let counter = 1;
      for (let i = 0; i < props.userProjects.length; i++) {
        if (nameOfProject === props.userProjects[i].nameProject) {
          i = 0;
          nameOfProject = `${projectName} ${counter}`;
          counter++;
        }
      }

      let idNewProject = await createNewProject(nameOfProject);
      if (tooltipOpen) {
        toggleTooltip();
      }

      let userInformation = await UserService.getUserInformation();
      setUserInformation(userInformation);
      setLoading(false);
      history.push(`/botmaker/${idNewProject}`);
    }
  };

  const handleEnter = (e) => {
    if (e === "Enter") return handleCreate();
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <Modal
        className="compModalNewProject"
        isOpen={props.modal}
        centered={true}
        toggle={toggle}
      >
        <ModalHeader toggle={toggle}></ModalHeader>
        <div className="content">
          <Tooltip placement="top" isOpen={tooltipOpen} target="inputTitle">
            Dê um nome para o seu projeto
          </Tooltip>
          <input
            id="inputTitle"
            className="inputTitle"
            placeholder="Digite aqui o nome do seu projeto"
            value={projectName}
            onChange={(event) => setProjectName(event.target.value)}
            onKeyDown={(e) => handleEnter(e.key)}
          />
          <div className="buttonArea">
            <div className="buttonNotSelected" onClick={() => handleCreate()}>
              Criar
            </div>{" "}
            <div className="buttonNotSelected" onClick={toggle}>
              Cancelar
            </div>
          </div>
        </div>
      </Modal>
    );
  }
};

export default ModalNewProject;
