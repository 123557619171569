import Axios from "axios";
require("dotenv").config();

export default class DictionaryService {
  static createNewDictionary = async (dictionary) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/dictionary/`,
      data: dictionary,
    }).then((res) => {
      response = res.data;
    });
    return response;
  };
}
