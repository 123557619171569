import React from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import LoginRoute from "./login";
import PrivateRoute from "./private";

import { Provider } from "../Context/Context";
import Api from "../Pages/Api";
import Account from "../Pages/Account";
import BotMaker from "../Pages/BotMaker";
import ChangePassword from "../Pages/ChangePassword";
import ChangesLog from "../Pages/ChangesLog";
import CancelPlan from "../Pages/CancelPlan";
import ForgotPassword from "../Pages/ForgotPassword";
import Loading from "../Components/Loading";
import ManagerPlan from "../Pages/ManagerPlan";
import PageNotFound from "../Pages/PageNotFound";
import Projects from "../Pages/Projects";
import Reports from "../Pages/Reports";
// import ReportData from "../Pages/ReportData";
import Share from "../Pages/Share";
import SignIn from "../Pages/SignIn";
import SignUp from "../Pages/SignUp";
import Welcome from "../Pages/Welcome";
import Plans from "../Pages/Plans";
import Messages from "../Pages/Messages";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";

const Routes = () => {
  return (
    <Provider>
      <PayPalScriptProvider
        options={{
          "client-id": process.env.REACT_APP_PAYPAL_USER_ID,
          currency: "BRL",
          vault: true,
          intent: "subscription",
        }}
      >
        <BrowserRouter>
          <Switch>
            <Route exact path="/docs/api" component={Api} />
            <LoginRoute exact path="/" component={Welcome} />
            <LoginRoute exact path="/signin" component={SignIn} />
            <LoginRoute exact path="/signup" component={SignUp} />
            <LoginRoute
              exact
              path="/forgotpassword"
              component={ForgotPassword}
            />
            <PrivateRoute
              exact
              path="/botmaker/:projectId"
              component={BotMaker}
            />
            <PrivateRoute
              exact
              path="/changePassword"
              component={ChangePassword}
            />
            <PrivateRoute exact path="/account" component={Account} />
            <PrivateRoute exact path="/loading" component={Loading} />
            <PrivateRoute exact path="/managerplan" component={ManagerPlan} />
            <PrivateRoute exact path="/projects" component={Projects} />
            <PrivateRoute exact path="/plans" component={Plans} />
            <PrivateRoute exact path="/messages" component={Messages} />
            <PrivateRoute exact path="/report/:projectId" component={Reports} />
            {/* <PrivateRoute
              exact
              path="/report/:projectId/data"
              component={ReportData}
            /> */}
            <PrivateRoute exact path="/share/:projectId" component={Share} />
            <PrivateRoute exact path="/changeslog" component={ChangesLog} />
            <PrivateRoute exact path="/cancelPlan" component={CancelPlan} />
            <Route exact path="*" component={PageNotFound} />
          </Switch>
        </BrowserRouter>
      </PayPalScriptProvider>
    </Provider>
  );
};

export default Routes;
