import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import AuthService from "../../Services/AuthService";
import Hazo from "../../Assets/img/256PX-01.png";
import EyeInvisible from "../../Assets/img/ICONEOLHOHAZOFECHADO.png";
import EyeVisible from "../../Assets/img/ICONEOLHOHAZO.png";

const PHONE_REGEX = /^[1-9]{2}[9][0-9]{4}[0-9]{4}$/;
const createUserFormSchema = z.object({
  name: z.string()
        .min(3, "O campo nome é obrigatório")
        .transform(name => {
          return name.trim().split(" ").map(word => {
            return word[0].toLocaleUpperCase().concat(word.substring(1))
          }).join(" ")
        }),
  email: z.string()
        .min(1, "O campo e-mail é obrigatório")
        .email("Este e-mail é inválido")
        .transform(email => {
          return email.replaceAll(" ", "")
        }),
  phone: z.string()
        .min(11, "O campo telefone é obrigatório")
        .refine(phone => PHONE_REGEX.test(phone), {
          message: "Adicione um telefone válido"
        }),
  password: z.string()
            .min(6, "A senha precisa de no mínimo 6 caracteres"),
  confirmPassword: z.string(),
}).refine((data) => data.password === data.confirmPassword, {
  message: "As senhas não correspondem",
  path: ["confirmPassword"],
})

const SignUp = () => {
  const history = useHistory();
  const {
    register,
    handleSubmit,
    setError,
    formState: {errors}
  } = useForm({
    resolver: zodResolver(createUserFormSchema),
  });
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState(false);
  const [generalWarning, setGeneralWarning] = useState({
    isOpen: false,
    message: "",
  });

  const handleSignUp = async(data) => {
    let response = await AuthService.signUp(data);

    switch(response.type) {
      case "success":
        history.push("/projects");
        setGeneralWarning({
          isOpen: false,
          message: "",
        });
        break;
      case "error":
        setError("email", {
          type: "manual",
          message: response.message
        });
        setGeneralWarning({
          isOpen: false,
          message: "",
        });
        break;
      case "auth/network-request-failed":
        setGeneralWarning({
          isOpen: true,
          message: "Falha na conexão. Verifique o seu acesso a internet e tente novamente."
        });
        break;
      case "auth/internal-error":
        setGeneralWarning({
          isOpen: true,
          message: "Erro inesperado ao tentar processar o cadastro. Tente novamente mais tarde."
        });
        break;
      case "auth/timeout":
        setGeneralWarning({
          isOpen: true,
          message: "O tempo limite da operação foi atingido. Tente novamente."
        });
        break;
      case "auth/unknown":
        setGeneralWarning({
          isOpen: true,
          message: "Um erro desconhecido ocorreu durante a criação do usuário. Tente novamente mais tarde."
        });
        break;
      case "auth/email-already-in-use":
        setGeneralWarning({
          isOpen: true,
          message: "O endereço de e-mail fornecido já está associado a uma conta existente."
        });
        break;
      default:
        setGeneralWarning({
          isOpen: true,
          message: "Verifique sua conexão e tente novamente."
        });
        break;
    }
  }

  return (
    <div className="CompSignUp">
      <div className="content">
        <div className="textArea">
          Legal!
          <br />
          Digita para mim os seus dados
          <br />
          para eu criar a sua conta!
        </div>
        <div id="triangulo-para-baixo"></div>
        <img alt="logo hazo" className="hazoImage" src={Hazo} />
        <div className="hazotext">Hazo</div>
        <div className="title">Crie sua conta grátis</div>
        <form onSubmit={handleSubmit(handleSignUp)}>
          <div className={errors.name ? "inputArea error" : "inputArea"}>
            <label htmlFor="name">Nome</label>
            <input
              type="text"
              {...register('name')}
            />
            {errors.name && (
              <span className="errorMessage">
                *{errors.name.message}
              </span>
            )}
          </div>
          <div className={errors.email ? "inputArea error" : "inputArea"}>
            <label htmlFor="email">E-mail</label>
            <input
              type="email"
              {...register('email')}
            />
            {errors.email && (
              <span className="errorMessage">
                *{errors.email.message}
              </span>
            )}
          </div>
          <div className={errors.phone ? "inputArea error" : "inputArea"}>
            <label htmlFor="phone">Telefone</label>
            <input
              type="text"
              {...register('phone')}
            />
            {errors.phone && (
              <span className="errorMessage">
                *{errors.phone.message}
              </span>
            )}
          </div>
          <div className={errors.password ? "inputArea error" : "inputArea"}>
            <label htmlFor="password">Senha</label>
            <div>
              <input
                type={showPassword ? "text" : "password"}
                {...register('password')}
              />
              <img
                alt={
                  showPassword
                  ? "olho aberto"
                  : "olho olho fechado"
                }
                onClick={() => setShowPassword(!showPassword)}
                src={showPassword ? EyeInvisible : EyeVisible}
            />
            </div>
            {errors.password && (
              <span className="errorMessage">
                *{errors.password.message}
              </span>
            )}
          </div>
          <div className={errors.confirmPassword ? "inputArea error" : "inputArea"}>
            <label htmlFor="confirmPassword">Confirmação de senha</label>
            <div>
              <input
                type={showConfirmPassword ? "text" : "password"}
                {...register('confirmPassword')}
              />
              <img
                alt={
                  showConfirmPassword
                  ? "olho aberto"
                  : "olho olho fechado"
                }
                onClick={() =>
                  setShowConfirmPassword(!showConfirmPassword)
                }
                src={showConfirmPassword ? EyeInvisible : EyeVisible}
              />
            </div>
            {errors.confirmPassword && (
              <span className="errorMessage">
                *{errors.confirmPassword.message}
              </span>
            )}
          </div>
          <button
            className="buttonSignin butttonHover"
            type="submit"
          >
            Cadastrar
          </button>
        </form>
          {generalWarning.isOpen && (
            <span
              className="errorMessage"
              style={{
                alignSelf: 'center',
                textAlign: 'center',
                width: 240,
              }}
            >
              Erro: {generalWarning.message}
            </span>
          )}
        <div
          className="buttonRedirect"
          onClick={() => history.push("signin")}
        >
          <u>ENTRAR NA MINHA CONTA (LOGIN)</u>
        </div>
        <div
          className="buttonRedirect buttonRedirectLarge"
          onClick={() => history.push("forgotpassword")}
        >
          Recuperar minha senha
        </div>
      </div>
    </div>
  );
};

export default SignUp;
