import React from "react";
import "./style.scss";
import ButtonOnOff from "../../ButtonOnOff";
import CondicionalsMultipleChoice from "../CondicionalsMultipleChoice";
import InputColor from "../InputColor";

const MultipleChoice = (props) => {
  const handleChangeOtherOption = (value) => {
    let arrayTemp = [...props.questionValues.questions];
    if (arrayTemp[props.questionNumber].otherOption === undefined) {
      arrayTemp[props.questionNumber].otherOption = {
        value: value,
        next: 0,
      };
    } else {
      arrayTemp[props.questionNumber].otherOption = {
        ...arrayTemp[props.questionNumber].otherOption,
        value: value,
      };
    }
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const handleChangeNoneOption = (value) => {
    let arrayTemp = [...props.questionValues.questions];
    if (arrayTemp[props.questionNumber].noneQuestion === undefined) {
      arrayTemp[props.questionNumber].noneQuestion = {
        value: value,
        next: 0,
      };
    } else {
      arrayTemp[props.questionNumber].noneQuestion = {
        ...arrayTemp[props.questionNumber].noneQuestion,
        value: value,
      };
    }
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const showConditionOtherOption = () => {
    if (
      props.questionValues.questions[props.questionNumber].otherOption !==
      undefined
    ) {
      if (
        props.questionValues.questions[props.questionNumber].otherOption
          .value === true
      ) {
        return (
          <div className="optionOtherOption">
            <div
              className="option"
              style={{ backgroundColor: "#606c88", color: "white" }}
            >
              Outra (escreva abaixo)
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const showConditionNoneQuestion = () => {
    if (
      props.questionValues.questions[props.questionNumber].noneQuestion !==
      undefined
    ) {
      if (
        props.questionValues.questions[props.questionNumber].noneQuestion
          .value === true
      ) {
        return (
          <div className="optionNoneQuestion goToCompNoneQuestion">
            <div
              className="option"
              style={{ backgroundColor: "#606c88", color: "white" }}
            >
              Nenhuma das opções anteriores
            </div>
            <div className="gap goToComp">
              ir para
              <select
                className="dropdownSelectQuestion"
                value={
                  props.questionValues.questions[props.questionNumber]
                    .noneQuestion.value === 0
                    ? "Próxima Pergunta"
                    : props.questionValues.questions[props.questionNumber]
                        .noneQuestion.next
                }
                onChange={(event) => handleNoneQuestion(event.target.value)}
              >
                <option>Próxima Pergunta</option>
                {props.questionValues.questions.map((question, index) => {
                  return (
                    <option key={question.number} value={question.number}>
                      Pergunta {index + 1}
                    </option>
                  );
                })}
                <option value="99999">Despedida</option>
              </select>
            </div>
          </div>
        );
      }
    }
    return null;
  };

  const handleNoneQuestion = (value) => {
    let arrayTemp = [...props.questionValues.questions];
    let next = value;
    switch (value) {
      case "Próxima Pergunta":
        next = 0;
        break;
      case "Despedida":
        next = 99999;
        break;
      default:
        next = parseInt(value);
        break;
    }
    arrayTemp[props.questionNumber].noneQuestion = {
      ...arrayTemp[props.questionNumber].noneQuestion,
      next: next,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <>
      <div className="optionsArea">
        {props.questionValues.questions[props.questionNumber].options.map(
          (option, index) => {
            return (
              <div key={index} className="options">
                <div>Opção {index + 1}:</div>
                <InputColor
                  option={option}
                  index={index}
                  questionNumber={props.questionNumber}
                  handleColor={props.handleColor}
                  setTooltipOpen={props.setTooltipOpen}
                  handleOptionValue={props.handleOptionValue}
                  handleClickInput={props.handleClickInput}
                  handleRemoveOption={props.handleRemoveOption}
                  handleKeyDown={props.handleKeyDown}
                  moveDownOption={props.moveDownOption}
                  moveUpOption={props.moveUpOption}
                />
              </div>
            );
          }
        )}
        <button
          className="buttonAddOption"
          onClick={() => props.handleAddOption(props.questionNumber)}
        >
          Adicionar Opção
        </button>
      </div>
      <hr />
      <div className="configurationOption">
        Outra (escreva abaixo)
        <ButtonOnOff
          on={
            props.questionValues.questions[props.questionNumber].otherOption !==
            undefined
              ? props.questionValues.questions[props.questionNumber].otherOption
                  .value
              : false
          }
          handleValue={() =>
            handleChangeOtherOption(
              props.questionValues.questions[props.questionNumber]
                .otherOption !== undefined
                ? !props.questionValues.questions[props.questionNumber]
                    .otherOption.value
                : true
            )
          }
        />
      </div>
      <div className="configurationOption">
        Nenhuma das opções anteriores
        <ButtonOnOff
          on={
            props.questionValues.questions[props.questionNumber]
              .noneQuestion !== undefined
              ? props.questionValues.questions[props.questionNumber]
                  .noneQuestion.value
              : false
          }
          handleValue={() =>
            handleChangeNoneOption(
              props.questionValues.questions[props.questionNumber]
                .noneQuestion !== undefined
                ? !props.questionValues.questions[props.questionNumber]
                    .noneQuestion.value
                : true
            )
          }
        />
      </div>
      <div className="conditionsArea">
        <hr />
        <span>Lógica (Condicionais de resposta)</span>
        <div className="optionsConditions">
          <div className="multipleOptionsBox">
            {props.questionValues.questions[props.questionNumber].options.map(
              (option, index) => {
                return (
                  <CondicionalsMultipleChoice
                    key={index}
                    index={index}
                    option={option}
                    questionNumber={props.questionNumber}
                    questionValues={props.questionValues}
                    handleNextOptions={props.handleNextOptions}
                  />
                );
              }
            )}
            {showConditionOtherOption()}
          </div>
          <div className="gap goToComp">
            ir para
            <select
              className="dropdownSelectQuestion"
              value={
                props.questionValues.questions[props.questionNumber].options[0]
                  .next === 0
                  ? "Próxima Pergunta"
                  : props.questionValues.questions[props.questionNumber]
                      .options[0].next
              }
              onChange={(event) =>
                props.handleNextMultipleOptions(
                  props.questionNumber,
                  event.target.value
                )
              }
            >
              <option>Próxima Pergunta</option>
              {props.questionValues.questions.map((question, index) => {
                return (
                  <option key={question.number} value={question.number}>
                    Pergunta {index + 1}
                  </option>
                );
              })}
              <option value="99999">Despedida</option>
            </select>
          </div>
        </div>
        <hr />
        {showConditionNoneQuestion()}
      </div>
    </>
  );
};

export default MultipleChoice;
