import { firebaseFirestore } from "../Config/firebase.js";
require("dotenv").config();

export default class JsonDictionaryService {
  static jsonDictionarySaveFirebase = async (
    projectId,
    projectJson,
    usedNumbers
  ) => {
    let querySnapshot = await firebaseFirestore
      .collection("projects")
      .where("id", "==", projectId)
      .get();

    let docRef = "";
    querySnapshot.forEach((document) => {
      docRef = document.id;
    });

    if (usedNumbers) {
      await firebaseFirestore
        .collection("projects")
        .doc(docRef)
        .update({ json: projectJson, used_numbers: usedNumbers });
    } else {
      await firebaseFirestore
        .collection("projects")
        .doc(docRef)
        .update({ json: projectJson });
    }
  };
}
