import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import { useParams } from "react-router-dom";
import { Tooltip } from "reactstrap";
import ButtonOnOff from "../../Components/ButtonOnOff";
import ChatbotHeader from "../../Components/ChatbotHeader";
import Check from "../../Assets/img/check.svg";
import Copy from "../../Assets/img/copy.svg";
import copy from "copy-to-clipboard";
import Edit from "../../Assets/img/edit.svg";
import Email from "../../Assets/img/email.svg";
import Embed from "../../Assets/img/embeded.svg";
import Facebook from "../../Assets/img/facebook.svg";
import FacebookApp from "../../Assets/img/facebook-app.png";
import html2canvas from "html2canvas";
import Linkedin from "../../Assets/img/linkedin.svg";
import Loading from "../../Components/Loading";
import NavigationHeader from "../../Components/NavigationHeader";
import ProjectsService from "../../Services/ProjectsService";
import QRCodeImg from "../../Assets/img/qrcode.svg";
import Cancel from "../../Assets/img/botao-x.png";
import Twitter from "../../Assets/img/twitter.svg";
import TwitterApp from "../../Assets/img/twitter-app.png";
import WhatsappApp from "../../Assets/img/whatsapp-app.png";
import WhatsappWeb from "../../Assets/img/whatsapp-web.png";
import QRCode from "react-qr-code";
import Hazo from "../../Assets/img/hazo-cortado.png";
import LeftImage from "../../Assets/img/embed-left.png";
import RightImage from "../../Assets/img/embed-right.png";
import Settings from "../../Assets/img/settings.png";

const Share = () => {
  const { chatbot, userInformation } = useContext(Context);
  let { projectId } = useParams();
  const [editLink, setEditLink] = useState(false);
  const [loading, setLoading] = useState(true);
  const [link, setLink] = useState(projectId);
  const [multipleAnswer, setMultipleAnswer] = useState(null);
  const [projectLinkStock, setProjectLinkStock] = useState();
  const [shareProjectInFinal, setShareProjectInFinal] = useState(null);
  const [showEmbed, setShowEmbed] = useState(false);
  const [showQrCode, setShowQrCode] = useState(false);
  const [tooltipCopy, setTooltipCopy] = useState(false);
  const [tooltipEmbed, setTooltipEmbed] = useState(false);
  const [tooltipMessage, setTooltipMessage] = useState("");
  const [tooltipQrcode, setTooltipQrcode] = useState(false);
  const [tooltipCopyCode, setTooltipCopyCode] = useState(false);
  const [tooltipUrl, setTooltipUrl] = useState(false);
  const [embedDirection, setEmbedDirection] = useState("left");
  const [URLFormated, setURLFormated] = useState("");
  const LINK_REGEX = /^([a-zA-Z0-9]|_){0,50}$/;

  const copyToClipboard = () => {
    copy(`${process.env.REACT_APP_BASE_URL}${link}`);
  };

  const handleQrCode = () => {
    setShowQrCode(
      <>
        <div className="box">QR-Code gerado, clique para salvar</div>
        <div onClick={() => handleDownloadImage()} className="box">
          <div id="printQr">
            <QRCode value={`${process.env.REACT_APP_BASE_URL}${link}`} />
          </div>
        </div>
      </>
    );
  };

  /* const getEmbedCode = () => {
    const embedLink = `
    <script>
      window.oneToOneWidgetProject = '${link}';
      window.oneToOneWidgetSide = '${embedDirection}';
      var s = document.createElement('script');
      s.src = 'https://widget.hazo.app/js/widget-loader.js';
      s.async = true;
      document.body.appendChild(s);
    </script>`;
    return (
      <div className="box embed-box">
        <h4>Embed &lt;/&gt;</h4>
        <span className="preview">Preview:</span>
        <img
          className="box-image"
          src={embedDirection === "left" ? LeftImage : RightImage}
          alt=""
        />
        <div className="btn-embed">
          <button
            onClick={() => handleEmbedDirection("left")}
            className="btn-left"
            style={{
              background: `${
                embedDirection === "left" ? "#1273CD" : "#63A0EE"
              }`,
            }}
          >
            Esquerda
          </button>
          <button
            onClick={() => handleEmbedDirection("right")}
            className="btn-right"
            style={{
              background: `${
                embedDirection === "right" ? "#1273CD" : "#63A0EE"
              }`,
            }}
          >
            Direita
          </button>
        </div>
        <div className="settings-text">
          <img src={Settings} alt="" />
          <span>
            Para implementar o embed, copie o código abaixo e cole no seu HTML
          </span>
        </div>
        <div className="embed-link">
          <Tooltip placement="top" isOpen={tooltipCopyCode} target={"copyCode"}>
            Código Copiado
          </Tooltip>
          <button
            id="copyCode"
            className="button-copy"
            onClick={() => {
              navigator.clipboard.writeText(embedLink);
              handleTooltip("tooltipCopyCode");
            }}
          >
            Copiar
          </button>
          {embedLink}
        </div>
        <span>
          *A cor do botão e o avatar acompanham as customizações efetuadas no
          chatbot.
        </span>
      </div>
    );
  };

  const handleEmbedDirection = (direction) => {
    setEmbedDirection(direction);
  }; */

  const handleTooltip = async (tooltipSelected) => {
    switch (tooltipSelected) {
      case "tooltipCopy":
        setTooltipCopy(true);
        setTimeout(() => {
          setTooltipCopy(false);
        }, 3000);
        break;
      case "tooltipQrcode":
        setTooltipQrcode(true);
        setTimeout(() => {
          setTooltipQrcode(false);
        }, 3000);
        break;
      /* case "tooltipEmbed":
        setTooltipEmbed(true);
        setTimeout(() => {
          setTooltipEmbed(false);
        }, 3000);
        break;
      case "tooltipCopyCode":
        setTooltipCopyCode(true);
        setTimeout(() => {
          setTooltipCopyCode(false);
        }, 3000);
        break; */
      default:
        break;
    }
  };

  const handleMultipleAnswer = async () => {
    await ProjectsService.setMultipleAnswer(
      projectId,
      !multipleAnswer,
      userInformation.email
    );
    setMultipleAnswer(!multipleAnswer);
  };

  const handleDownloadImage = async () => {
    const element = document.getElementById("printQr");
    let canvas = await html2canvas(element);
    let data = canvas.toDataURL("image/jpg");
    let link = document.createElement("a");

    link.href = data;
    link.download = "qr-code.jpg";

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    const getProjectInformation = async () => {
      let validationProjectIdExists = [];

      if (userInformation.subscription !== "IdssManager") {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element === projectId ? element : null;
          }
        );
      } else {
        validationProjectIdExists = userInformation.projects.filter(
          (element) => {
            return element.idProject === projectId ? element : null;
          }
        );
      }

      if (
        validationProjectIdExists.length > 0 ||
        userInformation.subscription === "IdssManager"
      ) {
        const userProject = await ProjectsService.getSpecificProject(projectId);
        if (userProject[0].multiple_answer) {
          setMultipleAnswer(true);
        } else {
          setMultipleAnswer(false);
        }

        if (userProject[0].share_final) {
          setShareProjectInFinal(true);
        } else {
          setShareProjectInFinal(false);
        }

        if (userProject[0].link) {
          setLink(userProject[0].link);
          setProjectLinkStock(userProject[0].link);
        }
        setLoading(false);
      }
    };

    if (userInformation) {
      getProjectInformation();
    }
    // eslint-disable-next-line
  }, [userInformation]);

  const handleLink = (value) => {
    if (value.match(LINK_REGEX) || value === "") {
      setLink(value);
      setTooltipUrl(false);
    } else {
      setTooltipMessage("Permitido somente letras e/ou números");
      setTooltipUrl(true);
    }
  };

  const handleSetLinkOnDatabase = async () => {
    if (link.length < 6) {
      setTooltipMessage("Coloque no mínimo 6 caracteres para o link");
      setTooltipUrl(true);
      return;
    }

    if (projectLinkStock !== link) {
      let objectData =
        userInformation.subscription === "IdssManager"
          ? {
              userEmail: userInformation.email,
              campaign: projectId,
              url: link,
              subscription: "IdssManager",
            }
          : {
              userEmail: userInformation.email,
              campaign: projectId,
              url: link,
            };

      let response = await ProjectsService.setLinkOfProject(objectData);
      if (response === "This url already exists") {
        setTooltipMessage("Este link já existe");
        setTooltipUrl(true);
        return;
      } else if (response === "An error happened") {
        setTooltipMessage("Um erro aconteceu. Tente novamente mais tarde.");
        setTooltipUrl(true);
        return;
      } else {
        setEditLink(false);
        setProjectLinkStock(link);
      }
    } else {
      setEditLink(false);
    }
    setTooltipUrl(false);
  };

  const handleCancelEditLink = () => {
    setEditLink(false);
    setLink(projectLinkStock);
  };
  // const handleResetLinkOnDatabase = async () => {
  //   let objectData =
  //     userInformation.subscription === "IdssManager"
  //       ? {
  //           userEmail: userInformation.email,
  //           campaign: projectId,
  //           url: projectId,
  //           subscription: "IdssManager",
  //         }
  //       : {
  //           userEmail: userInformation.email,
  //           campaign: projectId,
  //           url: projectId,
  //         };

  //   setLink(projectId);
  //   let response = await ProjectsService.setLinkOfProject(objectData);
  //   if (response) {
  //     setEditLink(false);
  //   }
  //   setProjectLinkStock(link);
  // };

  useEffect(() => {
    if (/* showEmbed ||  */showQrCode) {
      window.scroll(0, document.getElementById("body").clientHeight + 1000);
    }
  }, [/* showEmbed,  */showQrCode]);

  useEffect(() => {
    let URL = encodeURIComponent(process.env.REACT_APP_BASE_URL + link);
    setURLFormated(URL);
  }, [link]);

  const handleShareProjectInFinal = async () => {
    await ProjectsService.setShareProjectInFinal(
      projectId,
      !shareProjectInFinal ? 1 : 0,
      userInformation.email
    );
    setShareProjectInFinal(!shareProjectInFinal);
  };

  if (loading) {
    return <Loading />;
  } else {
    return (
      <div id="body" className="compShare">
        <ChatbotHeader
          handleShare={() => {}}
          questionValues={chatbot}
          page={"share"}
          projectId={projectId}
        />
        <NavigationHeader page="share" projectId={projectId} />
        <div className="container_message">
          <div className="contentShare">
            <div className="firstMessageArea">
              <div>
                <img className="hazo-image" alt="" src={Hazo} />
              </div>
              <div className="link-container">
                <div className="box">
                  <b>Compartilhe o seu projeto</b>
                </div>
              </div>
            </div>
            <div className="box boxLink">
              <Tooltip
                placement="top"
                isOpen={tooltipUrl}
                target={"tooltipUrl"}
              >
                {tooltipMessage}
              </Tooltip>
              <div id="tooltipUrl">
                {editLink ? (
                  <>
                    {process.env.REACT_APP_BASE_URL}
                    <input
                      onChange={(evt) => handleLink(evt.target.value)}
                      value={link}
                    />
                  </>
                ) : (
                  <a
                    className="linkToShare"
                    href={`${process.env.REACT_APP_BASE_URL}${link}`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    {`${process.env.REACT_APP_BASE_URL}${link}`}
                  </a>
                )}
              </div>
              {editLink ? (
                <>
                  <img
                    alt="check"
                    onClick={() => handleSetLinkOnDatabase()}
                    src={Check}
                  />
                  <img
                    alt="cancel"
                    className="cancelButton"
                    onClick={() => handleCancelEditLink()}
                    src={Cancel}
                  />
                  {/* <img
                    alt="reset"
                    onClick={() => handleResetLinkOnDatabase()}
                    src={Reset}
                  /> */}
                </>
              ) : (
                <img
                  alt="edit"
                  onClick={() => {
                    /* setShowEmbed(false); */
                    setShowQrCode(false);
                    setEditLink(true);
                  }}
                  src={Edit}
                />
              )}
            </div>
            <div className="boxShare">
              <Tooltip
                placement="top"
                isOpen={tooltipCopy}
                target={"tooltipCopy"}
              >
                Link copiado
              </Tooltip>
              <div
                className="optionShare"
                id="tooltipCopy"
                onClick={() => {
                  copyToClipboard();
                  handleTooltip("tooltipCopy");
                }}
              >
                <img alt="Copy" src={Copy} />
                <p>Copy</p>
              </div>
              <Tooltip
                placement="top"
                isOpen={tooltipQrcode}
                target={"tooltipQrcode"}
              >
                QR-Code gerado
              </Tooltip>
              <div
                className="optionShare"
                id="tooltipQrcode"
                onClick={() => {
                  handleQrCode();
                  handleTooltip("tooltipQrcode");
                }}
              >
                <img alt="QR-Code" src={QRCodeImg} />
                <p>QR-Code</p>
              </div>
              {/* <Tooltip
                placement="top"
                isOpen={tooltipEmbed}
                target={"tooltipEmbed"}
              >
                Embed Gerado
              </Tooltip>
              <div
                className="optionShare"
                id="tooltipEmbed"
                onClick={() => {
                  setShowEmbed(true);
                  handleTooltip("tooltipEmbed");
                }}
              >
                <img alt="Embed" src={Embed} />
                <p>Embed</p>
              </div> */}
              <a
                className="optionShare"
                href={`whatsapp://send?text=Olá! Acho que você vai gostar desse chatbot: ${process.env.REACT_APP_BASE_URL}${link}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Whatsapp App" src={WhatsappApp} />
                <p>Whatsapp</p>
                <p>App</p>
              </a>
              <a
                className="optionShare"
                href={`https://web.whatsapp.com/send?text=Olá%21%20Acho%20que%20você%20vai%20gostar%20desse%20chatbot%3A%20${URLFormated}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Whatsapp Web" src={WhatsappWeb} />
                <p>Whatsapp</p>
                <p>Web</p>
              </a>

              <a
                className="optionShare"
                href={`fb://faceweb/f?href=https%3A%2F%2Fm.facebook.com%2Fsharer.php%3Fu%3D${URLFormated}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Facebook App" src={FacebookApp} />
                <p>Facebook</p>
                <p>App</p>
              </a>
              <a
                className="optionShare"
                href={`https://www.facebook.com/sharer/sharer.php?u=${process.env.REACT_APP_BASE_URL}${link}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Facebook Web" src={Facebook} />
                <p>Facebook</p>
                <p>Web</p>
              </a>
              <a
                className="optionShare"
                href={`twitter://post?text=Olá%21%20Acho%20que%20você%20vai%20gostar%20desse%20chatbot%3A%20${URLFormated}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Twitter" src={TwitterApp} />
                <p>Twitter</p>
                <p>App</p>
              </a>
              <a
                className="optionShare"
                href={`https://twitter.com/intent/tweet?text=Olá! Acho que você vai gostar desse chatbot: ${process.env.REACT_APP_BASE_URL}${link}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Twitter" src={Twitter} />
                <p>Twitter</p>
                <p>Web</p>
              </a>
              <a
                className="optionShare"
                href={`https://www.linkedin.com/sharing/share-offsite/?url=${process.env.REACT_APP_BASE_URL}${link}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Linkedin" src={Linkedin} />
                <p>Linkedin</p>
              </a>
              <a
                className="optionShare"
                href={`mailto:?subject=Feedback&body=Olá! Acho que você vai gostar desse chatbot: ${process.env.REACT_APP_BASE_URL}${link}`}
                rel="noreferrer"
                target="_blank"
              >
                <img alt="Email" src={Email} />
                <p>Email</p>
              </a>
            </div>
            <div className="box boxMultipleAnswer">
              Permitir várias respostas
              <ButtonOnOff
                on={multipleAnswer ? true : false}
                handleValue={handleMultipleAnswer}
              />
            </div>
            <div className="box boxMultipleAnswer">
              Compartilhamento via whatsapp na despedida
              <ButtonOnOff
                on={shareProjectInFinal ? true : false}
                handleValue={handleShareProjectInFinal}
              />
            </div>
            {showQrCode}
            {/* {showEmbed && getEmbedCode()} */}
          </div>
        </div>
      </div>
    );
  }
};

export default Share;
