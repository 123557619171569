import Axios from "axios";
import { firebaseFirestore } from "../Config/firebase.js";
import UserService from "./UserService";

require("dotenv").config();

export default class PaypalService {
  static cancelSubscription = async (idSubscription, reason) => {
    const configHeaders = {
      "content-type": "application/json",
      Accept: "application/json",
    };

    let response = await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      headers: configHeaders,
      url: `https://api-m.sandbox.paypal.com/v1/billing/subscriptions/${idSubscription}/cancel`,
      auth: {
        username: process.env.REACT_APP_PAYPAL_USER_ID,
        password: process.env.REACT_APP_PAYPAL_USER_SECRET,
      },
      data: JSON.stringify({ reason: reason }),
    })
      .then(() => {
        return "";
      })
      .catch((err) => {
        return err;
      });

    if (response === "") {
      let userEmail = UserService.getCurrentUserEmail();
      await firebaseFirestore
        .collection("users")
        .where("email", "==", userEmail)
        .get()
        .then((snap) => {
          snap.forEach(async (document) => {
            let docRef = document.id;
            await firebaseFirestore.collection("users").doc(docRef).update({
              subscription: "Free",
              idSubscriptionPaypal: null,
            });
          });
        });
    }
    return response;
  };
}
