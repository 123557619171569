import React from "react";
import "./styles.scss";
import Loader from "react-loader-spinner";

const LoadingProjects = () => {
  return (
    <div className="container-loader-projects">
      <Loader type="Oval" color="#609ef1" height="80" width="80" />
    </div>
  );
};

export default LoadingProjects;
