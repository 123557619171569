import React, { useEffect, useState } from "react";
import "./style.scss";
import Profile from "../../Assets/img/profile-blue.png";

const SquareProject = (props) => {
  const [windowDimensions, setWindowDimensions] = useState(null);
  const textSize = props.subscription === "IdssManager" ? 25 : 55;
  const emailSize = 25;

  useEffect(() => {
    const handleResize = () => {
      let dimensions = window.innerWidth;
      setWindowDimensions(dimensions);
    };

    window.addEventListener("resize", handleResize);
    return handleResize();
  }, []);

  const getDate = () => {
    if (!props.createDate) return;
    let day =
      props.createDate.getDate() < 10
        ? "0" + props.createDate.getDate()
        : props.createDate.getDate();
    let month =
      props.createDate.getMonth() + 1 < 10
        ? "0" + (props.createDate.getMonth() + 1)
        : props.createDate.getMonth() + 1;
    const year = props.createDate.getFullYear();

    return (
      <span>
        Criado em: {day} / {month} /{year}
      </span>
    );
  };

  const resizeText = (text, type = null) => {
    if (text !== null) {
      if (type === "email") {
        const newArr = text.slice(0, emailSize);
        return `${newArr}...`;
      }

      if (text.length > textSize) {
        const newArr = text.slice(0, textSize);
        return `${newArr}...`;
      } else {
        return text;
      }
    }
  };

  const handleEmail = () => {
    if (windowDimensions > 840) {
      return <span>{props.user}</span>;
    } else {
      return resizeText(props.user, "email");
    }
  };

  return (
    <div className="squareProjectBox">
      <div className="title">
        <h4>
          {windowDimensions > 840 ? props.title : resizeText(props.title)}
        </h4>
        {props.user && handleEmail()}
        {getDate()}
      </div>
      {props.qtdAnswers ? (
        <>
          <div className="line"></div>
          <div className="qtdAnswers">
            <img alt="profile" className="profileIcon" src={Profile} />
            {props.qtdAnswers}
          </div>
        </>
      ) : null}
    </div>
  );
};

export default SquareProject;
