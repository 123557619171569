import React from "react";
import InputColor from "../InputColor";

const ClosedAnswer = (props) => {
  return (
    <div>
      {props.questionValues.questions[props.questionNumber].options.map(
        (option, index) => {
          return (
            <div key={index} className="options">
              <div>Opção {index + 1}:</div>
              <InputColor
                option={option}
                index={index}
                questionNumber={props.questionNumber}
                handleColor={props.handleColor}
                setTooltipOpen={props.setTooltipOpen}
                handleOptionValue={props.handleOptionValue}
                handleClickInput={props.handleClickInput}
                handleRemoveOption={props.handleRemoveOption}
                handleKeyDown={props.handleKeyDown}
                moveDownOption={props.moveDownOption}
                moveUpOption={props.moveUpOption}
              />
            </div>
          );
        }
      )}
      <button
        className="buttonAddOption"
        onClick={() => props.handleAddOption(props.questionNumber)}
      >
        Adicionar Opção
      </button>
    </div>
  );
};

export default ClosedAnswer;
