import React, { createContext, useEffect, useState } from "react";
import { Auth } from "../Services/AuthService";
import CampaignService from "../Services/CampaignService";
import DictionaryService from "../Services/DictionaryService";
import ProjectsService from "../Services/ProjectsService";
import UserService from "../Services/UserService";
import MessagesService from "../Services/MessagesService";

const Context = createContext();

const Provider = ({ children }) => {
  const [chatbot, setChatbot] = useState({
    robot_name: "Hazo, o Camaleão",
    robot_image_name: "hazo.svg",
    robot_blob: null,
    business_image_name: "lettering-hazo.svg",
    business_blob: null,
    chatbot_header_color: "#609ef1",
    disclaimer_initial: {
      value: "",
      edit: 1,
    },
    project_name: "",
    questions: [],
    multiple_answers: false,
    usedNumbers: [],
  });
  const questions = [
    {
      type: "menuInteractive",
      name: "Menu interativo com links",
      question: "Menu interativo com links",
    },
    {
      type: "starScale",
      name: "Escala Estrela",
      question: "Escala Estrela",
    },
    {
      type: "openAnswer",
      name: "Pergunta Aberta",
      question: "Pergunta Aberta",
    },
    { type: "emojiScale", name: "Escala Emoji", question: "Escala Emoji" },
    {
      type: "closeAnswer",
      name: "Pergunta Fechada",
      question: "Pergunta Fechada",
    },
    { type: "insertImage", name: "Inserir Link Imagem", question: "Imagem" },
    { type: "insertVideo", name: "Inserir Link Vídeo", question: "Vídeo" },
    {
      type: "insertParagraph",
      name: "Inserir Parágrafo",
      question: "Parágrafo",
    },
    {
      type: "likeDislike",
      name: "Like e Dislike",
      question: "Like e Dislike",
    },
    {
      type: "multipleChoice",
      name: "Múltipla Escolha",
      question: "Múltipla Escolha",
    },
    {
      type: "numericalScale",
      name: "Escala Numérica",
      question: "Escala Numérica",
    },
  ];
  const [idProject, setIdProject] = useState(null);
  const [userProjects, setUserProjects] = useState([]);
  const [userProjectsQtdAnswers, setUserProjectsQtdAnswers] = useState([]);
  const [userInformation, setUserInformation] = useState(null);
  const [sheetLink, setSheetLink] = useState("");
  const [userMessages, setUserMessages] = useState([]);
  const [qtdNewMessages, setQtdNewMessages] = useState(0);
  const [dataTableXlsx, setDataTableXlsx] = useState(null);
  const [loadingEngagement, setLoadingEngagement] = useState(false);
  const youtubeRegex =
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.be)\/(watch\?v=|embed\/|v\/)?([\w-]{11})(\S*)?$/;

  useEffect(() => {
    const handleCancelSubscription = async () => {
      if (userInformation?.subscription_until) {
        let subscription_until = new Date(
          userInformation?.subscription_until.seconds * 1000
        );

        let today = new Date();
        if (today > subscription_until) {
          await UserService.resetAccountPlan();
          await updateUserInformation();
        }
      }
    };

    if (userInformation) {
      handleCancelSubscription();
    }
  }, [userInformation]);

  const getProjectsInFirebase = async (userProjects) => {
    let projectsInformation = [];
    if (userInformation.subscription === "IdssManager") {
      projectsInformation = await ProjectsService.getProjectsIdss();
    }

    if (userProjects.length > 0) {
      projectsInformation = [
        ...projectsInformation,
        { user: userInformation.email, projects: userInformation.projects },
      ];
    }

    const arrayToReturn = [];
    for (let i = 0; i < projectsInformation.length; i++) {
      let projects = await ProjectsService.getProjects(
        projectsInformation[i].projects
      );

      for (let j = 0; j < projects.length; j++) {
        arrayToReturn.push({
          user: projectsInformation[i].user,
          idProject: projects[j].id,
          nameProject: projects[j].name,
          jsonProject: projects[j].json,
          status: projects[j].status,
          multipleAnswer: projects[j].multiple_answer,
          share_final: projects[j].share_final,
          link: projects[j].link,
          data: projects[j].created_at,
          usedNumbers: projects[j].used_numbers,
          folder: projects[j].folder,
          filterQuestion: projects[j].filterQuestion,
        });
      }
    }
    return arrayToReturn;
  };

  const createNewIdCampaign = async () => {
    let response = await CampaignService.createNewIdCampaign();
    setIdProject(response.message);
    return response.message;
  };

  const createNewProject = async (projectName) => {
    let idNewProject = await createNewIdCampaign();
    setIdProject(idNewProject);

    let chatbotStructure = await resetChatbot(projectName);
    await ProjectsService.newProjectInFirebase(userInformation, {
      id: idNewProject,
      name: projectName,
      structure: chatbotStructure,
    });

    await CampaignService.createNewCampaign(
      JSON.stringify({
        campaign: idNewProject,
        userEmail: userInformation.email,
      })
    );
    await CampaignService.createNewStaticLink(
      JSON.stringify({
        userEmail: userInformation.email,
        campaign: idNewProject,
        staticLink: idNewProject,
        cookieTime: 15,
      })
    );
    await createDictionary(chatbotStructure, idNewProject);
    return idNewProject;
  };

  const createDictionary = async (chatbotToAPI, projectId) => {
    let emailToQuery = userInformation.email;
    if (userInformation.subscription === "IdssManager") {
      let response = await ProjectsService.getOwnerProject(
        JSON.stringify({
          idProject: projectId,
        })
      );
      emailToQuery = response.user;
    }

    setChatbot(chatbotToAPI);
    await DictionaryService.createNewDictionary(
      JSON.stringify({
        email: emailToQuery,
        idproject: projectId,
        logic: chatbotToAPI,
      })
    );
  };

  const resetChatbot = async (projectName = "") => {
    let chatbotStructure = {
      robot_name: "Hazo, o Camaleão",
      robot_image_name: "hazo.svg",
      business_image_name: "lettering-hazo.svg",
      chatbot_header_color: "#609ef1",
      disclaimer_initial: {
        value: "",
        edit: 1,
      },
      project_name: projectName,
      questions: [],
    };
    await setChatbot(chatbotStructure);

    return chatbotStructure;
  };

  const saveProject = async (projectId, jsonProject, nameProject) => {
    await ProjectsService.saveProject(projectId, jsonProject, nameProject);
  };

  const duplicateProject = async (project, projectName) => {
    let response = await CampaignService.createNewIdCampaign();
    let idNewProject = response.message;
    let objectProject = JSON.parse(project.jsonProject);

    setIdProject(idNewProject);
    setChatbot(objectProject);
    await ProjectsService.newProjectInFirebase(userInformation, {
      id: idNewProject,
      name: projectName,
      structure: objectProject,
    });
    await CampaignService.createNewCampaign(
      JSON.stringify({
        campaign: idNewProject,
        userEmail: userInformation.email,
      })
    );
    await CampaignService.createNewStaticLink(
      JSON.stringify({
        userEmail: userInformation.email,
        campaign: idNewProject,
        staticLink: idNewProject,
        cookieTime: 15,
      })
    );
    await createDictionary(objectProject, idNewProject);

    let arrayProjects = [...userInformation.projects, idNewProject];
    setUserInformation({
      ...userInformation,
      projects: arrayProjects,
    });

    return idNewProject;
  };

  const duplicateTemplate = async (template, templateName) => {
    let response = await CampaignService.createNewIdCampaign();
    let idNewProject = response.message;
    let objectTemplate = JSON.parse(template.json);

    setIdProject(idNewProject);
    setChatbot(objectTemplate);
    await ProjectsService.newProjectInFirebase(userInformation, {
      id: idNewProject,
      name: templateName,
      structure: objectTemplate,
    });
    await CampaignService.createNewCampaign(
      JSON.stringify({
        campaign: idNewProject,
        userEmail: userInformation.email,
      })
    );
    await CampaignService.createNewStaticLink(
      JSON.stringify({
        userEmail: userInformation.email,
        campaign: idNewProject,
        staticLink: idNewProject,
        cookieTime: 15,
      })
    );
    await createDictionary(objectTemplate, idNewProject);

    let arrayProjects = [...userInformation.projects, idNewProject];
    setUserInformation({
      ...userInformation,
      projects: arrayProjects,
    });

    return idNewProject;
  };

  const updateUserInformation = async () => {
    let userInformation = await UserService.getUserInformation();
    setUserInformation(userInformation);
  };

  useEffect(() => {
    const getUserData = async () => {
      let userInformation = await UserService.getUserInformation();
      setUserInformation(userInformation);
    };

    const getUserMessages = async () => {
      let messages = await MessagesService.getMessagesByUser();
      setUserMessages(messages);
      getQtdNewMessages(messages);
    };

    Auth.onAuthStateChanged((currentUser) => {
      if (currentUser) {
        getUserData();
        getUserMessages();
      }
    });
  }, []);

  const resetAllInformations = () => {
    setIdProject(null);
    setUserProjects([]);
    setUserProjectsQtdAnswers([]);
    setUserInformation(null);
  };

  const handleTotalInteractions = () => {
    switch (userInformation?.subscription) {
      case "Free 1.0":
        return 50;
      case "Basic 1.0":
        return 300;
      case "Premium 1.0":
        return 600;
      case "Ultra 1.0":
        return 1200;
      case "Free":
      case "Founder":
        return 300;
      case "Basic":
        return 600;
      case "Premium":
      case "Idss":
        return 1500;
      case "IdssUltra":
      case "Ultra":
        return 5000;
      case "Enterprise":
      case "IdssManager":
        return "ilimitado";
      default:
        break;
    }
  };

  const getQtdNewMessages = (messages) => {
    let qtd = 0;
    for (let i = 0; i < messages.length; i++) {
      if (parseInt(messages[i].is_new_message)) {
        qtd += 1;
      }
    }
    setQtdNewMessages(qtd);
  };

  const getNumberQuestionInDictionary = (dataFirebase, question) => {
    const questionMessage = question.replace(/^P\d+ - /, "");
    const questionDictionary = dataFirebase.questions.filter(
      (project) => project.message === questionMessage
    );
    const questionNumber = questionDictionary[0].number;

    const formatedQuestionNumber =
      Math.abs(questionNumber).toString().length === 1
        ? `q_0${questionNumber}`
        : `q_${questionNumber}`;

    return formatedQuestionNumber;
  };

  return (
    <Context.Provider
      value={{
        chatbot,
        questions,
        userProjects,
        getProjectsInFirebase,
        createNewProject,
        createDictionary,
        idProject,
        resetChatbot,
        setChatbot,
        userInformation,
        sheetLink,
        setSheetLink,
        setIdProject,
        setUserInformation,
        saveProject,
        userProjectsQtdAnswers,
        duplicateProject,
        duplicateTemplate,
        resetAllInformations,
        handleTotalInteractions,
        updateUserInformation,
        setUserProjects,
        userMessages,
        setUserMessages,
        qtdNewMessages,
        setQtdNewMessages,
        getQtdNewMessages,
        dataTableXlsx,
        setDataTableXlsx,
        getNumberQuestionInDictionary,
        loadingEngagement,
        setLoadingEngagement,
        youtubeRegex,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export { Provider, Context };
