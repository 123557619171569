import React from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { FaArrowLeft } from "react-icons/fa";
import HazoError from "../../../Assets/img/256PX-02.png";

const ModalCancelPlanConfirmation = (props) => {
  const history = useHistory();

  const toggle = () => {
    props.setModal(!props.modal);
  };

  if (props.modal) {
    return (
      <div className="ModalConfimationProjectDelete">
        <div className="content">
          <div className="icon">
            <button className="btn-icon">
              <FaArrowLeft onClick={() => toggle()} />
            </button>
          </div>
          <img src={HazoError} alt="camaleão-hazo" />
          <p>
            Sentimos muito em sabe que você deseja cancelar seu plano. Antes de
            continuar, gostaria que nossa equipe entrasse em contato para
            entender sua escolha?
          </p>
          <div className="modalButtonArea">
            <button className="button" onClick={() => toggle()}>
              Sim
            </button>
            <button
              className="onlyUnderline"
              onClick={() => history.push("/cancelPlan")}
            >
              Não, continuar cancelamento
            </button>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ModalCancelPlanConfirmation;
