import React, { useContext, useState } from "react";
import "./styles.scss";
import { BsFillPieChartFill, BsFileBarGraphFill } from "react-icons/bs";
import { FaShareSquare } from "react-icons/fa";
import { Context } from "../../Context/Context";
import { Tooltip } from "reactstrap";

const MenuReports = ({ modalExport, setTabNavigation, tabNavigation, existAnswers }) => {
  const { userInformation } = useContext(Context);
  const [tooltipOpen, setTooltipOpen] = useState(false);

  return (
    <div className="container-menu">
      <div className="content-menu">
        <div className="btn-section">
          <button
            className={tabNavigation === "report" ? "active" : "btn"}
            value={"report"}
            onClick={() => setTabNavigation("report")}
          >
            <BsFillPieChartFill />
            Relatórios
          </button>
          <Tooltip
            placement="bottom"
            popperClassName="tooltipZero"
            isOpen={tooltipOpen}
            target={"ButtonDatabase"}
          >
            Está funcionalidade não faz parte do seu plano.
          </Tooltip>
          <button
            id="ButtonDatabase"
            className={tabNavigation === "database" ? "active" : "btn"}
            value={"base"}
            onClick={() => {
              if (
                userInformation.subscription !== "Idss" &&
                userInformation.subscription !== "IdssUltra"
              ) {
                setTabNavigation("database");
              } else {
                setTooltipOpen(true);
                setTimeout(() => {
                  setTooltipOpen(false);
                }, 5000);
              }
            }}
          >
            <BsFileBarGraphFill />
            Base de Dados
          </button>
        </div>
        { existAnswers &&
          <div>
            <button className="btn-share" onClick={() => modalExport(true)}>
              <FaShareSquare />
            </button>
          </div>
        }
      </div>
    </div>
  );
};

export default MenuReports;
