import React from "react";
import "./style.scss";

const NumericalScale = (props) => {
  const handleMin = (value) => {
    let arrayTemp = [...props.questionValues.questions];
    let arrayWithOptions = [];

    if (value < 0) {
      value = 0;
    } else if (value > 10) {
      value = 10;
    } else if (!value) {
      value = 0;
    }

    let difference = arrayTemp[props.questionNumber].max - value;
    for (let i = 0; i <= difference; i++) {
      arrayWithOptions.push({
        color: generateColor(i, difference),
        next: 0,
        value: String(i + value),
      });
    }
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      min: value,
      options: arrayWithOptions,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const handleMax = (value) => {
    let arrayTemp = [...props.questionValues.questions];
    let arrayWithOptions = [];

    if (value < 0) {
      value = 0;
    } else if (value > 10) {
      value = 10;
    } else if (!value) {
      value = 0;
    }

    for (let i = arrayTemp[props.questionNumber].min; i <= value; i++) {
      arrayWithOptions.push({
        color: generateColor(i, value - arrayTemp[props.questionNumber].min),
        next: 0,
        value: String(i),
      });
    }
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      max: value,
      options: arrayWithOptions,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  const generateColor = (position, size) => {
    let jump = 11 / size;
    if (jump === 11) {
      jump = 10;
    } else if (jump === Infinity) {
      jump = 0;
    }

    let arrayColors = [
      "#B72025",
      "#D82028",
      "#EE5223",
      "#F36E23",
      "#FAA723",
      "#FFC828",
      "#ECDC0D",
      "#E8E73D",
      "#C6D92F",
      "#AFCF3D",
      "#64B64F",
    ];
    return arrayColors[
      Math.floor(jump * position) > 10 ? 10 : Math.floor(jump * position)
    ];
  };

  return (
    <div className="scaleArea">
      <div className="optionScale">
        Escala de:
        <input
          className="boxNumber min"
          max="10"
          min="0"
          value={props.questionValues.questions[props.questionNumber].min}
          onChange={(event) => handleMin(Number(event.target.value))}
          type="number"
        />
        (min)
      </div>
      <div className="optionScale">
        Até:
        <input
          className="boxNumber max"
          max="10"
          min="0"
          value={props.questionValues.questions[props.questionNumber].max}
          onChange={(event) => handleMax(Number(event.target.value))}
          type="number"
        />
        (max)
      </div>
    </div>
  );
};

export default NumericalScale;
