import React from "react";
import "./style.scss";
import { FaStoreAlt, FaPalette } from "react-icons/fa";
import { BsChatSquareTextFill } from "react-icons/bs";
import { RiCustomerService2Fill } from "react-icons/ri";
import { useHistory } from "react-router-dom";
import CardPlan from "../../Components/PlanComponents/CardPlan";
import Hazo from "../../Assets/img/hazo.png";

const Plans = () => {
  const history = useHistory();
  const products = [
    {
      account: "Basic",
      description: "Plano Hazo Basic",
      plans: {
        year: "P-75U99199JX2653440MLME4TY",
        month: "P-1753127890342753EMLBSJ7A",
      },
      price: {
        year: 119.9,
        month: 149.9,
        discount: "16%",
      },
      texts: [
        <>
          <BsChatSquareTextFill />
          300 Interações / Respostas
        </>,
        <>
          <FaPalette />
          Customização (Avatar, logo e cor do cabeçalho)
        </>,
        <>
          <FaStoreAlt />
          Link Personalizado ex: hazo.me/nome-do-seu-projeto
        </>,
        <>
          <RiCustomerService2Fill />
          Suporte por e-mail com resposta em até 24h
        </>,
      ],
    },
    {
      account: "Premium",
      description: "Plano Hazo Premium",
      plans: {
        year: "P-0SP333152H913493BMLME5NI",
        month: "P-2PE5116662348874LMJZKPKI",
        discount: "40%",
      },
      price: {
        year: 279,
        month: 355.5,
        discount: "30%",
      },
      texts: [
        <>
          <BsChatSquareTextFill />
          600 Interações / Respostas
        </>,
        <>
          <FaPalette />
          Customização (Avatar, logo e cor do cabeçalho)
        </>,
        <>
          <FaStoreAlt />
          Link Personalizado ex: hazo.me/nome-do-seu-projeto
        </>,
        <>
          <RiCustomerService2Fill />
          Suporte por e-mail com resposta em até 24h
        </>,
      ],
    },
    {
      account: "Ultra",
      description: "Plano Hazo Basic",
      plans: {
        year: "P-62T960193V522335YMJ5IRLA",
        month: "P-5TA2641554985942NMJZKNUY",
      },
      price: {
        year: 499.9,
        month: 625.9,
        discount: "40%",
      },
      texts: [
        <>
          <BsChatSquareTextFill />
          1.200 Interações / Respostas
        </>,
        <>
          <FaPalette />
          Customização (Avatar, logo e cor do cabeçalho)
        </>,
        <>
          <FaStoreAlt />
          Link Personalizado ex: hazo.me/nome-do-seu-projeto
        </>,
        <>
          <RiCustomerService2Fill />
          Suporte por e-mail com resposta em até 24h
        </>,
      ],
    },
    {
      account: "Enterprise",
      button: "Entrar em contato",
      text: [
        <>
          Faltou alguma coisa? Conte pra gente sobre o seu projeto.
          <br />
          Entre em contato através email: hazo@hazo.app
        </>,
      ],
    },
    {
      account: "Free",
      button: "Mudar Plano",
      price: {
        year: 0.0,
        month: 0.0,
      },
      texts: [
        <>
          <BsChatSquareTextFill />
          50 Interações / Respostas
        </>,
        <>
          <FaPalette />
          Customização (Avatar, logo e cor do cabeçalho)
        </>,
        <>
          <FaStoreAlt />
          Link Personalizado ex: hazo.me/nome-do-seu-projeto
        </>,
      ],
      textDisabled: true,
    },
  ];

  return (
    <section className="paymentStyled">
      <div className="iconGoBack">
        <button className="buttonGoBack" onClick={() => history.goBack()}>
          Voltar
        </button>
      </div>

      <div>
        <div className="img-hazo">
          <img src={Hazo} alt="camaleão-hazo" />
          <h3 className="small-heading">
            Escolha o plano que melhor se encaixa nas suas demandas
          </h3>
        </div>
        <div className="card-con">
          {products.map((product, index) => {
            return <CardPlan key={index} product={product} />;
          })}
        </div>
      </div>
    </section>
  );
};

export default Plans;
