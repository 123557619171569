import React from "react";
import "./style.scss";
import Hazo from "../../Assets/img/512PX-02.png";

const PageNotFound = () => {
  return (
    <div className="CompPageNotFound">
      <img alt="hazo assutado" src={Hazo} />
      <div className="contentArea">
        <span className="fontBig">Ops! </span>
        <br />
        <span className="fontMedium">Essa página não existe!</span>
      </div>
    </div>
  );
};

export default PageNotFound;
