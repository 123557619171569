import React from "react";
import "./style.scss";
import InputColor from "../InputColor";
import Star from "../../../Assets/img/star.svg";

const StarScale = (props) => {
  const handleTypeQuestion = (typeOfQuestion) => {
    let arrayTemp = [...props.questionValues.questions];
    if (typeOfQuestion === "starThreeScale") {
      let optionsOfQuestion = arrayTemp[props.questionNumber].options;
      let restArray = optionsOfQuestion.slice(5, optionsOfQuestion.length);
      arrayTemp[props.questionNumber] = {
        ...arrayTemp[props.questionNumber],
        type: typeOfQuestion,
        options: [
          { color: "#606c88", next: 0, value: "star-1" },
          { color: "#606c88", next: 0, value: "star-2" },
          { color: "#606c88", next: 0, value: "star-3" },
          ...restArray,
        ],
      };
    } else {
      let optionsOfQuestion = arrayTemp[props.questionNumber].options;
      let restArray = optionsOfQuestion.slice(3, optionsOfQuestion.length);
      arrayTemp[props.questionNumber] = {
        ...arrayTemp[props.questionNumber],
        type: typeOfQuestion,
        options: [
          { color: "#606c88", next: 0, value: "star-1" },
          { color: "#606c88", next: 0, value: "star-2" },
          { color: "#606c88", next: 0, value: "star-3" },
          { color: "#606c88", next: 0, value: "star-4" },
          { color: "#606c88", next: 0, value: "star-5" },
          ...restArray,
        ],
      };
    }
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <>
      {" "}
      <div className="optionsArea optionsAreaChoiseStars">
        <div className="optionsStar">
          <div
            className="boxCheck"
            onClick={() => handleTypeQuestion("starThreeScale")}
          >
            {props.questionValues.questions[props.questionNumber].type ===
            "starThreeScale" ? (
              <div className="boxChecked"></div>
            ) : null}
          </div>
          <div className="starsArea">
            <div>
              <img alt="Star" src={Star} />
              <img alt="Star" src={Star} />
              <img alt="Star" src={Star} />
            </div>
            3-stars
          </div>
        </div>
        <div className="optionsStar">
          <div
            className="boxCheck"
            onClick={() => handleTypeQuestion("starFiveScale")}
          >
            {props.questionValues.questions[props.questionNumber].type ===
            "starFiveScale" ? (
              <div className="boxChecked"></div>
            ) : null}
          </div>
          <div className="starsArea">
            <div>
              <img alt="Star" src={Star} />
              <img alt="Star" src={Star} />
              <img alt="Star" src={Star} />
              <img alt="Star" src={Star} />
              <img alt="Star" src={Star} />
            </div>
            5-stars
          </div>
        </div>
      </div>
      <div className="optionsArea">
        <hr />
        {props.questionValues.questions[props.questionNumber].options.map(
          (option, index) => {
            if (option.value.match(/^star-[1-5]$/)) {
              let stars = [];
              for (let i = 1; i <= index + 1; i++) {
                stars.push(<img alt="Star" key={i} src={Star} />);
              }
              return (
                <div key={index} className="options">
                  <div>Opção {index + 1}:</div>
                  {stars}
                </div>
              );
            } else {
              return (
                <div key={index} className="options">
                  <div>Opção {index + 1}:</div>
                  <InputColor
                    option={option}
                    index={index}
                    questionNumber={props.questionNumber}
                    handleColor={props.handleColor}
                    setTooltipOpen={props.setTooltipOpen}
                    handleOptionValue={props.handleOptionValue}
                    handleClickInput={props.handleClickInput}
                    handleKeyDown={props.handleKeyDown}
                    handleRemoveOption={props.handleRemoveOption}
                    moveDownOption={props.moveDownOption}
                    moveUpOption={props.moveUpOption}
                  />
                </div>
              );
            }
          }
        )}
        <button
          className="buttonAddOption"
          onClick={() => props.handleAddOption(props.questionNumber)}
        >
          Adicionar Opção
        </button>
      </div>
    </>
  );
};

export default StarScale;
