import React from "react";
import "./style.scss";
import { Button } from "reactstrap";
import Plus from "../../Assets/img/plus.png";
import PlusBlack from "../../Assets/img/plus-black.png";
import Check from "../../Assets/img/check.png";
import X from "../../Assets/img/x.png";

const EditQuestionMenu = (props) => {
  return (
    <div className="compEditQuestionMenu">
      <Button
        className="buttonSecondary"
        onClick={() => props.handleCancel(props.questionNumber)}
      >
        <img alt="fechar" className="compEditQuestionMenuIcon" src={X} />
      </Button>
      <Button
        className="buttonSecondary"
        onClick={() => props.handleSave(props.questionNumber)}
      >
        <img alt="confirmar" className="compEditQuestionMenuIcon" src={Check} />
      </Button>
      {props.showSavePlusNew ? (
        <Button
          className="buttonPrimary"
          onClick={() => props.handleSaveAndNew(props.questionNumber)}
        >
          <img
            alt="adicionar nova pergunta"
            className="compEditQuestionMenuIcon"
            src={Plus}
          />
        </Button>
      ) : (
        <Button
          className="buttonSecondary"
          onClick={() => props.handleSaveAndNew(props.questionNumber)}
        >
          <img
            alt="adicionar nova pergunta"
            className="compEditQuestionMenuIcon"
            src={PlusBlack}
          />
        </Button>
      )}
    </div>
  );
};

export default EditQuestionMenu;
