import { Bar } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";

const BarChart = ({ data, plugins, chartType }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        right: 50,
        bottom: 0,
        left: 20,
      },
    },
    scales: {
      x: {
        barPercentage: 0.5,
        grid: {
          offset: true,
        },
      },
      y: {
        beggingAtZero: true,
      },
    },
    indexAxis: chartType === "BarChart" && "y",
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        align: "end",
        anchor: "end",
        color: "#000",
        offset: 4,
        font: {
          size: 12,
        },
        formatter: (value) => {
          return `${value}%`;
        },
      },
    },
  };

  return <Bar data={data} plugins={plugins} options={options} />;
};

export default BarChart;
