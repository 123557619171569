import React from "react";
import ButtonOnOff from "../../ButtonOnOff";

const InsertTextBox = (props) => {
  const handleFinishSearch = () => {
    let arrayTemp = [...props.questionValues.questions];
    arrayTemp[props.questionNumber] = {
      ...arrayTemp[props.questionNumber],
      finish: !arrayTemp[props.questionNumber].finish,
    };
    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
  };

  return (
    <>
      <hr />
      <div className="finishSearch">
        <div className="text">Encerrar Pesquisa</div>
        <ButtonOnOff
          on={
            props.questionValues.questions[props.questionNumber].finish
              ? true
              : false
          }
          handleValue={handleFinishSearch}
        />
      </div>
    </>
  );
};

export default InsertTextBox;
