import Axios from "axios";
require("dotenv").config();

export default class CampaignService {
  static createNewIdCampaign = async () => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "get",
      url: `${process.env.REACT_APP_BASE_URL_API}new_idcampaign/`,
    }).then((res) => {
      response = res.data;
    });
    return response;
  };

  static createNewCampaign = async (data) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}new_campaign/`,
      data: data,
    }).then((res) => {
      response = res.data;
    });
    return response;
  };

  static createNewStaticLink = async (data) => {
    let response = null;
    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "post",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}new_static_link/`,
      data: data,
    }).then((res) => {
      response = res.data;
    });
    return response;
  };
}
