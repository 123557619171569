import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Tooltip } from "reactstrap";
import AuthService from "../../Services/AuthService";
import EyeVisible from "../../Assets/img/ICONEOLHOHAZO.png";
import EyeInvisible from "../../Assets/img/ICONEOLHOHAZOFECHADO.png";
import Hazo from "../../Assets/img/256PX-01.png";

const ChangePassword = () => {
  const history = useHistory();
  const [message, setMessage] = useState(false);
  const [messageError, setMessageError] = useState(false);
  const [messageVisible, setMessageVisible] = useState(false);
  const [passwordOld, setPasswordOld] = useState("");
  const [passwordOldVisible, setPasswordOldVisible] = useState(false);
  const [tooltipPasswordOld, setTooltipPasswordOld] = useState(false);
  const [tooltipPasswordMessageOld, setTooltipPasswordMessageOld] =
    useState("");
  const [passwordNew, setPasswordNew] = useState("");
  const [passwordNewVisible, setPasswordNewVisible] = useState(false);
  const [tooltipPasswordNew, setTooltipPasswordNew] = useState(false);
  const [tooltipPasswordNewMessage, setTooltipPasswordNewMessage] =
    useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [passwordConfirmationVisible, setPasswordConfirmationVisible] =
    useState(false);
  const [tooltipPasswordConfirmation, setTooltipPasswordConfirmation] =
    useState(false);
  const [
    tooltipPasswordConfirmationMessage,
    setTooltipPasswordConfirmationMessage,
  ] = useState("");

  const handleChangePassword = async () => {
    if (passwordOld === "") {
      setTooltipPasswordMessageOld("O campo de senha é obrigatório");
      return setTooltipPasswordOld(true);
    }

    if (passwordNew === "") {
      setTooltipPasswordNewMessage("O campo de senha é obrigatório");
      return setTooltipPasswordNew(true);
    }

    if (passwordConfirmation === "") {
      setTooltipPasswordConfirmationMessage(
        "A confirmação de senha é obrigatória"
      );
      return setTooltipPasswordConfirmation(true);
    }

    if (passwordNew !== passwordConfirmation) {
      setTooltipPasswordNewMessage("As senhas estão diferentes");
      return setTooltipPasswordNew(true);
    } else {
      setTooltipPasswordNew(false);
    }

    let response = await AuthService.reAuth(passwordOld, passwordNew);
    if (response.type === "success") {
      setMessageVisible(true);
      setMessageError(false);
      setMessage(response.message);
    } else {
      setMessageVisible(true);
      setMessageError(true);
      setMessage(response.message);
    }
  };

  const handleEnter = (e) => {
    if (e === "Enter") return handleChangePassword();
  };

  return (
    <div className="CompChangePassword">
      <div className="content">
        <div className="textArea">
          Legal, vamos trocar sua senha!
          <br />
          Digita para mim os seus dados!
        </div>
        <div id="triangulo-para-baixo"></div>
        <img alt="logo hazo" className="hazoImage" src={Hazo} />
        <div className="hazotext">Hazo</div>
        <div className="title">Altere a sua senha</div>
        <div className="inputArea">
          <Tooltip
            placement="top"
            isOpen={tooltipPasswordOld}
            target="passwordOld"
          >
            {tooltipPasswordMessageOld}
          </Tooltip>
          <label htmlFor="Senha Antiga">Senha Antiga</label>
          <div>
            <input
              id="passwordOld"
              value={passwordOld}
              onChange={(event) => {
                setPasswordOld(event.target.value);
                setTooltipPasswordOld(false);
              }}
              onKeyDown={(e) => handleEnter(e.key)}
              type={passwordOldVisible ? "text" : "password"}
            />
            <img
              alt={passwordOldVisible ? "olho aberto" : "olho olho fechado"}
              onClick={() => setPasswordOldVisible(!passwordOldVisible)}
              src={passwordOldVisible ? EyeInvisible : EyeVisible}
            />
          </div>
        </div>
        <div className="inputArea">
          <Tooltip
            placement="top"
            isOpen={tooltipPasswordNew}
            target="passwordNew"
          >
            {tooltipPasswordNewMessage}
          </Tooltip>
          <label htmlFor="Nova Senha">Nova Senha</label>
          <div>
            <input
              id="passwordNew"
              value={passwordNew}
              onChange={(event) => {
                setPasswordNew(event.target.value);
                setTooltipPasswordNew(false);
              }}
              onKeyDown={(e) => handleEnter(e.key)}
              type={passwordNewVisible ? "text" : "password"}
            />
            <img
              alt={passwordNewVisible ? "olho aberto" : "olho olho fechado"}
              onClick={() => setPasswordNewVisible(!passwordNewVisible)}
              src={passwordNewVisible ? EyeInvisible : EyeVisible}
            />
          </div>
        </div>
        <div className="inputArea">
          <Tooltip
            placement="top"
            isOpen={tooltipPasswordConfirmation}
            target="passwordNewConfirmation"
          >
            {tooltipPasswordConfirmationMessage}
          </Tooltip>
          <label htmlFor="Confirmação da Nova Senha">
            Confirmação de senha
          </label>
          <div>
            <input
              id="passwordNewConfirmation"
              value={passwordConfirmation}
              onChange={(event) => {
                setPasswordConfirmation(event.target.value);
                setTooltipPasswordConfirmation(false);
              }}
              onKeyDown={(e) => handleEnter(e.key)}
              type={passwordConfirmationVisible ? "text" : "password"}
            />
            <img
              alt={
                passwordConfirmationVisible
                  ? "olho aberto"
                  : "olho olho fechado"
              }
              onClick={() =>
                setPasswordConfirmationVisible(!passwordConfirmationVisible)
              }
              src={passwordConfirmationVisible ? EyeInvisible : EyeVisible}
            />
          </div>
        </div>

        {messageVisible ? (
          <div className={messageError ? "errorMessage" : "sucessMessage"}>
            {message}
          </div>
        ) : null}

        <div
          className="buttonChangePassword buttonHover"
          onClick={() => handleChangePassword()}
        >
          Trocar Senha
        </div>

        <div className="buttonRedirect" onClick={() => history.goBack()}>
          <u>Voltar</u>
        </div>
      </div>
    </div>
  );
};

export default ChangePassword;
