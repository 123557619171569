import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Modal, ModalHeader, Tooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import { Context } from "../../../Context/Context";

const ModalDuplicateTemplate = (props) => {
  const { duplicateTemplate } = useContext(Context);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const history = useHistory();
  const [newProjectName, setNewProjectName] = useState("");

  const toggleTooltip = () => setTooltipOpen(!tooltipOpen);

  const toggle = () => {
    props.setModal(!props.modal);
    if (tooltipOpen) {
      toggleTooltip();
    }
  };

  const handleDuplicate = async () => {
    let response = await duplicateTemplate(props.template, newProjectName);
    history.push(`/botmaker/${response}`);
  };

  useEffect(() => {
    const handleProjectName = () => {
      setNewProjectName("Copia " + props.template.name);
    };

    if (props.template !== null) {
      handleProjectName();
    }
    // eslint-disable-next-line
  }, [props.template]);

  return (
    <Modal
      className="compModalDuplicateTemplate"
      isOpen={props.modal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">
        <Tooltip placement="top" isOpen={tooltipOpen} target="inputTitle">
          Dê um nome para cópia do template
        </Tooltip>
        <div className="divTitle">Dê um nome para cópia do template</div>
        <hr className="hrTitle" />
        <input
          id="inputTitle"
          className="inputTitle"
          placeholder="Digite aqui o nome do seu projeto"
          value={newProjectName}
          onChange={(event) => setNewProjectName(event.target.value)}
        />
        <div className="buttonArea">
          <div className="buttonNotSelected" onClick={() => handleDuplicate()}>
            Duplicar
          </div>{" "}
          <div className="buttonNotSelected" onClick={toggle}>
            Cancelar
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalDuplicateTemplate;
