import React from "react";
import { useHistory } from "react-router-dom";
import "./style.scss";
import Logo121Labs from "../../Assets/img/LOGO121LABS-BRANCO_PNG.png";

const ChangesLog = () => {
  const history = useHistory();

  return (
    <div className="CompChangesLog">
      <h1>Atualizações do Hazo</h1>
      <p className="goBackButton" onClick={() => history.goBack()}>
        Voltar
      </p>
      <div className="content">
        <div>
          <p className="titleChangesLog">Version: 5.1.0</p>
          <p>Data: 08/11/2022</p>
          <p>
            Novo: Integração com google sheets Novo: Tamanho para perguntas do
            tipo imagem Bug: Corrigido bugs de integração e customomização de
            chatbot
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 5.1.0</p>
          <p>Data: 18/10/2022</p>
          <p>
            Novo: Scroll automatico ao terminar de editar uma pergunta Bug:
            Corrigidos bugs
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 5.1.0</p>
          <p>Data: 13/10/2022</p>
          <p>Novo: Removido plano Founder Bug: Corrigidos bugs</p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 5.0.0</p>
          <p>Data: 10/10/2022</p>
          <p>
            Novo: Nova lógica condicionais para questões de múltipla escolha
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 4.0.6</p>
          <p>Data: 07/10/2022</p>
          <p>
            Novo: Visualização do tipo tabela nos relatórios
            <br />
            Novo: Nome do projeto aparecendo na tela de botmaker
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 4.0.5</p>
          <p>Data: 16/09/2022</p>
          <p>
            Novo: Cores autómaticas na perguta de escala numérica
            <br />
            Novo: Ordenação por nomes na página de projetos
            <br />
            Novo: Favicon com fundo translucido
            <br />
            Novo: Estilização para telas com resoluções grandes
            <br />
            Novo: Limitação de visualização dos relatórios de acordo com o tipo
            de plano
            <br />
            Bug: Corrigido download da base de dados retornando vazia
            <br />
            Bug: Corrigido problema em condicionais atribuindo valores errados
            em pergunta do tipo like/dislike
            <br />
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 4.0.4</p>
          <p>Data: 05/09/2022</p>
          <p>
            Bug: Corrigido problema ao abrir chatbot de teste na tela de
            projetos
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 4.0.3</p>
          <p>Data: 01/09/2022</p>
          <p>Novo: Adicionado bloqueio de customizações para contas Free</p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 4.0.2</p>
          <p>Data: 25/08/2022</p>
          <p>
            Novo: Adicioniado data de criação do projeto a tela inicial
            <br />
            Novo: Validação do questionário ao navegar para o compartilhamento
            <br />
            Bug: Troca de númeração das perguntas ao deletar
            <br />
            Bug: Modificação em várias multiplas perguntas quando a mesma é
            duplicada
            <br />
            Bug: Corrigido compartilhamento via whatsapp que estava enviando
            link errado
            <br />
            Bug: Corrigido renderização de logo em formatos não válidos
            <br />
            Bug: Corrigido exportação de PDF em celular
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 4.0.1</p>
          <p>Data: 20/07/2022</p>
          <p>Novo: Disponibilizado assinatura de planos via paypal</p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 3.0.4</p>
          <p>Data: 18/07/2022</p>
          <p>
            Novo: Remoção de quebra de linha nas opções de resposta
            <br />
            Fix: Ajustado estilo do tooltip
            <br />
            Bug: Botão que corta a imagem não aparecia na versão mobile
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 3.0.3</p>
          <p>Data: 13/07/2022</p>
          <p>
            Novo: Adicionado rolagem automatica para perguntar que estão com
            erro na edição
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 3.0.2</p>
          <p>Data: 11/07/2022</p>
          <p>
            Novo: Novo botão para adicionar pergunta durante a edição de uma
            interação.
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 3.0.1</p>
          <p>Data: 08/07/2022</p>
          <p>
            Novo: Nova lógica para montagem do chatbot onde não há problema em
            movimentar e deletar perguntas, e as condicionais se mantém fixadas
            nas perguntas.
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.11</p>
          <p>Data: 08/07/2022</p>
          <p>Novo: Novo nome para alguns gráficos</p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.10</p>
          <p>Data: 29/06/2022</p>
          <p>
            Bug: Corrigido erro ao abrir questionario e retornar página em
            branco
            <br />
            Novo: Resize do box de opção de resposta
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.9</p>
          <p>Data: 23/06/2022</p>
          <p>
            Bug: Corrigido erro ao adicionar opções na pergunta do tipo menu
            interativo
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.8</p>
          <p>Data: 22/06/2022</p>
          <p>
            Bug: Corrigido mudança de cores dos botões na escala emoji e estrela
            <br />
            Bug: Corrigido atualização da URL na página de compartilhamento
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.7</p>
          <p>Data: 21/06/2022</p>
          <p>
            Novo: pode-se adicionar opções de respostas em perguntas
            estruturadas
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.6</p>
          <p>Data: 15/06/2022</p>
          <p>
            Bug: Corrigido a largura do pdf gerado no botmaker.
            <br />
            Bug: Corrigido problema de loop ao deletar perguntas do mesmo tipo.
            <br />
            Novo: Log de atualizações
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.5</p>
          <p>Data: 14/06/2022</p>
          <p>
            Novo: Movimentação de opções de resposta no botmaker.
            <br />
            Novo: Aumentado o tamanho da logo no chatbot.
            <br />
            Novo: Exportação via PDF do botmaker.
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.4</p>
          <p>Data: 13/06/2022</p>
          <p>
            Novo: Adicionado tela de loading em páginas que aguardam dados.
            <br />
            Bug: Erro de condicionais em perguntas do tipo like/dislike.
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.3</p>
          <p>Data: 10/06/2022</p>
          <p>
            Bug: Ajustado problema de não carregamento dos gráficos após
            download da base de dados.
            <br />
            Novo: Agrupamento de respostas em perguntas do tipo de múltipla
            escolha.
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.2</p>
          <p>Data: 09/06/2022</p>
          <p>
            Novo: Restruturação do banco de dados, aumentando a segurança e a
            performance da aplicação.
            <br />
            Novo: Desbloqueio do limite de perguntas.
            <br />
            Novo: Aumentado o limite de caracteres em perguntas abertas de 600
            para 2000.
          </p>
        </div>
        <div>
          <p className="titleChangesLog">Version: 2.0.1</p>
          <p>Data: 07/06/2022</p>
          <p>
            Novo: Otimizado o código da aplicação, para que a mesma fique mais
            leve.
            <br />
            Novo: Implementado novas medidas de segurança na aplicação.
          </p>
        </div>
      </div>
      <div className="footer">
        <div className="footerFont">Powered by</div>
        <img alt="Logo 121 Labs" className="footerImage" src={Logo121Labs} />
      </div>
    </div>
  );
};

export default ChangesLog;
