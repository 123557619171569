import React from "react";
import "./style.scss";
import EscalaEmoji from "../../../Assets/img/escala_emoji.svg";
import EscalaEstrela from "../../../Assets/img/escala_estrela.svg";
import EscalaNumerica from "../../../Assets/img/escala_numerica.svg";
import InserirImagem from "../../../Assets/img/inserir_imagem.svg";
import InserirLinkYoutube from "../../../Assets/img/inserir_link_yt.svg";
import InserirParagrafo from "../../../Assets/img/inserir_paragrafo.svg";
import MenuInteractive from "../../../Assets/img/inserir_link.svg";
import MultipleChoice from "../../../Assets/img/multiple_choice.svg";
import PerguntaLikeDislike from "../../../Assets/img/pergunta_like_dislike.svg";
import PerguntaAberta from "../../../Assets/img/pergunta_aberta.svg";
import PerguntaFechada from "../../../Assets/img/pergunta_fechada.svg";
import { Modal, ModalHeader } from "reactstrap";

const ModalAnswers = (props) => {
  const toggle = () => {
    props.setModal(!props.modal);
  };

  const handleSetQuestion = (typeOfAnswer) => {
    let arrayTemp = props.questionValues.questions;
    switch (typeOfAnswer) {
      case "closeAnswer":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [{ color: "#606c88", next: 0, value: "" }],
        };
        break;
      case "emojiScale":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#606c88", next: 0, value: "emoji-1" },
            { color: "#606c88", next: 0, value: "emoji-2" },
            { color: "#606c88", next: 0, value: "emoji-3" },
            { color: "#606c88", next: 0, value: "emoji-4" },
            { color: "#606c88", next: 0, value: "emoji-5" },
          ],
        };
        break;
      case "insertImage":
      case "insertVideo":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: "",
        };
        break;
      case "insertLink":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [{ color: "#606c88", next: 0, value: "", url: "" }],
        };
        break;
      case "likeDislike":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#606c88", next: 0, value: "Like" },
            { color: "#606c88", next: 0, value: "Dislike" },
          ],
        };
        break;
      case "insertParagraph":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          finish: false,
          type: typeOfAnswer,
        };
        break;
      case "menuInteractive":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            {
              color: "#606c88",
              next: 0,
              value: "",
              url: "",
              type: "link",
            },
          ],
        };
        break;
      case "multipleChoice":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [{ color: "#606c88", next: 0, value: "" }],
        };
        break;
      case "numericalScale":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#B72025", next: 0, value: "0" },
            { color: "#D82028", next: 0, value: "1" },
            { color: "#EE5223", next: 0, value: "2" },
            { color: "#F36E23", next: 0, value: "3" },
            { color: "#FAA723", next: 0, value: "4" },
            { color: "#FFC828", next: 0, value: "5" },
            { color: "#ECDC0D", next: 0, value: "6" },
            { color: "#E8E73D", next: 0, value: "7" },
            { color: "#C6D92F", next: 0, value: "8" },
            { color: "#AFCF3D", next: 0, value: "9" },
            { color: "#64B64F", next: 0, value: "10" },
          ],
          min: 0,
          max: 10,
        };
        break;
      case "openAnswerFreeText":
      case "openAnswerOnlyNumbers":
      case "openAnswerEmail":
      case "openAnswerPhone":
      case "openAnswerCellPhone":
      case "openAnswerCPF":
      case "openAnswerDate":
      case "openAnswerHour":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          size: 200,
          preferNotAnswer: false,
          options: [],
        };
        break;
      case "starThreeScale":
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
          options: [
            { color: "#606c88", next: 0, value: "star-1" },
            { color: "#606c88", next: 0, value: "star-2" },
            { color: "#606c88", next: 0, value: "star-3" },
          ],
        };
        break;

      default:
        arrayTemp[props.questionNumber] = {
          ...arrayTemp[props.questionNumber],
          type: typeOfAnswer,
          error: false,
        };
        break;
    }

    props.setQuestionValues({
      ...props.questionValues,
      questions: arrayTemp,
    });
    toggle();
  };

  return (
    <Modal
      className="compModalAnswers"
      isOpen={props.modal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("closeAnswer")}
        >
          <img src={PerguntaFechada} alt="Pergunta Fechada" />
          <div className="groupTitles">
            <p className="KOAtitle">Pergunta Fechada</p>
            <p className="KOAsubtitle">(Uma resposta entre as opções)</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("multipleChoice")}
        >
          <img src={MultipleChoice} alt="MultipleChoice" />
          <div className="groupTitles">
            <p className="KOAtitle">Múltipla Escolha</p>
            <p className="KOAsubtitle">(Várias respostas entre as opções)</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("openAnswerFreeText")}
        >
          <img src={PerguntaAberta} alt="Pergunta Aberta" />
          <div className="groupTitles">
            <p className="KOAtitle">Pergunta Aberta</p>
            <p className="KOAsubtitle">(Texto, números ou cadastro)</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("numericalScale")}
        >
          <img src={EscalaNumerica} alt="Escala Numerica" />
          <div className="groupTitles">
            <p className="KOAtitle">Escala Numérica</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("starThreeScale")}
        >
          <img src={EscalaEstrela} alt="Escala Estrela" />
          <div className="groupTitles">
            <p className="KOAtitle">Escala Estrela</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("emojiScale")}
        >
          <img src={EscalaEmoji} alt="Escala Emoji" />
          <div className="groupTitles">
            <p className="KOAtitle">Escala Emoji</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("likeDislike")}
        >
          <img src={PerguntaLikeDislike} alt="Pergunta Like Dislike" />
          <div className="groupTitles">
            <p className="KOAtitle">Like e Dislike</p>
            <p className="KOAsubtitle">Curtiu e não curtiu</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("insertImage")}
        >
          <img src={InserirImagem} alt="Inserir Imagem" />
          <div className="groupTitles">
            <p className="KOAtitle">Inserir Link de Imagem</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("insertVideo")}
        >
          <img src={InserirLinkYoutube} alt="Inserir Link YouTube" />
          <div className="groupTitles">
            <p className="KOAtitle">Inserir Link de Vídeo do Youtube</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("menuInteractive")}
        >
          <img src={MenuInteractive} alt="Menu Interactive" />
          <div className="groupTitles">
            <p className="KOAtitle">Menu interativo com links</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleSetQuestion("insertParagraph")}
        >
          <img src={InserirParagrafo} alt="InserirParagrafo" />
          <div className="groupTitles">
            <p className="KOAtitle">Inserir Parágrafo</p>
            <p className="KOAsubtitle">(Box de texto)</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalAnswers;
