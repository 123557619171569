import React from "react";
import Dislike from "../../../Assets/img/dislike.svg";
import Emoji1 from "../../../Assets/img/emoji-1.svg";
import Emoji2 from "../../../Assets/img/emoji-2.svg";
import Emoji3 from "../../../Assets/img/emoji-3.svg";
import Emoji4 from "../../../Assets/img/emoji-4.svg";
import Emoji5 from "../../../Assets/img/emoji-5.svg";
import Like from "../../../Assets/img/like.svg";
import Star from "../../../Assets/img/star.svg";
import "./style.scss";
import "../style.scss";

const RenderQuestions = ({ type, questionValues, questionNumber }) => {
  const handleType = (type, option, index) => {
    switch (type) {
      case "emojiScale":
        return emojiScale(option, index);
      case "likeDislike":
        return likeDislike(option, index);
      case "starThreeScale":
      case "starFiveScale":
        return starScale(option, index);
      default:
        return option.value;
    }
  };

  const emojiScale = (option, index) => {
    if (index === 0) return <img alt="Emoji 1" src={Emoji1} />;
    if (index === 1) return <img alt="Emoji 2" src={Emoji2} />;
    if (index === 2) return <img alt="Emoji 3" src={Emoji3} />;
    if (index === 3) return <img alt="Emoji 4" src={Emoji4} />;
    if (index === 4) return <img alt="Emoji 5" src={Emoji5} />;
    return option.value;
  };

  const imageSend = () => {
    return (
      <div className="imageContainer">
        <img
          alt="Mídia enviada pelo usuário"
          src={questionValues.questions[questionNumber].options}
        />
      </div>
    );
  };

  const likeDislike = (option, index) => {
    if (index === 0)
      return <img className="likeDislikeIconEditOff" alt="like" src={Like} />;
    if (index === 1)
      return (
        <img className="likeDislikeIconEditOff" alt="dislike" src={Dislike} />
      );
    return option.value;
  };

  const multipleChoice = () => {
    return (
      <>
        {questionValues.questions[questionNumber].options.map(
          (option, index) => {
            return (
              <div
                key={index}
                className="showOptionsButton"
                style={{
                  backgroundColor: option.color,
                  color: option.textColor,
                }}
              >
                {option.value}
              </div>
            );
          }
        )}
        {questionValues.questions[questionNumber].otherOption ? (
          questionValues.questions[questionNumber].otherOption.value ? (
            <div className="showOptionsButton">Outra (escreva abaixo)</div>
          ) : null
        ) : null}
        {questionValues.questions[questionNumber].noneQuestion ? (
          questionValues.questions[questionNumber].noneQuestion.value ? (
            <div className="showOptionsButton">
              Nenhuma das opções anteriores
            </div>
          ) : null
        ) : null}
      </>
    );
  };

  const starScale = (option, index) => {
    if (option.value.match(/^star-[0-9]$/)) {
      let starQuantity = [];
      const starNumber = option.value.split("-")[1];
      for (let i = 0; i < starNumber; i++) {
        starQuantity.push(
          <img className="starIconEditOff" key={i} alt="Star" src={Star} />
        );
      }
      return (
        <div
          key={index}
          style={{
            backgroundColor: option.color,
          }}
        >
          {starQuantity}
        </div>
      );
    } else {
      return (
        <div
          key={index}
          style={{
            backgroundColor: option.color,
          }}
        >
          {option.value}
        </div>
      );
    }
  };

  const videoSend = () => {
    return (
      <iframe
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        height="350px"
        src={questionValues.questions[questionNumber].options}
        title="Mídia enviada pelo usuário"
        width="100%"
      />
    );
  };

  if (type === "insertImage") {
    return imageSend();
  } else if (type === "insertVideo") {
    return videoSend();
  } else if (type === "multipleChoice") {
    return multipleChoice();
  } else {
    return questionValues.questions[questionNumber].options.map(
      (option, index) => {
        return (
          <div
            key={index}
            className={
              type === "likeDislike" && index <= 1
                ? "likeDislikeIconEditOff"
                : "showOptionsButton"
            }
            style={{
              backgroundColor: option.color,
            }}
          >
            {handleType(type, option, index)}
          </div>
        );
      }
    );
  }
};

export default RenderQuestions;
