import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Context } from "../../Context/Context";
import Hazo from "../../Assets/img/256PX-01-whiout-padding.svg";
import HeaderProjectsPage from "../../Components/HeaderProjectsPage";
import LoadingProjects from "../../Components/LoadingProjects";
import ModalDuplicateTemplate from "../../Components/Modals/ModalDuplicateTemplate";
import ModalMenuProjects from "../../Components/Modals/ModalMenuProjects/";
import ModalNewProject from "../../Components/Modals/ModalNewProject";
import ModalRenameDuplicateProject from "../../Components/Modals/ModalRenameDuplicateProject/";
import Plus from "../../Assets/img/plus.png";
import ProjectsService from "../../Services/ProjectsService";
import SquareProject from "../../Components/SquareProject";
import TemplateService from "../../Services/TemplateService";
import ProjectsNavigation from "../../Components/ProjectsNavigation";

const Projects = () => {
  const { getProjectsInFirebase, userInformation } = useContext(Context);
  const [controllerOthersRequest, setControllerOthersRequest] = useState(true);
  const [filter, setFilter] = useState("dateDesc");
  const [filterOthers, setFilterOthers] = useState("dateDesc");
  const [loading, setLoading] = useState(false);
  const [modalMenuProjects, setModalMenuProjects] = useState(false);
  const [modalMenuTemplate, setModalMenuTemplate] = useState();
  const [modalNewProject, setModalNewProject] = useState(false);
  const [modalRenameDuplicateProject, setModalRenameDuplicateProject] =
    useState(false);
  const [otherLoading, setOtherLoading] = useState(false);
  const [projectSelected, setProjectSelected] = useState("projects");
  const [projectToModal, setProjectToModal] = useState(null);
  const [templates, setTemplates] = useState([]);
  const [templateToModal, setTemplateToModal] = useState(null);
  const [totalAnswers, setTotalAnswers] = useState([]);
  const [userProjects, setUserProjects] = useState([]);
  const [userOthersProjects, setUserOthersProjects] = useState([]);
  const [userProjectsQtdAnswers, setUserProjectsQtdAnswers] = useState([]);

  useEffect(() => {
    setLoading(true);
    const getProjectsToList = async () => {
      let projectsInFirebase = await getProjectsInFirebase(
        userInformation.projects
      );
      filterOtherProjects(projectsInFirebase);
      projectsInFirebase = filterDefaultProjects(projectsInFirebase);
      let arrayIdsProjects = projectsInFirebase.map((element) => {
        return element.idProject;
      });
      const firstPromise = ProjectsService.getProjectsTotalAnswers(
        userInformation.email,
        arrayIdsProjects
      );
      const secondPromise = ProjectsService.getProjectsTotalAnswers(
        userInformation.email,
        arrayIdsProjects,
        true
      );
      Promise.all([firstPromise, secondPromise]).then(
        ([firstResponse, secondResponse]) => {
          // first promise
          setUserProjectsQtdAnswers(firstResponse);
          projectsInFirebase.map((element, index) => {
            return (projectsInFirebase[index].qtdAnswer = firstResponse[
              element.idProject
            ]
              ? firstResponse[element.idProject]
              : "0");
          });
          setUserProjects(projectsInFirebase);
          // second promise
          let answers =
            Object.keys(secondResponse).length > 0
              ? Object.keys(secondResponse).reduce(
                  (sum, key) => sum + parseFloat(secondResponse[key] || 0),
                  0
                )
              : 0;
          setTotalAnswers(answers);
          setLoading(false);
        }
      );
    };

    if (userInformation) {
      getProjectsToList();
    }

    // eslint-disable-next-line
  }, [userInformation]);

  useEffect(() => {
    const getTemplates = async () => {
      let response = await TemplateService.getTemplates();
      setTemplates(response);
    };

    if (templates.length === 0) {
      getTemplates();
    }

    // eslint-disable-next-line
  }, []);

  const handleProjectToModal = (element) => {
    setProjectToModal(element);
    setModalMenuProjects(true);
  };

  const handleModalRenameDuplicateProject = () => {
    setModalMenuProjects(false);
    setModalMenuTemplate(false);
    setModalRenameDuplicateProject(true);
  };

  const handleTemplateToModal = (element) => {
    setTemplateToModal(element);
    setModalMenuTemplate(true);
  };

  const filterOtherProjects = (projects) => {
    let projectsToSet = projects.filter((element) => {
      return element.folder === "others" ? element : null;
    });
    setUserOthersProjects(projectsToSet);
  };

  const filterDefaultProjects = (projects) => {
    let projectsToSet = projects.filter((element) => {
      return element.folder !== "others" ? element : null;
    });
    return projectsToSet;
  };

  const handleTotalOthersProjects = async () => {
    setOtherLoading(true);
    let arrayIdsProjects = userOthersProjects.map((element) => {
      return element.idProject;
    });
    let projectsQtdAnswers = await ProjectsService.getProjectsTotalAnswers(
      userInformation.email,
      arrayIdsProjects
    );
    setUserProjectsQtdAnswers({
      ...userProjectsQtdAnswers,
      ...projectsQtdAnswers,
    });
    setOtherLoading(false);
  };

  return (
    <div className="projectsComp">
      <ModalNewProject
        modal={modalNewProject}
        setModal={setModalNewProject}
        userProjects={userProjects}
      />
      <ModalRenameDuplicateProject
        modal={modalRenameDuplicateProject}
        setModal={setModalRenameDuplicateProject}
        project={projectToModal}
      />
      <ModalMenuProjects
        project={projectToModal}
        modal={modalMenuProjects}
        userProjects={userProjects}
        setUserProjects={setUserProjects}
        userOthersProjects={userOthersProjects}
        setUserOthersProjects={setUserOthersProjects}
        setModal={setModalMenuProjects}
        setProject={setProjectToModal}
        handleModalRenameDuplicateProject={handleModalRenameDuplicateProject}
      />
      <ModalDuplicateTemplate
        template={templateToModal}
        modal={modalMenuTemplate}
        setModal={setModalMenuTemplate}
        userProjects={userProjects}
      />
      <HeaderProjectsPage name={userInformation?.name} />
      <div className="contentArea">
        <ProjectsNavigation
          controllerOthersRequest={controllerOthersRequest}
          handleTotalOthersProjects={handleTotalOthersProjects}
          projectSelected={projectSelected}
          setControllerOthersRequest={setControllerOthersRequest}
          setProjectSelected={setProjectSelected}
          totalAnswers={totalAnswers}
        />
        {projectSelected === "projects" && (
          <section className="projectsArea">
            <div
              className="newProjectButton"
              onClick={() => setModalNewProject(true)}
            >
              <div className="plusArea">
                <img alt="Plus" className="plus" src={Plus} />
              </div>
              <div className="line"></div>
              <div className="textButton">Crie uma nova interação!</div>
              <img alt="Hazo" className="hazo" src={Hazo} />
            </div>
            <div className="filterArea">
              <select
                className="selectFilter"
                value={filter}
                name="filter"
                onChange={(evt) => setFilter(evt.target.value)}
              >
                <option value="nameAsc">Nome ⇧</option>
                <option value="nameDesc">Nome ⇩</option>
                <option value="dateAsc">Data ⇧</option>
                <option value="dateDesc">Data ⇩</option>
                <option value="answersAsc">Qtd Respostas ⇧</option>
                <option value="answersDesc">Qtd Respostas ⇩</option>
              </select>
            </div>
            {loading && <LoadingProjects />}
            {userProjects &&
              userProjects
                .sort((a, b) => {
                  let projectNameA = a.nameProject.toLowerCase();
                  let projectNameB = b.nameProject.toLowerCase();
                  switch (filter) {
                    case "dateAsc":
                      return (
                        new Date(a.data.toDate()) - new Date(b.data.toDate())
                      );
                    case "dateDesc":
                      return (
                        new Date(b.data.toDate()) - new Date(a.data.toDate())
                      );
                    case "nameAsc":
                      if (projectNameA < projectNameB) {
                        return -1;
                      }
                      if (projectNameA > projectNameB) {
                        return 1;
                      }
                      return null;
                    case "nameDesc":
                      if (projectNameA > projectNameB) {
                        return -1;
                      }
                      if (projectNameA < projectNameB) {
                        return 1;
                      }
                      return null;
                    case "answersAsc":
                      if (parseInt(a.qtdAnswer) > parseInt(b.qtdAnswer)) {
                        return 1;
                      }
                      if (parseInt(a.qtdAnswer) < parseInt(b.qtdAnswer)) {
                        return -1;
                      }
                      return null;
                    case "answersDesc":
                      if (parseInt(a.qtdAnswer) > parseInt(b.qtdAnswer)) {
                        return -1;
                      }
                      if (parseInt(a.qtdAnswer) < parseInt(b.qtdAnswer)) {
                        return 1;
                      }
                      return null;
                    default:
                      return null;
                  }
                })
                .map((element, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleProjectToModal(element)}
                    >
                      <SquareProject
                        user={
                          userInformation.subscription === "IdssManager"
                            ? element.user
                            : null
                        }
                        title={element.nameProject}
                        createDate={element.data.toDate()}
                        qtdAnswers={
                          userProjectsQtdAnswers[element.idProject]
                            ? userProjectsQtdAnswers[element.idProject]
                            : 0
                        }
                      />
                    </div>
                  );
                })}
          </section>
        )}
        {projectSelected === "others" && (
          <section className="projectsArea">
            <div className="filterArea">
              <select
                className="selectFilter"
                value={filterOthers}
                name="filter"
                onChange={(evt) => setFilterOthers(evt.target.value)}
              >
                <option value="nameAsc">Nome ⇧</option>
                <option value="nameDesc">Nome ⇩</option>
                <option value="dateAsc">Data ⇧</option>
                <option value="dateDesc">Data ⇩</option>
                <option value="answersAsc">Qtd Respostas ⇧</option>
                <option value="answersDesc">Qtd Respostas ⇩</option>
              </select>
            </div>
            {otherLoading && <LoadingProjects />}
            {userOthersProjects &&
              userOthersProjects
                .sort((a, b) => {
                  let projectNameA = a.nameProject.toLowerCase();
                  let projectNameB = b.nameProject.toLowerCase();
                  switch (filterOthers) {
                    case "dateAsc":
                      return (
                        new Date(a.data.toDate()) - new Date(b.data.toDate())
                      );
                    case "dateDesc":
                      return (
                        new Date(b.data.toDate()) - new Date(a.data.toDate())
                      );
                    case "nameAsc":
                      if (projectNameA < projectNameB) {
                        return -1;
                      }
                      if (projectNameA > projectNameB) {
                        return 1;
                      }
                      return null;
                    case "nameDesc":
                      if (projectNameA > projectNameB) {
                        return -1;
                      }
                      if (projectNameA < projectNameB) {
                        return 1;
                      }
                      return null;
                    case "answersAsc":
                      if (parseInt(a.qtdAnswer) > parseInt(b.qtdAnswer)) {
                        return 1;
                      }
                      if (parseInt(a.qtdAnswer) < parseInt(b.qtdAnswer)) {
                        return -1;
                      }
                      return null;
                    case "answersDesc":
                      if (parseInt(a.qtdAnswer) > parseInt(b.qtdAnswer)) {
                        return -1;
                      }
                      if (parseInt(a.qtdAnswer) < parseInt(b.qtdAnswer)) {
                        return 1;
                      }
                      return null;
                    default:
                      return null;
                  }
                })
                .map((element, index) => {
                  return (
                    <div
                      key={index}
                      onClick={() => handleProjectToModal(element)}
                    >
                      <SquareProject
                        user={
                          userInformation.subscription === "IdssManager"
                            ? element.user
                            : null
                        }
                        title={element.nameProject}
                        createDate={element.data.toDate()}
                        qtdAnswers={
                          userProjectsQtdAnswers[element.idProject]
                            ? userProjectsQtdAnswers[element.idProject]
                            : 0
                        }
                      />
                    </div>
                  );
                })}
          </section>
        )}
        {projectSelected === "templates" && (
          <section className="projectsArea">
            {templates.map((element, index) => {
              return (
                <div key={index} onClick={() => handleTemplateToModal(element)}>
                  <SquareProject title={element.name} />
                </div>
              );
            })}
          </section>
        )}
      </div>
    </div>
  );
};

export default Projects;
