import { Bar } from "react-chartjs-2";
//eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
import ChartDataLabels from "chartjs-plugin-datalabels";

const AnswerChart = ({ data, width }) => {
  let dateToSet = [...data].reverse();

  const userData = {
    labels: "",
    datasets: [
      {
        label: "",
        data: dateToSet.map((el) => ({ x: el.date, y: el.qtd })).reverse(),
        backgroundColor: `#609ef1`,
        borderWidth: 1,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        top: 20,
        right: 20,
        bottom: 0,
        left: 20,
      },
    },
    scales: {
      x: {
        barPercentage: 0.5,
        grid: {
          offset: true,
        },
      },
      y: {
        beggingAtZero: true,
      },
    },
    plugins: {
      legend: { display: false },
      tooltip: {
        enabled: false,
      },
      datalabels: {
        align: "end",
        anchor: "end",
        color: "#000",
        offset: 4,
        font: {
          size: 12,
        },
        formatter: (value) => {
          return `${value.y}`;
        },
      },
    },
  };

  return <Bar data={userData} plugins={[ChartDataLabels]} options={options} />;
};

export default AnswerChart;
