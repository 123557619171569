import React, { useContext, useEffect, useState } from "react";
import "./style.scss";
import { Context } from "../../../Context/Context";
import ButtonCheckout from "../ButtonCheckout";

const CardPlan = (props) => {
  const { product } = props;
  const { userInformation } = useContext(Context);
  const [planType, setPlanType] = useState("year");
  const [user, setUser] = useState(userInformation);

  useEffect(() => {
    const getUserInformation = async () => {
      if (userInformation === null) {
        return;
      }
      const inf = await userInformation;
      setUser(inf);
    };
    getUserInformation();
  }, [userInformation]);

  if (!user) {
    return null;
  } else {
    return (
      <div className="cardStyled">
        <div className="card-info">
          <div className="card-header">
            <h2 className="card-title">{product.account}</h2>
            {product.price && (
              <>
                {product.price.year ? (
                  <>
                    <div>
                      <h3 className="card-price">
                        R$ {product.price.year.toFixed(2)} <span>/mês</span>
                      </h3>
                      {product.price.discount && (
                        <p>
                          no plano anual ({product.price.discount} de desconto)
                        </p>
                      )}
                    </div>
                    <span>ou</span>
                    <h4 className="month-price">
                      R$ {product.price.month}0 /mês
                    </h4>
                  </>
                ) : (
                  <h3 className="card-price">R$ {product.price.month}</h3>
                )}
              </>
            )}
          </div>

          <div className="card-body">
            {product.account === "Enterprise" ? (
              <p className="text-enterprise">{product.text}</p>
            ) : (
              <ul className="list-con">
                {product.texts.map((element, index) => {
                  return (
                    <li
                      className={
                        product.textDisabled && index !== 0
                          ? "text-check free"
                          : "text-check"
                      }
                      key={index}
                    >
                      {element}
                    </li>
                  );
                })}
              </ul>
            )}

            {(product.account === "Basic" ||
              product.account === "Premium" ||
              product.account === "Founder" ||
              product.account === "Ultra") &&
              user.subscription !== product.account && (
                <div className="select-flex">
                  <select
                    className="select-plan"
                    value={planType}
                    onChange={(e) => setPlanType(e.target.value)}
                  >
                    <option value="year">
                      Plano anual: R$ {product.price.year.toFixed(2)} /mês
                    </option>
                    <option value="month">
                      Plano mensal: R$ {product.price.month.toFixed(2)} /mês
                    </option>
                  </select>

                  <div
                    style={{ display: planType === "year" ? "block" : "none" }}
                  >
                    <ButtonCheckout
                      product={{
                        plan: product.account,
                        planId: product.plans.year,
                        planType: planType,
                        price: Number(product.price.year) * 12,
                      }}
                    />
                  </div>
                  <div
                    style={{ display: planType === "month" ? "block" : "none" }}
                  >
                    <ButtonCheckout
                      product={{
                        plan: product.account,
                        planId: product.plans.month,
                        planType: planType,
                        price: Number(product.price.month),
                      }}
                    />
                  </div>
                  <span>
                    * Você será direcionado para a página do PayPal e terá a
                    opção de pagar com cartão de crédito
                  </span>
                </div>
              )}

            <div className="button-con">
              {user.subscription === product.account ? (
                <button disabled>Plano Atual</button>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default CardPlan;
