import React, { useState } from "react";
import "./styles.scss";
import DownArrow from "../../Assets/img/down-arrow.svg";
import Duplicate from "../../Assets/img/duplicate.png";
import ModalWarning from "../../Components/Modals/ModalWarning/";
import Pencil from "../../Assets/img/edit.svg";
import Plus from "../../Assets/img/plus.png";
import PlusBlack from "../../Assets/img/plus-black.png";
import Trash from "../../Assets/img/trash_2.png";
import UpArrow from "../../Assets/img/up-arrow.svg";

const ConfigurationQuestionButtons = (props) => {
  const [modalWarning, setModalWarning] = useState(false);

  return (
    <div className="compConfigurationQuestionButtons">
      <ModalWarning modal={modalWarning} setModal={setModalWarning}>
        <div className="compConfigurationQuestionButtonsWarningArea">
          Deseja mesmo remover a pergunta?
          <div>
            <button
              className="buttonCancel"
              onClick={() => setModalWarning(false)}
            >
              Não
            </button>
            <button
              className="buttonWarning"
              onClick={() => {
                props.removeQuestion(props.questionNumber);
                setModalWarning(false);
              }}
            >
              Sim
            </button>
          </div>
        </div>
      </ModalWarning>
      <div className="editArea" onClick={() => setModalWarning(true)}>
        <img alt="botao de lixeira" src={Trash} />
      </div>
      <div
        className="editArea"
        onClick={() => props.handleDuplicateQuestion(props.questionNumber)}
      >
        <img alt="botao de duplicar" src={Duplicate} />
      </div>
      <div
        className="editArea"
        onClick={() => props.moveUpQuestion(props.questionNumber)}
      >
        <img alt="botao de mover para cima" src={UpArrow} />
      </div>
      <div
        className="editArea"
        onClick={() => props.moveDownQuestion(props.questionNumber)}
      >
        <img alt="botao de mover para baixo" src={DownArrow} />
      </div>
      <div
        className="editArea"
        onClick={() => props.handleEdit(props.questionNumber)}
      >
        <img alt="botao de edit" src={Pencil} />
      </div>
      {props.showNew ? (
        <div
          className="editArea buttonNew"
          style={{ padding: 0 }}
          onClick={() => props.addQuestion()}
        >
          <img
            alt="botao de adicionar"
            style={{ height: "33px", width: "33px" }}
            src={Plus}
          />
        </div>
      ) : (
        <div
          className="editArea"
          style={{ padding: 0 }}
          onClick={() => props.addQuestion(props.questionNumber)}
        >
          <img
            alt="botao de adicionar"
            style={{ height: "33px", width: "33px" }}
            src={PlusBlack}
          />
        </div>
      )}
    </div>
  );
};

export default ConfigurationQuestionButtons;
