import React from "react";
import "./style.scss";
import Hazo from "../../../Assets/img/256PX-02.png";

const ModalConfimationProjectDelete = (props) => {
  const toggle = () => {
    props.setModal(!props.modal);
  };

  if (props.modal) {
    return (
      <div className="ModalConfimationProjectDelete">
        <div className="divTitle">
          A opção de excluir um projeto é <br />
          definitiva e você perderá todos os <br />
          dados do relatório!
        </div>
        <div id="triangulo-para-baixo"></div>
        <img src={Hazo} alt="hazo" />
        <div className="hazotext">Hazo</div>
        <div className="question">
          Você realmente deseja <br />
          excluir o projeto?
        </div>
        <div className="buttonArea">
          <div className="buttonNo" onClick={() => toggle()}>
            Não
          </div>
          <div className="buttonExclude" onClick={() => props.handleDelete()}>
            Excluir
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

export default ModalConfimationProjectDelete;
