import React, { useEffect, useState } from "react";
import "./style.scss";
import edit from "../../Assets/img/edit.svg";
import trash from "../../Assets/img/trash_2.png";
import ReportService from "../../Services/ReportService";

const ChartComment = ({ chartNumber, dataQuestions, reportChartsRef }) => {
  const [textArea, setTextArea] = useState("");
  const [allComments, setAllComments] = useState([]);
  const [commentPosition, setCommentPosition] = useState(-1);
  const [close, setClose] = useState(false);

  const handleChange = (e) => {
    setTextArea(e);
  };

  useEffect(() => {
    if (dataQuestions?.length > 0) {
      const comments = dataQuestions.find((c) => c.number === chartNumber);
      if (comments) {
        setAllComments(comments.comment);
      }
    }
  }, [dataQuestions, chartNumber]);

  const handleSave = () => {
    if (textArea === "") {
      return;
    }

    let comments = [...allComments];
    if (commentPosition === -1) {
      comments = [
        ...allComments,
        { comment: textArea, created_at: Date.now() },
      ];
    } else {
      comments.filter((element, i) =>
        i === commentPosition
          ? (comments[commentPosition].comment = textArea)
          : element
      );
      setCommentPosition(-1);
    }

    ReportService.updateComment(reportChartsRef, {
      number: chartNumber,
      comment: comments,
    });
    setTextArea("");
    setAllComments(comments);
  };

  const handleEdit = (e) => {
    const commentPosition = allComments.findIndex((el) => el === e);

    if (commentPosition === -1) {
      setCommentPosition(-1);
    } else {
      setCommentPosition(commentPosition);
    }
    setTextArea(e.comment);
  };

  const handleDelete = (e) => {
    const comments = allComments.filter((el) => el !== e);
    const dataComment = { number: chartNumber, comment: comments };
    ReportService.updateComment(reportChartsRef, dataComment);

    setAllComments(comments);
  };

  const renderComments = () => {
    if (!allComments) {
      return;
    }

    return allComments.map((element, i) => {
      const dateObj = new Date(element.created_at);
      const day = dateObj.getUTCDate();
      const month = dateObj.getUTCMonth() + 1;
      const year = dateObj.getUTCFullYear();
      return (
        <li key={i} className="li-comment">
          <span className="date">{`${day}/${month}/${year
            .toString()
            .substring(2, 4)}`}</span>
          <span className="text">{element.comment}</span>
          <div className="btn-li">
            <button onClick={() => handleEdit(element)}>
              <img src={edit} alt="edit" />
            </button>
            <button onClick={() => handleDelete(element)}>
              <img src={trash} alt="trash" />
            </button>
          </div>
        </li>
      );
    });
  };

  return (
    <>
      <button className="hide-comments" onClick={() => setClose(!close)}>
        {close ? "Mostrar" : "Esconder"} Comentários
      </button>
      {!close && (
        <div className="comment-container">
          <div className="comment-area">
            <h4>
              <strong>Comentários</strong>
            </h4>
            {dataQuestions !== [] && (
              <section className="comment-section">
                <ul className="ul-comment">{renderComments()}</ul>
              </section>
            )}
            <div className="textarea">
              <textarea
                onChange={(e) => handleChange(e.target.value)}
                value={textArea}
              ></textarea>
              <button onClick={() => handleSave()}>Comentar</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ChartComment;
