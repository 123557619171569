import { firebaseAuth } from "../Config/firebase";
import Axios from "axios";
export default class MessagesService {
  static getCurrentUserEmail = () => {
    return firebaseAuth.currentUser.email;
  };

  static getMessagesByUser = async() => {
    let userEmail = await this.getCurrentUserEmail();
    let response = null;
    let messages = [];

    await Axios({
      crossDomain: true,
      dataType: "json",
      method: "get",
      contentType: "application/json",
      url: `${process.env.REACT_APP_BASE_URL_API}v2/messages/?user=${userEmail}`,
    })
    .then((res) => {
      messages = res.data.data;
      response = "success";
    })
    .catch((error) => {
      response = error;
    })

    return { response, messages };
  };

  static saveMessage = async(id, value) => {
    let userEmail = await this.getCurrentUserEmail();
    let response = null;
    let dataToRequest = JSON.stringify({
      user: userEmail,
      save: {
        id,
        value
      }
    });

    await Axios.put(
      `${process.env.REACT_APP_BASE_URL_API}v2/messages/`,
      dataToRequest
    )
      .then(() => {
        response = "success"
      })
      .catch((error) => {
        response = error;
      });

    return response;
  };

  static readAllMessages = async() => {
    let response = null;
    let userEmail = await this.getCurrentUserEmail();
    let dataToRequest = JSON.stringify({
      user: userEmail,
      read: true,
    });

    await Axios.put(
      `${process.env.REACT_APP_BASE_URL_API}v2/messages/`,
      dataToRequest
    )
      .then(() => {
        response = "success";
      })
      .catch((error) => {
        response = error;
      });

    return response;
  };
}
