let presets = [
  {
    question: "NPS",
    min: 0,
    message:
      "Em uma escala de 0 a 10 o quanto você recomendaria [a empresa/produto/serviço] a um amigo ou familiar?",
    max: 10,
    options: [
      { color: "#B72025", next: 0, value: "0" },
      { color: "#D82028", next: 0, value: "1" },
      { color: "#EE5223", next: 0, value: "2" },
      { color: "#F36E23", next: 0, value: "3" },
      { color: "#FAA723", next: 0, value: "4" },
      { color: "#FFC828", next: 0, value: "5" },
      { color: "#ECDC0D", next: 0, value: "6" },
      { color: "#E8E73D", next: 0, value: "7" },
      { color: "#C6D92F", next: 0, value: "8" },
      { color: "#AFCF3D", next: 0, value: "9" },
      { color: "#64B64F", next: 0, value: "10" },
    ],
    type: "numericalScale",
  },
  {
    question: "CES",
    message:
      "Como você avalia a facilidade que teve para [resolver o seu problema/ter acesso ao serviço ou sistema...]?",
    options: [
      { color: "#64B64F", next: 0, value: "Extremamente Fácil" },
      { color: "#C6D92F", next: 0, value: "Fácil" },
      { color: "#FFC828", next: 0, value: "Normal" },
      { color: "#EE5223", next: 0, value: "Difícil" },
      { color: "#B72025", next: 0, value: "Extremamente Difícil" },
    ],
    type: "closeAnswer",
  },
  {
    question: "CSAT",
    message:
      "Qual é a sua Satisfação Geral com [Empresa/Processo/Serviço/Produto]?",
    options: [
      { color: "#64B64F", next: 0, value: "Muito Satisfeito" },
      { color: "#C6D92F", next: 0, value: "Satisfeito" },
      { color: "#FFC828", next: 0, value: "Razoável" },
      { color: "#EE5223", next: 0, value: "Insatisfeito" },
      { color: "#B72025", next: 0, value: "Muito Insatisfeito" },
    ],
    type: "closeAnswer",
  },
  {
    question: "CSATNUM",
    min: 1,
    message:
      "Em uma escala de 1 a 10 o quanto você avalia a sua satisfação geral [com a empresa/produto/serviço]?",
    max: 10,
    options: [
      { color: "#B72025", next: 0, value: "1" },
      { color: "#D82028", next: 0, value: "2" },
      { color: "#EE5223", next: 0, value: "3" },
      { color: "#F36E23", next: 0, value: "4" },
      { color: "#FAA723", next: 0, value: "5" },
      { color: "#FFC828", next: 0, value: "6" },
      { color: "#ECDC0D", next: 0, value: "7" },
      { color: "#E8E73D", next: 0, value: "8" },
      { color: "#AFCF3D", next: 0, value: "9" },
      { color: "#64B64F", next: 0, value: "10" },
    ],
    type: "numericalScale",
  },
  {
    question: "Concordancia",
    message:
      'O quanto você concorda com a frase [Exemplo: "Eu acredito na missão da empresa"]',
    options: [
      { color: "#64B64F", next: 0, value: "Concordo Totalmente" },
      { color: "#C6D92F", next: 0, value: "Concordo" },
      { color: "#FFC828", next: 0, value: "Nem Concordo, Nem Discordo" },
      { color: "#EE5223", next: 0, value: "Discordo" },
      { color: "#B72025", next: 0, value: "Discordo Totalmente" },
    ],
    type: "closeAnswer",
  },
  {
    question: "Importancia",
    message:
      "O quanto é importante para você que [Empresa/Processo/Serviço/Produto] possua [atributo/qualidade/característica]?",
    options: [
      { color: "#64B64F", next: 0, value: "Muito Importante" },
      { color: "#C6D92F", next: 0, value: "Importante" },
      { color: "#FFC828", next: 0, value: "Razoável" },
      { color: "#EE5223", next: 0, value: "Pouco Importante" },
      { color: "#B72025", next: 0, value: "Nada Importante" },
    ],
    type: "closeAnswer",
  },
  {
    question: "IBGE",
    message: "Quantos anos você tem?",
    options: [
      { color: "#606C88", next: 0, value: "15 ou menos" },
      { color: "#606C88", next: 0, value: "16 a 19 anos" },
      { color: "#606C88", next: 0, value: "20 a 24 anos" },
      { color: "#606C88", next: 0, value: "25 a 29 anos" },
      { color: "#606C88", next: 0, value: "30 a 34 anos" },
      { color: "#606C88", next: 0, value: "35 a 39 anos" },
      { color: "#606C88", next: 0, value: "40 a 44 anos" },
      { color: "#606C88", next: 0, value: "45 a 49 anos" },
      { color: "#606C88", next: 0, value: "50 a 54 anos" },
      { color: "#606C88", next: 0, value: "55 a 59 anos" },
      { color: "#606C88", next: 0, value: "60 anos ou mais" },
    ],
    type: "closeAnswer",
  },
  {
    question: "ANS",
    message: "Quantos anos você tem?",
    options: [
      { color: "#606C88", next: 0, value: "17 anos ou menos" },
      { color: "#606C88", next: 0, value: "18 anos" },
      { color: "#606C88", next: 0, value: "19 a 23 anos" },
      { color: "#606C88", next: 0, value: "24 a 28 anos" },
      { color: "#606C88", next: 0, value: "29 a 33 anos" },
      { color: "#606C88", next: 0, value: "34 a 38 anos" },
      { color: "#606C88", next: 0, value: "39 a 43 anos" },
      { color: "#606C88", next: 0, value: "44 a 48 anos" },
      { color: "#606C88", next: 0, value: "49 a 53 anos" },
      { color: "#606C88", next: 0, value: "54 a 58 anos" },
      { color: "#606C88", next: 0, value: "59 anos ou mais" },
    ],
    type: "closeAnswer",
  },
  {
    question: "Conhecimento",
    message:
      "O quanto você conhece [atributo/qualidade/característica] da(o) [Empresa/Processo/Serviço/Produto]?",
    options: [
      { color: "#64B64F", next: 0, value: "Conheço Muito" },
      { color: "#C6D92F", next: 0, value: "Conheço" },
      { color: "#FFC828", next: 0, value: "Razoável" },
      { color: "#EE5223", next: 0, value: "Conheço Pouco" },
      { color: "#B72025", next: 0, value: "Não Conheço Nada" },
    ],
    type: "closeAnswer",
  },
  {
    question: "Afirmativa",
    message: "",
    options: [
      { color: "#64B64F", next: 0, value: "Sim" },
      { color: "#B72025", next: 0, value: "Não" },
      { color: "#FFC828", next: 0, value: "Não sei" },
    ],
    type: "closeAnswer",
  },
];

export default function filterByQuestion(type) {
  if (type) {
    return presets.filter(({ question }) => question === type);
  }
  return null;
}
