import React from "react";
import "./style.scss";
import { Modal, ModalHeader } from "reactstrap";
import BarChart from "../../../Assets/img/bar-chart.svg";
import CES from "../../../Assets/img/ces.svg";
import ColumnChart from "../../../Assets/img/column-chart.svg";
import CSAT from "../../../Assets/img/csat.svg";
import NPS from "../../../Assets/img/nps.svg";
import PieChart from "../../../Assets/img/pie-chart.svg";
import ReportService from "../../../Services/ReportService";

const ModalCharts = (props) => {
  const toggle = () => {
    props.setModal(!props.modal);
  };

  const handleTypeOfChart = async (typeOfAnswer) => {
    let newTypeOfCharts = {
      ...props.typeOfChart,
      [props.chartNumber]: typeOfAnswer,
    };
    props.setTypeOfChart(newTypeOfCharts);
    await ReportService.updateReportCharts(
      props.reportChartsRef,
      newTypeOfCharts
    );
    toggle();
  };

  return (
    <Modal
      className="compModalCharts"
      isOpen={props.modal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">
        <div
          className="boxKindOfAnswer"
          onClick={() => handleTypeOfChart("BarChart")}
        >
          <img src={BarChart} alt="Grafico de barra" />
          <div className="groupTitles">
            <p className="KOAtitle">Grafico de barra</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleTypeOfChart("ColumnChart")}
        >
          <img src={ColumnChart} alt="Grafico de coluna" />
          <div className="groupTitles">
            <p className="KOAtitle">Grafico de coluna</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleTypeOfChart("PieChart")}
        >
          <img src={PieChart} alt="Gráfico de pizza" />
          <div className="groupTitles">
            <p className="KOAtitle">Gráfico de pizza</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          onClick={() => handleTypeOfChart("NPS")}
        >
          <img src={NPS} alt="NPS" />
          <div className="groupTitles">
            <p className="KOAtitle">NPS</p>
            <p className="comingSoonText">Net Promoter Score</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          // onClick={() => handleTypeOfChart("CSAT")}
        >
          <img src={CSAT} alt="CSAT" />
          <div className="groupTitles">
            <p className="KOAtitle">CSAT</p>
            <p className="comingSoonText">Em breve</p>
          </div>
        </div>
        <div
          className="boxKindOfAnswer"
          // onClick={() => handleTypeOfChart("CES")}
        >
          <img src={CES} alt="CES" />
          <div className="groupTitles">
            <p className="KOAtitle">CES</p>
            <p className="comingSoonText">Em breve</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalCharts;
