import React, { useState } from "react";
import "./style.scss";

const ButtonOnOff = (props) => {
  const [offSet, setOffSet] = useState(props.on ? 37 : 0);

  const handleOffSet = () => {
    if (offSet === 0 || offSet === 37) {
      let counter = offSet;
      if (props.on) {
        let moveToLeft = setInterval(() => {
          counter = counter - 1;
          setOffSet(counter);
          if (counter === 0) {
            clearInterval(moveToLeft);
          }
        }, 1);
      } else {
        let moveToRight = setInterval(() => {
          counter = counter + 1;
          setOffSet(counter);
          if (counter === 37) {
            clearInterval(moveToRight);
          }
        }, 1);
      }
      props.handleValue();
    }
  };

  return (
    <div className="compButtonOnOff" onClick={() => handleOffSet()}>
      <div
        className={
          props.on ? "contentArea contentAreaOn" : "contentArea contentAreaOff"
        }
      >
        {props.on ? (
          <div className="buttonText buttonTextOn">On</div>
        ) : (
          <div className="buttonText buttonTextOff">Off</div>
        )}
      </div>
      <div
        className="circleWhite circleWhiteOn"
        style={{ marginLeft: offSet + "px" }}
      ></div>
    </div>
  );
};

export default ButtonOnOff;
