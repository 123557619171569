import React from "react";
import "./style.scss";
import { Modal, ModalHeader } from "reactstrap";

const ModalWarning = (props) => {
  const toggle = () => {
    props.setModal(!props.modal);
  };

  return (
    <Modal
      className="compModalWarning"
      isOpen={props.modal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">{props.children}</div>
    </Modal>
  );
};

export default ModalWarning;
