import React, { useEffect, useState } from "react";
import "./style.scss";
import { Tooltip } from "reactstrap";
import { useHistory } from "react-router-dom";
import AuthService from "../../Services/AuthService";
import Hazo from "../../Assets/img/256PX-01.png";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [tooltipEmail, setTooltipEmail] = useState(false);
  const [emailTooltipMessage, setEmailTooltipMessage] = useState("");
  const [loginError, setLoginError] = useState(false);
  const [loginErrorMessage, setLoginErrorMessage] = useState("");
  const [message, setMessage] = useState(false);
  const [timer, setTimer] = useState(30);
  const history = useHistory();

  useEffect(() => {
    if (!message) return;
    let myInterval = setInterval(() => {
      setTimer(timer - 1);
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  }, [message, timer]);

  const freeze = (el) => {
    setMessage(true);
    setEmailTooltipMessage("Email Enviado!!");
    setTooltipEmail(true);
    el.disabled = true;
    setTimeout(() => {
      el.disabled = false;
      setMessage(false);
      setTooltipEmail(false);
      setTimer(30);
    }, 30000);
  };

  const handleForgetPassword = async (el) => {
    let loginValues = {
      emailLogin: email,
    };

    if (
      !loginValues.emailLogin.match(
        // eslint-disable-next-line
        /(?:[a-z0-9!#$%&'*+\/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+\/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/g
      )
    ) {
      setEmailTooltipMessage("Formato do email inválido");
      return setTooltipEmail(true);
    }

    if (loginValues.emailLogin === "") {
      setEmailTooltipMessage("Campo do email é obrigatório");
      return setTooltipEmail(true);
    }

    await AuthService.resetPassword(loginValues.emailLogin)
      .then(() => {
        freeze(el);
      })
      .catch((err) => {
        setLoginError(true);
        setLoginErrorMessage(err.message);
      });
  };

  const handleEnter = (e) => {
    if (e === "Enter") return handleForgetPassword();
  };

  return (
    <div className="CompForgotPassword">
      <div className="content col-12 col-md-8 col-ls-6 col-xl-5">
        <div className="message">
          <h2 className="title-message">Esqueceu sua senha? Sem problemas!</h2>
          <p className="mb-1 mb-sm-4">
            Enviaremos um link de recuperação de senha para o email informado
            abaixo.
          </p>
          <p className="mb-3 mb-sm-4">
            Por favor, confira também a <strong>caixa de spam</strong> caso não
            encontre o email em sua caixa de entrada.
          </p>
        </div>
        <div id="triangulo-para-baixo"></div>
        <img alt="logo hazo" className="hazoImage" src={Hazo} />
        <div className="hazotext">Hazo</div>
        <div className="title">Trocar senha</div>
        <div className="btn-area">
          <div className="inputArea">
            <Tooltip placement="top" isOpen={tooltipEmail} target="email">
              {emailTooltipMessage}
            </Tooltip>
            <label htmlFor="E-mail">E-mail</label>
            <input
              id="email"
              value={email}
              onChange={(event) => {
                setEmail(event.target.value);
                setTooltipEmail(false);
              }}
              onKeyDown={(e) => handleEnter(e.key)}
              type="text"
            />
          </div>
          <button
            className="buttonSignin buttonHover"
            onClick={(e) => handleForgetPassword(e.target)}
          >
            {message ? "Solicitar Novamente " : "Solicitar Alteração"}
            {message && (timer < 10 ? `(00:0${timer})` : `(00:${timer})`)}
          </button>
          {loginError ? (
            <div className="errorMessage">{loginErrorMessage}</div>
          ) : null}
          <div
            className="buttonRedirect buttonRedirectLarge"
            onClick={() => history.push("signup")}
          >
            Criar uma nova conta
          </div>
          <div
            className="buttonRedirect buttonRedirectLarge"
            onClick={() => history.push("/projects")}
          >
            <u>Página Inicial</u>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
