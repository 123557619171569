import React, { useCallback, useState } from "react";
import "./style.scss";
import Cropper from "react-easy-crop";

const ModalCropImage = (props) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(props.image, croppedAreaPixels);
      props.setImage(croppedImage);
      toggle();
    } catch (e) {}
    // eslint-disable-next-line
  }, [croppedAreaPixels]);

  const getCroppedImg = async (imageSrc, pixelCrop) => {
    let image = await createImage(imageSrc);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    canvas.width = image.width;
    canvas.height = image.height;
    ctx.drawImage(image, 0, 0);

    const data = ctx.getImageData(
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height
    );

    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    ctx.putImageData(data, 0, 0);

    let blobToCreateImage = await new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(URL.createObjectURL(file));
      }, "image/png");
    });
    image = await createImage(blobToCreateImage);

    if (props.whichImageToSet === "avatar") {
      canvas.width = image.width * (150 / image.width);
      canvas.height = image.height * (150 / image.height);
      ctx.drawImage(image, 0, 0, 150, 150);
    } else {
      canvas.width = image.width * (150 / image.width);
      canvas.height = image.height * (84.375 / image.height);
      ctx.drawImage(image, 0, 0, 150, 84.375);
    }

    return await new Promise((resolve, reject) => {
      canvas.toBlob((file) => {
        resolve(file);
      }, "image/png");
    });
  };

  const createImage = (url) =>
    new Promise((resolve, reject) => {
      const image = new Image();
      image.addEventListener("load", () => resolve(image));
      image.addEventListener("error", (error) => reject(error));
      image.setAttribute("crossOrigin", "anonymous");
      image.src = url;
    });

  const toggle = () => {
    props.setModal(!props.modal);
  };

  if (!props.modal || !props.whichImageToSet) {
    return null;
  } else {
    return (
      <div className="compModalCropImage">
        <div className="controls">
          <input
            type="range"
            value={zoom}
            min={1}
            max={3}
            step={0.1}
            aria-labelledby="Zoom"
            onChange={(e) => {
              setZoom(e.target.value);
            }}
            className="zoom-range"
          />
          <div className="buttonArea">
            <button className="buttonCancel" onClick={() => toggle()}>
              Cancelar
            </button>
            <button className="buttonSave" onClick={() => showCroppedImage()}>
              Salvar
            </button>{" "}
          </div>
        </div>
        <div className="App">
          <div className="crop-container">
            <Cropper
              aspect={1}
              crop={crop}
              image={props.image}
              onCropChange={setCrop}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              zoom={zoom}
            />
          </div>
        </div>
      </div>
    );
  }
};

export default ModalCropImage;
