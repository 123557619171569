import React, { useContext, useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import { Context } from "../../Context/Context";
import { FaArrowLeft } from "react-icons/fa";
import { Tooltip } from "reactstrap";
import AuthService from "../../Services/AuthService";
import Hazo from "../../Assets/img/256PX-01.png";

const Account = () => {
  const { resetAllInformations, handleTotalInteractions, userInformation } =
    useContext(Context);
  const history = useHistory();
  const [tooltipDatabase, setTooltipOpen] = useState(false);

  if (userInformation) {
    return (
      <section className="compAccount">
        <div className="content">
          <div className="icon">
            <button className="btn-icon">
              <FaArrowLeft onClick={() => history.goBack()} />
            </button>
          </div>
          <div className="planArea">
            <p className="planTitle">
              Plano Atual: {userInformation.subscription.split(" ")[0]}
            </p>
            <p>Total de Interações: {handleTotalInteractions()}</p>
            <p className="planEmail">Usuário: {userInformation.email}</p>
          </div>
          <div className="title">Oi {userInformation.name}, tudo bem?</div>
          <div id="triangulo-para-baixo"></div>
          <img alt="hazo logo" className="hazoImage" src={Hazo} />
          <div className="hazotext">Hazo</div>
          <div className="question">O que você deseja fazer?</div>
          <button
            className="button"
            onClick={() => history.push("/changePassword")}
          >
            Alterar Senha
          </button>
          <Tooltip
            placement="top"
            isOpen={tooltipDatabase}
            target={"tooltipDatabase"}
          >
            Está funcionalidade não faz parte do seu plano.
          </Tooltip>
          <button
            className="button"
            id="tooltipDatabase"
            onClick={() => {
              if (
                userInformation.subscription !== "Idss" &&
                userInformation.subscription !== "IdssUltra"
              ) {
                history.push("/managerPlan");
              } else {
                setTooltipOpen(true);
                setTimeout(() => {
                  setTooltipOpen(false);
                }, 5000);
              }
            }}
          >
            Gerenciar Plano
          </button>
          <button
            className="button"
            onClick={() => history.push("/changeslog")}
          >
            Atualizações
          </button>
          <button
            className="onlyUnderline"
            onClick={() => {
              resetAllInformations();
              AuthService.signOut();
            }}
          >
            Encerrar sessão
          </button>
        </div>
      </section>
    );
  } else {
    return null;
  }
};

export default Account;
