import React, { useContext, useState } from "react";
import "./style.scss";
import PDF from "../../../Assets/img/pdf.png";
import XLSX from "../../../Assets/img/xlsx.png";
import { Modal, ModalHeader } from "reactstrap";
import { Context } from "../../../Context/Context";
import { Tooltip } from "reactstrap";

const ModalExport = (props) => {
  const { userInformation } = useContext(Context);
  const [tooltipDatabase, setTooltipDatabase] = useState(false);

  const toggle = () => {
    props.setModal(!props.modal);
    setTooltipDatabase(false);
  };

  const handleExportDatabase = () => {
    if (
      userInformation.subscription !== "Idss" &&
      userInformation.subscription !== "IdssUltra"
    ) {
      props.createXlsxDatabase();
    } else {
      setTooltipDatabase(true);
      setTimeout(() => {
        setTooltipDatabase(false);
      }, 5000);
    }
  };

  return (
    <Modal
      className="compModalExport"
      isOpen={props.modal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">
        <div className="boxPresets" onClick={() => props.createPdf()}>
          <img alt="PDF" src={PDF} />
          <div className="groupTitles">
            <p className="pTitle">Exportar PDF</p>
          </div>
        </div>
        <div className="boxPresets" onClick={() => props.createXlsxTable()}>
          <img alt="XLSX" src={XLSX} />
          <div className="groupTitles">
            <p className="pTitle">Exportar tabelas XLSX</p>
          </div>
        </div>
        <Tooltip
          placement="top"
          isOpen={tooltipDatabase}
          target={"tooltipDatabase"}
        >
          Está funcionalidade não faz parte do seu plano.
        </Tooltip>
        <div
          id="tooltipDatabase"
          className="boxPresets"
          onClick={() => handleExportDatabase()}
        >
          <img alt="XLSX" src={XLSX} />
          <div className="groupTitles">
            <p className="pTitle">Exportar base XLSX</p>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default ModalExport;
