import React, { useEffect, useState } from "react";
import "./style.scss";
import ConfigurationQuestionButtons from "../../ConfigurationQuestionButtons";
import EditQuestionMenu from "../../EditQuestionMenu";
import ModalAnswers from "../../Modals/ModalAnswers";
import ModalPresets from "../../Modals/ModalPresets";
import Polygon from "../../../Assets/img/polygon_down.svg";
import { Tooltip } from "reactstrap";

const DefaultQuestion = (props) => {
  const [modalAnswersOpen, setModalAnswersOpen] = useState(false);
  const [modalPresetsOpen, setModalPresetsOpen] = useState(false);
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [tooltipMessage, seTooltipMessage] = useState("");

  const handleInputToSave = () => {
    props.handleSave(props.questionNumber);
  };

  const handleInputToSaveNew = () => {
    props.handleSaveAndNew(props.questionNumber);
  };

  const handleToCancel = () => {
    props.handleCancel(props.questionNumber);
  };

  useEffect(() => {
    const validError = () => {
      if (props.questionValues.questions[props.questionNumber].message === "") {
        seTooltipMessage("Digite uma pergunta");
        setTooltipOpen(true);
      } else if (
        props.questionValues.questions[props.questionNumber].type === ""
      ) {
        seTooltipMessage("Escolha um tipo de resposta");
        setTooltipOpen(true);
      }
    };

    if (props.questionValues.questions[props.questionNumber].error) {
      validError();
    } else {
      setTooltipOpen(false);
    }
    // eslint-disable-next-line
  }, [props.questionValues]);

  if (!props.questionValues.questions[props.questionNumber].edit) {
    return (
      <div className="compDefaultQuestion boxNoEdit">
        <img
          alt="robot"
          className="robotIcon"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
          style={{
            visibility:
              props.lastQuestion === props.questionNumber + 1
                ? "visible"
                : "hidden",
          }}
        />
        <div className="questionArea">
          <div className="title">Pergunta {props.questionNumber + 1}</div>
          <div className="inputTitle">
            <p style={{ whiteSpace: "pre-line" }}>
              {props.questionValues.questions[props.questionNumber].message}
            </p>
          </div>
          <ConfigurationQuestionButtons
            addQuestion={props.addQuestion}
            handleDuplicateQuestion={props.handleDuplicateQuestion}
            handleEdit={props.handleEdit}
            moveUpQuestion={props.moveUpQuestion}
            moveDownQuestion={props.moveDownQuestion}
            questionNumber={props.questionNumber}
            removeQuestion={props.removeQuestion}
            showNew={
              props.questionValues.questions.length - 1 === props.questionNumber
                ? true
                : false
            }
          />
        </div>
      </div>
    );
  } else {
    return (
      <div className="compDefaultQuestion boxEdit">
        <img
          alt="robot"
          className="robotIcon"
          src={`${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`}
          style={{
            visibility:
              props.lastQuestion === props.questionNumber + 1
                ? "visible"
                : "hidden",
          }}
        />
        <div className="questionArea">
          <ModalAnswers
            questionNumber={props.questionNumber}
            questionValues={props.questionValues}
            setQuestionValues={props.setQuestionValues}
            modal={modalAnswersOpen}
            setModal={setModalAnswersOpen}
          />
          <ModalPresets
            questionNumber={props.questionNumber}
            questionValues={props.questionValues}
            setQuestionValues={props.setQuestionValues}
            modal={modalPresetsOpen}
            setModal={setModalPresetsOpen}
          />
          <div className="title">Pergunta {props.questionNumber + 1}</div>
          <div
            id={"Pergunta" + props.questionNumber}
            className={
              !tooltipOpen ? "inputTitle" : "inputTitle inputTitleError"
            }
          >
            <Tooltip
              innerClassName="redBackground"
              className="redBackground"
              placement="top"
              popperClassName="tooltipZero"
              isOpen={tooltipOpen}
              target={"Pergunta" + props.questionNumber}
            >
              {tooltipMessage}
            </Tooltip>
            <button
              className="buttonPresets"
              onClick={() => setModalPresetsOpen(true)}
            >
              Presets
            </button>
            <textarea
              rows="3"
              className="buttonPresetInputPadding form-control"
              value={
                props.questionValues.questions[props.questionNumber].message
              }
              placeholder="Digite aqui a sua pergunta"
              onChange={(event) => {
                props.handleMessage(props.questionNumber, event.target.value);
                props.handleKeyDown(event);
              }}
            />
            <div id={"PerguntaEditQuestionMenu" + props.questionNumber}>
              <button
                className="buttonSelectAnswer"
                onClick={() => {
                  setModalAnswersOpen(true);
                }}
              >
                Selecione o tipo de resposta
                <img alt="poligono" className="polygon" src={Polygon} />
              </button>
            </div>
          </div>
          <EditQuestionMenu
            handleCancel={handleToCancel}
            handleSave={handleInputToSave}
            handleSaveAndNew={handleInputToSaveNew}
            questionNumber={props.questionNumber}
            showSavePlusNew={
              props.questionValues.questions.length - 1 === props.questionNumber
                ? true
                : false
            }
          />
        </div>
      </div>
    );
  }
};

export default DefaultQuestion;
