import React, { useState } from "react";
import "./style.scss";
import Hazo from "../../Assets/img/lettering-hazo.svg";
import ModalCustomLayout from "../../Components/Modals/ModalCustomLayout/";
import { BsThreeDotsVertical, BsX } from "react-icons/bs";

const ChatbotHeader = (props) => {
  const [modal, setModal] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const chatbotTextColor = props.questionValues.chatbot_header_textColor;
  const textColor = chatbotTextColor !== undefined ? chatbotTextColor : "white";

  const toggle = async () => {
    setIsOpen(false);
  };

  return (
    <header
      className="chatbotHeader"
      style={
        props.page === "share"
          ? null
          : {
              backgroundColor: props.questionValues.chatbot_header_color,
            }
      }
    >
      <ModalCustomLayout
        modal={modal}
        questionValues={props.questionValues}
        setModal={setModal}
        setQuestionValues={props.setQuestionValues}
      />
      <div
        className="containerWidth"
        style={{ backgroundColor: props.questionValues.chatbot_header_color }}
      >
        <section className="containerHeaderMain">
          <div className="imageAndName">
            <div className="logoArea">
              <img
                alt="business"
                className="logo"
                src={
                  props.page === "share"
                    ? Hazo
                    : `${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.business_image_name}`
                }
              />
            </div>
            <div className="titleBody">
              <h1
                className="titleHeaderMain"
                style={props.page === "share" ? null : { color: textColor }}
              >
                <strong>{props.questionValues.robot_name}</strong>
              </h1>
              <span
                className="titleHeaderMainSubtitle"
                style={props.page === "share" ? null : { color: textColor }}
              >
                Online
              </span>
            </div>
          </div>
          <div className="divDropDown smallScreen">
            {props.page === "botmaker" ? (
              <>
                <button
                  className="buttonDropDown"
                  onClick={() => setIsOpen(!isOpen)}
                >
                  {!isOpen ? (
                    <BsThreeDotsVertical />
                  ) : (
                    <BsX style={{ fontSize: "2rem" }} />
                  )}
                </button>

                {isOpen && (
                  <div className="dropDown">
                    <div className="small-pointer"></div>
                    {props.page === "botmaker" ? (
                      <>
                        <div
                          className="buttonLayout"
                          onClick={async () => {
                            await toggle();
                            props.handleTestChatbot();
                          }}
                        >
                          Testar
                        </div>
                        <div
                          className="buttonLayout"
                          onClick={async () => {
                            await toggle();
                            setModal(true);
                          }}
                        >
                          Customizar
                        </div>
                        <div
                          className="buttonLayout"
                          onClick={async () => {
                            await toggle();
                            props.setLogic(!props.logic);
                          }}
                        >
                          Mostrar/Esconder Lógica
                        </div>
                        <div
                          className="buttonLayout"
                          onClick={async () => {
                            await toggle();
                            props.generatePdf();
                          }}
                        >
                          Exportar PDF
                        </div>
                      </>
                    ) : null}
                  </div>
                )}
              </>
            ) : null}
          </div>
        </section>
      </div>
    </header>
  );
};

export default ChatbotHeader;
