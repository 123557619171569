import React, { useContext, useRef, useState } from "react";
import "./style.scss";
import { Modal, ModalBody } from "reactstrap";
import { Context } from "../../../Context/Context";
import { useParams } from "react-router-dom";
import GradientIcon from "../../../Assets/img/gradient.png";
import JsonDictionaryService from "../../../Services/JsonDictionaryService";
import LogoService from "../../../Services/LogoService";
import ModalCropImage from "../ModalCropImage";

const ModalCustomLayout = (props) => {
  const { chatbot, createDictionary } = useContext(Context);
  const { projectId } = useParams();
  const [tooltipModalHeaderEditOpen, setTooltipModalHeaderEditOpen] =
    useState(false);
  const [avatarBlob, setAvatarBlob] = useState(null);
  const [businessBlob, setBusinessBlob] = useState(null);
  const [modalValues, setModalValues] = useState({
    robot_name: chatbot.robot_name,
    chatbot_header_color: chatbot.chatbot_header_color,
    chatbot_header_textColor: chatbot.chatbot_header_textColor,
    robot_image_name: chatbot.robot_image_name,
    business_image_name: chatbot.business_image_name,
    disclaimer_final: chatbot.disclaimer_final
      ? chatbot.disclaimer_final
      : "Obrigado! Crie também o seu hazo.app!",
  });
  const openBrowserRobotImage = useRef(null);
  const [fileToUpload, setFileToUpload] = useState({
    business: null,
    robot: null,
  });
  const [modalCropImage, setModalCropImage] = useState(false);
  const [blobToCrop, setBlobToCrop] = useState(null);
  const [fileName, setFileName] = useState("");
  const [whichImageToSet, setWhichImageToSet] = useState("");
  const [forceUpdate, setForceUpdade] = useState(0);
  const [messageErrorBusiness, setMessageErrorBusiness] = useState("");
  const [messageErrorAvatar, setMessageErrorAvatar] = useState("");

  const handleBusinessImage = (e) => {
    if (e.target.files[0].size > 3000000) {
      setMessageErrorBusiness("O arquivo é muito grande");
      return;
    } else {
      setMessageErrorBusiness("");
    }

    let typeOfImage = e.target.files[0].name.split(".").pop();
    let base = "";
    switch (typeOfImage) {
      case "svg":
        base = "image/svg+xml";
        break;
      case "png":
        base = "image/png";
        break;
      case "jpg":
      case "jpeg":
        base = "image/jpg";
        break;
      default:
        setMessageErrorBusiness("Formato do arquivo inválido");
        return;
    }
    setMessageErrorBusiness("");

    let fileName = "business";
    let blob = e.target.files[0].slice(0, e.target.files[0].size, base);
    let newFile = new File([blob], `${projectId}-${fileName}.${typeOfImage}`, {
      type: base,
    });

    handleLogoChange(newFile);
    setFileName(newFile.name);
    setWhichImageToSet(fileName);
    setBusinessBlob(URL.createObjectURL(newFile));
  };

  const toggleTooltip = () =>
    setTooltipModalHeaderEditOpen(!tooltipModalHeaderEditOpen);

  const toggle = () => {
    props.setModal(!props.modal);
    if (tooltipModalHeaderEditOpen) {
      toggleTooltip();
    }
  };

  const handleLogoChange = (logo) => {
    setModalValues({
      ...modalValues,
      business_image_name: `${logo.name}?v=${Math.round(
        Math.random() * (100000 - 0) + 0
      )}`,
    });
    setFileToUpload({
      ...fileToUpload,
      business: logo,
    });
  };

  const handleAvatarName = (event) => {
    setModalValues({
      ...modalValues,
      robot_name: event.target.value,
    });
  };

  const handleHeaderColor = (color) => {
    const r = parseInt(color.substr(1, 2), 16);
    const g = parseInt(color.substr(3, 2), 16);
    const b = parseInt(color.substr(5, 2), 16);

    const yiq = (r * 299 + g * 587 + b * 114) / 1000;
    const textColor = yiq >= 128 ? "black" : "white";

    setModalValues({
      ...modalValues,
      chatbot_header_color: color,
      chatbot_header_textColor: textColor,
    });
  };

  const handleImageToCrop = (evt, imageAvatarOrBusiness) => {
    if (evt.target.files[0].size > 3000000) {
      setMessageErrorAvatar("O arquivo é muito grande");
      return;
    } else {
      setMessageErrorAvatar("");
    }

    let typeOfImage = evt.target.files[0].name.split(".").pop();
    let base = "";
    switch (typeOfImage) {
      case "svg":
        base = "image/svg+xml";
        break;
      case "png":
        base = "image/png";
        break;
      case "jpg":
      case "jpeg":
        base = "image/jpg";
        break;
      default:
        setMessageErrorAvatar("Formato do arquivo inválido");
        return;
    }
    setMessageErrorAvatar("");

    let fileName = "";
    if (imageAvatarOrBusiness === "avatar") {
      fileName = "robot";
    }
    if (imageAvatarOrBusiness === "business") {
      fileName = "business";
    }

    let blob = evt.target.files[0].slice(0, evt.target.files[0].size, base);
    let newFile = new File([blob], `${projectId}-${fileName}.${typeOfImage}`, {
      type: base,
    });
    setFileName(newFile.name);
    setWhichImageToSet(imageAvatarOrBusiness);
    setBlobToCrop(URL.createObjectURL(newFile));
    setModalCropImage(true);
  };

  const handleSetImage = (blob) => {
    if (whichImageToSet === "avatar") {
      let newFile = new File([blob], `${projectId}-avatar.png`, {
        type: "image/png",
      });
      setModalValues({
        ...modalValues,
        robot_image_name: `${newFile.name}?v=${Math.round(
          Math.random() * (100000 - 0) + 0
        )}`,
      });
      setAvatarBlob(URL.createObjectURL(blob));
      setFileToUpload({
        ...fileToUpload,
        robot: newFile,
      });
    }
  };

  const handleSave = async () => {
    if (fileToUpload.business !== null) {
      await LogoService.logoSave(fileToUpload.business);
    }

    if (fileToUpload.robot !== null) {
      openBrowserRobotImage.current.value = "";
      await LogoService.logoSave(fileToUpload.robot);
    }

    let chatbotObject = {
      ...props.questionValues,
      robot_name: modalValues.robot_name,
      chatbot_header_color: modalValues.chatbot_header_color,
      chatbot_header_textColor: modalValues.chatbot_header_textColor,
      robot_image_name: modalValues.robot_image_name,
      business_image_name: modalValues.business_image_name,
      disclaimer_final: modalValues.disclaimer_final,
    };
    props.setQuestionValues(chatbotObject);

    await createDictionary(chatbotObject, projectId);
    await JsonDictionaryService.jsonDictionarySaveFirebase(
      projectId,
      JSON.stringify(chatbotObject)
    );
    setForceUpdade(Math.round(Math.random() * (100000 - 0) + 0));
    toggle();
  };

  const handleCancel = () => {
    setTooltipModalHeaderEditOpen(false);
    setAvatarBlob(null);
    setBusinessBlob(null);
    setModalValues({
      robot_name: chatbot.robot_name,
      chatbot_header_color: chatbot.chatbot_header_color,
      chatbot_header_textColor: chatbot.chatbot_header_textColor,
      robot_image_name: chatbot.robot_image_name,
      business_image_name: chatbot.business_image_name,
      disclaimer_final: chatbot.disclaimer_final
        ? chatbot.disclaimer_final
        : "Obrigado! Crie também o seu hazo.app!",
    });
    toggle();
  };

  const handleDiclaimerFinal = (event) => {
    setModalValues({
      ...modalValues,
      disclaimer_final: event.target.value,
    });
  };

  return (
    <Modal className="testemodal" isOpen={props.modal} centered={true}>
      <div style={{ display: "none" }}>{forceUpdate}</div>
      <ModalCropImage
        modal={modalCropImage}
        setModal={setModalCropImage}
        image={blobToCrop}
        setImage={handleSetImage}
        fileName={fileName}
        whichImageToSet={whichImageToSet}
      />
      <ModalBody className="CompModalCustomLayout">
        <div className="imageArea">
          <label className="labelLogoImg">
            <img
              alt="logo da empresa"
              className="robotAndHazoImage"
              id="tooltipLogo"
              src={
                businessBlob !== null
                  ? businessBlob
                  : `${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.business_image_name}`
              }
            />
          </label>
          <div>
            <label className="imageChange">
              Enviar Logo
              <input
                className="inputHide"
                htmlFor="Altere a logo da empresa"
                type="file"
                accept=".jpg, .jpeg, .png, .svg"
                onChange={handleBusinessImage}
              />
            </label>
          </div>
          {messageErrorBusiness && (
            <span className="error">{messageErrorBusiness}</span>
          )}
          <span className="orientation">
            * Formatos aceitos: PNG, SVG, JPEG, JPG. <br />
            Tamanho maximo: 3 mb
          </span>
        </div>
        <div className="imageArea">
          <div htmlFor="Cor da barra superior">
            <p htmlFor="Cor da barra superior">
              Cor do cabeçalho da Interação:
            </p>
            <div className="areaColorHeader">
              <div className="editColorHeader" id="tooltipColor">
                <input
                  className="inputColorChange"
                  id={"inputColorHeader"}
                  value={modalValues.chatbot_header_color}
                  onChange={(e) => {
                    handleHeaderColor(e.target.value);
                  }}
                  type="color"
                />
                <img src={GradientIcon} alt="icon gradiente color" />
              </div>
            </div>
          </div>
        </div>
        <div className="imageArea">
          <p htmlFor="Nome do Avatar">Nome do Avatar:</p>
          <input
            className="inputText"
            id="tooltipAvatarName"
            placeholder="Digite aqui o nome da sua robô"
            value={modalValues.robot_name}
            onChange={handleAvatarName}
          />
        </div>
        <div className="imageArea">
          <label className="labelLogoImg">
            <img
              alt="imagem da robo"
              className="robotAndHazoImage radiusImg"
              id="tooltipAvatar"
              src={
                avatarBlob === null
                  ? `${process.env.REACT_APP_BASE_URL}dist/img/${props.questionValues.robot_image_name}`
                  : avatarBlob
              }
            />
          </label>
          <div>
            <label className="imageChange">
              Enviar Avatar
              <input
                accept=".jpg, .jpeg, .png, .svg"
                className="inputHide"
                type="file"
                ref={openBrowserRobotImage}
                onChange={(e) => handleImageToCrop(e, "avatar")}
              />
            </label>
          </div>
          {messageErrorAvatar && (
            <span className="error">{messageErrorAvatar}</span>
          )}
          <span className="orientation">
            * Formatos aceitos: PNG, SVG, JPEG, JPG. <br />
            Tamanho maximo: 3 mb
          </span>
        </div>
        <div className="imageArea">
          <p htmlFor="Despedida">Despedida Personalizada:</p>
          <input
            className="inputText"
            id="tooltipGoodbye"
            placeholder="Digite aqui a sua despedida"
            value={modalValues.disclaimer_final}
            onChange={(e) => handleDiclaimerFinal(e)}
          />
        </div>
        <div className="buttonArea">
          <button className="buttonCancel" onClick={() => handleCancel()}>
            Cancelar
          </button>
          <button className="buttonSave" onClick={handleSave}>
            Salvar
          </button>{" "}
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ModalCustomLayout;
