import React, { useState } from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import BlueHome from "../../Assets/img/blue_home.png";
import BlueInteraction from "../../Assets/img/blue_interaction.png";
import BlueReport from "../../Assets/img/blue_report.png";
import BlueShare from "../../Assets/img/blue_share.png";
import GrayHome from "../../Assets/img/gray_home.png";
import GrayInteraction from "../../Assets/img/gray_interaction.png";
import GrayReport from "../../Assets/img/gray_report.png";
import GrayShare from "../../Assets/img/gray_share.png";
import Hazo from "../../Assets/img/256PX-02.png";
import ModalWarning from "../../Components/Modals/ModalWarning/";

const NavigationHeader = (props) => {
  const history = useHistory();
  const [modalWarning, setModalWarning] = useState(false);

  return (
    <nav className="compNavigationHeader">
      <ModalWarning modal={modalWarning} setModal={setModalWarning}>
        <div className="warningHazo">
          <img alt="hazo" src={Hazo} />
          Existem perguntas no modo de edição
        </div>
      </ModalWarning>
      {props.page === "home" ? (
        <img
          alt="home azul"
          className="iconNavigation"
          src={BlueHome}
          onClick={() => history.push("/projects")}
        />
      ) : (
        <img
          alt="home cinza"
          className="iconNavigation"
          src={GrayHome}
          onClick={() => history.push("/projects")}
        />
      )}
      <div id="triangle-to-right"></div>
      {props.page === "botmaker" ? (
        <img
          alt="interacao azul"
          className="iconNavigation"
          src={BlueInteraction}
          onClick={() => history.push(`/botmaker/${props.projectId}`)}
        />
      ) : (
        <img
          alt="interacao cinza"
          className="iconNavigation"
          src={GrayInteraction}
          onClick={() => history.push(`/botmaker/${props.projectId}`)}
        />
      )}
      <div id="triangle-to-right"></div>
      {props.page === "share" ? (
        <img
          alt="compartilhamento azul"
          className="iconNavigation"
          onClick={() => history.push(`/share/${props.projectId}`)}
          src={BlueShare}
        />
      ) : (
        <img
          alt="compartilhamento cinza"
          className="iconNavigation"
          onClick={() => {
            history.location.pathname.includes("botmaker")
              ? props.validQuestions()
                ? setModalWarning(true)
                : props.handleShare()
              : history.push(`/share/${props.projectId}`);
          }}
          src={GrayShare}
        />
      )}
      <div id="triangle-to-right"></div>
      {props.page === "report" ? (
        <img
          alt="relatorio azul"
          className="iconNavigation"
          src={BlueReport}
          onClick={() => history.push(`/report/${props.projectId}`)}
        />
      ) : (
        <img
          alt="relatorio cinza"
          className="iconNavigation"
          src={GrayReport}
          onClick={() => history.push(`/report/${props.projectId}`)}
        />
      )}
    </nav>
  );
};

export default NavigationHeader;
