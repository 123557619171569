import React from "react";
import "./style.scss";
import { useHistory } from "react-router-dom";
import Hazo from "../../Assets/img/256PX-01.png";
import Logo121Labs from "../../Assets/img/LOGO121LABS-BRANCO_PNG.png";

const Welcome = () => {
  const history = useHistory();

  return (
    <div className="CompWelcome">
      <div className="content">
        <div className="textArea">
          Olá! Eu sou o <span className="fontBold">Hazo</span>, o{" "}
          <span className="fontBold">Camaleão</span>!<br />
          Eu me transformo no Avatar que você quiser!
          <br />
          Eu te ajudo a criar interações <br />
          ou pesquisas rápidas
          <br />
          com um{" "}
          <span className="fontBold">
            <u>Chatbot customizado do seu jeito</u>
          </span>
          !<br />
          <span className="fontBold">Vamos lá</span>!
        </div>
        <div id="triangulo-para-baixo"></div>
        <img alt="hazo logo" className="hazoImage" src={Hazo} />
        <div className="hazotext">Hazo</div>
        <div
          className="buttonSignup buttonHover"
          onClick={() => history.push("/signup")}
        >
          Criar minha conta Grátis
        </div>
        <div
          className="buttonSignin buttonHover"
          onClick={() => history.push("/signin")}
        >
          Já tenho uma conta (Login)
        </div>
      </div>
      <div className="footer">
        <div className="footerFont">Powered by</div>
        <img alt="Logo 121 Labs" className="footerImage" src={Logo121Labs} />
      </div>
    </div>
  );
};

export default Welcome;
