import { Doughnut } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";
import "./style.scss";

const NpsChart = ({ data }) => {
  let allNegatives = [];
  let allNeutrals = [];
  let allPositives = [];

  data.forEach((element) => {
    if (element.option > 6 && element.option < 9) {
      allNeutrals.push(Number(element.value));
    } else if (element.option > 8) {
      allPositives.push(Number(element.value));
    } else {
      allNegatives.push(Number(element.value));
    }
  });

  const arrayReducer = (arr) => {
    return arr.reduce(
      (previousValue, currentValue) => previousValue + currentValue,
      0
    );
  };

  const negative = arrayReducer(allNegatives);
  const neutral = arrayReducer(allNeutrals);
  const positive = arrayReducer(allPositives);
  const total = negative + positive + neutral;

  const totalNegative = Math.round((negative / total) * 100);
  const totalNeutral = Math.round((neutral / total) * 100);
  const totalPositive = Math.round((positive / total) * 100);
  const npsScore = totalPositive - totalNegative;

  const userData = {
    labels: null,
    datasets: [
      {
        label: "",
        data: [100, 50, 25, 25],
        backgroundColor: [
          "rgba(255, 46, 44, 1)",
          "rgba(255, 206, 86, 1)",
          "rgb(136, 196, 62)",
          "rgba(25, 162, 98, 1)",
        ],
        needleValue: npsScore + 100,
        borderWidth: 1,
        cutout: "85%",
        circumference: 190,
        rotation: 265,
      },
    ],
  };

  const gaugeNeedle = {
    id: "gaugeNeedle",
    afterDatasetDraw(chart) {
      const {
        ctx,
        data,
        chartArea: { width, height },
      } = chart;

      ctx.save();
      const needleValue = data.datasets[0].needleValue;
      const dataTotal = data.datasets[0].data.reduce((a, b) => a + b, 0);
      const angle = Math.PI + (1 / dataTotal) * needleValue * Math.PI;

      const getColor = () => {
        if (needleValue > 175) {
          return "rgba(25, 162, 98, 1)";
        } else if (needleValue > 100 && needleValue < 151) {
          return "rgba(255, 206, 86, 1)";
        } else if (needleValue > 150 && needleValue < 176) {
          return "rgb(136, 196, 62)";
        } else {
          return "rgba(255, 46, 44, 1)";
        }
      };

      const cx = width / 2;
      const cy = chart._metasets[0].data[0].y;

      //needle
      ctx.translate(cx, cy);
      ctx.rotate(angle);
      ctx.beginPath();
      ctx.moveTo(0, -3);
      ctx.lineTo(height / 2, 0);
      ctx.lineTo(0, 3);
      ctx.fillStyle = "#4445";
      ctx.fill();
      ctx.restore();

      ctx.beginPath();
      ctx.arc(cx, cy, 5, 0, 10);
      ctx.fillStyle = "#444";
      ctx.fill();
      ctx.restore();

      ctx.font = "30px Helvetica";
      ctx.fillStyle = getColor();
      ctx.fillText(npsScore, cx, cy - 40);
      ctx.textAlign = "center";
      ctx.restore();

      ctx.font = "14px Helvetica";
      ctx.fillStyle = getColor();
      ctx.fillText("NPS", cx, cy - 20);
      ctx.textAlign = "center";
      ctx.restore();

      ctx.font = "14px Helvetica";
      ctx.fillStyle = "#b70000";
      ctx.fillText("-100", 40, cy);
      ctx.restore();

      ctx.font = "14px Helvetica";
      ctx.fillStyle = "#0c640b";
      ctx.fillText("100", width - 40, cy);
      ctx.restore();

      ctx.font = "16px Helvetica";
      ctx.fillStyle = "#ffce56";
      ctx.fillText("0", cx, cy / 2);
      ctx.textAlign = "center";
      ctx.restore();
    },
  };

  return (
    <div className="chartCard">
      <section className="chartBox">
        <Doughnut
          data={userData}
          plugins={[gaugeNeedle]}
          redraw={true}
          options={{
            plugins: {
              legend: { display: false },
              tooltip: {
                enabled: false,
              },
            },
          }}
        />
        {/* <div className="infoArea"> */}
        <div className="negativeToZero">
          <p>Risco</p>
          <span>(-100 a 0)</span>
        </div>
        <div className="zeroTo50">
          <p>Aperfeiçoamento</p>
          <span>(0 a 50)</span>
        </div>
        <div className="fiveTo75">
          <p>Qualidade</p>
          <span>(50 a 75)</span>
        </div>
        <div className="sevenTo100">
          <p>Excelência</p>
          <span>(75 a 100)</span>
        </div>
        {/* </div> */}
      </section>
      <section className="gridBox">
        <div
          className="gridChart"
          style={{
            gridTemplateColumns: `${totalNegative}% ${totalNeutral}% ${totalPositive}%`,
          }}
        >
          <div
            className="b-One"
            style={{ width: "100%", background: "#ff2e2c" }}
          >
            {totalNegative}%
          </div>
          <div
            className="b-Two"
            style={{ width: "100%", background: "#ffce56" }}
          >
            {totalNeutral}%
          </div>
          <div
            className="b-Three"
            style={{ width: "100%", background: "#19a262" }}
          >
            {totalPositive}%
          </div>
        </div>
      </section>
      <section className="labelSection">
        <div className="negative">
          <p>Detratores</p>
          <span>(0 a 6)</span>
        </div>
        <div className="neutral">
          <p>Neutros</p>
          <span>(7 e 8)</span>
        </div>
        <div className="positive">
          <p>Promotores</p>
          <span>(9 e 10)</span>
        </div>
      </section>
    </div>
  );
};

export default NpsChart;
