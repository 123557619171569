import { Pie } from "react-chartjs-2";
// eslint-disable-next-line
import { Chart as ChartJS } from "chart.js/auto";

const PieChart = ({ data, plugins }) => {
  const options = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: {
        left: 54,
        right: 50,
        top: 50,
        bottom: 50,
      },
    },
    plugins: {
      tooltip: {
        enabled: false,
      },
      datalabels: {
        align: "end",
        anchor: "end",
        clamp: true,
        color: "#000",
        offset: 4,
        font: {
          size: 12,
        },
        formatter: (value) => {
          return `${value}%`;
        },
      },
    },
  };

  return <Pie data={data} plugins={plugins} options={options} />;
};

export default PieChart;
