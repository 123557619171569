import React, { useEffect, useState } from "react";
import Arrow from "../../Assets/img/up-arrow.svg";
import "./style.scss";

export default function Api() {
  const [showButton, setShowButton] = useState(false);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    if (window.pageYOffset > 20) {
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <div className="compDocumentation">
      <div className="documentation">
        <h1>API Documentation Hazo</h1>
        <h2>Introduction</h2>
        <p>
          This documentation is all about Hazo! Here, you can efficiently manage
          your campaigns (chatbots) and effortlessly create dynamic links when
          you have a list of clients, for example.
        </p>
        <h2>Authorization</h2>
        <p>
          To access our APIs, you need to request a token. To obtain a token,
          please contact us at hazo@hazo.app and submit your request. The
          authentication method for all APIs is Bearer.
        </p>
        <h2>API's disponiveis</h2>
        <ul>
          {/* <li>
            <a href="#answers">Answers</a>
          </li> */}
          <li>
            <a href="#campaigns">Campaigns</a>
          </li>
          {/* <li>
            <a href="#controllers">Controllers</a>
          </li> */}
          <li>
            <a href="#links">Links</a>
          </li>
        </ul>

        {/* <div className="api-section" id="answers">
          <h2>Answers (In development)</h2>

          <h3>API Endpoint:</h3>
          <p>https://api.hazo.app/public/answers</p>

          <h3>Description:</h3>
          <p>This API retrieves a list of answers.</p>

          <h3>Method:</h3>
          <p>GET</p>

          <h3>Request Parameters:</h3>
          <ul>
            <li>query (required): The query for which to retrieve answers.</li>
            <li>
              limit (optional): The maximum number of answers to retrieve
              (default is 10).
            </li>
          </ul>

          <h3>Response:</h3>
          <p>
            The API returns a list of answers, along with their relevance
            scores.
          </p>

          <h3>Example Request:</h3>
          <pre></pre>

          <h3>Example Response:</h3>
          <pre></pre>
        </div>
         */}
        <div className="api-section" id="campaigns">
          <h2>/Campaigns</h2>

          <h4>API Endpoint:</h4>
          <p>https://api.hazo.app/public/campaigns/</p>

          <h4>Description:</h4>
          <p>This API retrieves all campaigns from user.</p>

          <h4>Method:</h4>
          <p>GET</p>

          <h4>Response:</h4>
          <p>The API returns all campaigns.</p>

          <h4>Example Request:</h4>
          <pre>
            GET https://api.hazo.app/public/campaigns/
            {`\n`}HTTP/1.1 Authorization: Bearer
            eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...
          </pre>

          <h4>Example Response:</h4>
          <pre>
            {`{`}
            {`\n\t`}data:["abcdef","fedcba"]
            {`\n}`}
          </pre>
        </div>

        {/* <div className="api-section" id="controllers">
          <h2>Controllers (In development)</h2>

          <h3>API Endpoint:</h3>
          <p>https://api.hazo.app/public/controllers/</p>

          <h3>Description:</h3>
          <p>
            This API retrieves a list of all controllers of specific campaign.
          </p>
          <h3>Method:</h3>
          <p>GET</p>

          <h3>URL Parameters:</h3>
          <ul>
            <li>campaign (required): The user email campaign.</li>
          </ul>

          <h3>Response:</h3>
          <p>The API returns a list of controllers.</p>

          <h3>Example Request:</h3>
          <pre></pre>

          <h3>Example Response:</h3>
          <pre></pre>
        </div>
         */}
        <div className="api-section" id="links">
          <h2>/Link</h2>

          <h4>API Endpoint:</h4>
          <p>https://api.hazo.app/public/link/</p>

          <h4>Description:</h4>
          <p>This API creates a new trackable link of chatbot.</p>

          <h4>Method:</h4>
          <p>POST</p>

          <h4>Request Parameters:</h4>
          <ul>
            <li>
              id (required): The id interger to identify the link. (limit 50
              digits)
            </li>
            <li>campaign (required): The campaign.</li>
            <li>
              custom_field_1 (optional): Anything data to store. (limit 50
              characters)
            </li>
            <li>
              custom_field_2 (optional): Anything data to store. (limit 50
              characters)
            </li>
            <li>
              custom_field_3 (optional): Anything data to store. (limit 50
              characters)
            </li>
            <li>
              custom_field_4 (optional): Anything data to store. (limit 50
              characters)
            </li>
            <li>
              custom_field_5 (optional): Anything data to store. (limit 50
              characters)
            </li>
          </ul>

          <h4>Response:</h4>
          <p>The API returns the newly created link.</p>

          <h4>Example Request:</h4>
          <pre>
            POST https://api.hazo.app/public/link/ HTTP/1.1
            {`\n`}Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9...
            {`\n`}Content-Type: application/json
            {`\n{`}
            {`\n\t`}"id": 123456789,
            {`\n\t`}"campaign": "example",
            {`\n\t`}"custom_field_1": "anything",
            {`\n\t`}"custom_field_2": "anything",
            {`\n\t`}"custom_field_3": "anything",
            {`\n\t`}"custom_field_4": "anything",
            {`\n\t`}"custom_field_5": "anything"
            {`\n}`}
          </pre>

          <h4>Example Response:</h4>
          <pre>
            {`{`}
            {`\n\t`}data:"https://dev.hazo.me/example/00_example_123456789"
            {`\n}`}
          </pre>
        </div>
      </div>
      {showButton && (
        <button id="scroll-to-top-btn" onClick={scrollToTop}>
          <img src={Arrow} alt="arrow up" />
        </button>
      )}
    </div>
  );
}
