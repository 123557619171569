import { firebaseAuth, firebaseFirestore } from "../Config/firebase.js";
import firebase from "firebase/app";

export default class UserService {
  static getCurrentUserEmail = () => {
    return firebaseAuth.currentUser.email;
  };

  static getUserInformation = async () => {
    let userEmail = await this.getCurrentUserEmail();

    let querySnapshot = await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get();
    let dataToReturn = null;

    querySnapshot.forEach((document) => {
      dataToReturn = document.data();
    });

    return dataToReturn;
  };

  static setAccountPlan = async (typePlan, idSubscriptionPaypal, planType) => {
    let userEmail = await this.getCurrentUserEmail();

    await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get()
      .then((snap) => {
        snap.forEach(async (document) => {
          let docRef = document.id;
          let newDateAt = new Date();
          let newDateUntil = new Date();

          if (planType === "year") {
            newDateUntil.setMonth(newDateUntil.getMonth() + 12);
          } else {
            newDateUntil.setMonth(newDateUntil.getMonth() + 1);
          }

          await firebaseFirestore.collection("users").doc(docRef).update({
            subscription: typePlan,
            subscription_at: newDateAt,
            subscription_until: newDateUntil,
            id_subscription_paypal: idSubscriptionPaypal,
          });
        });
      });
  };

  static resetAccountPlan = async () => {
    let userEmail = await this.getCurrentUserEmail();
    await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get()
      .then((snap) => {
        snap.forEach(async (document) => {
          let docRef = document.id;
          await firebaseFirestore.collection("users").doc(docRef).update({
            subscription: "Free",
            subscription_at: firebase.firestore.FieldValue.delete(),
            subscription_until: firebase.firestore.FieldValue.delete(),
            id_subscription_paypal: firebase.firestore.FieldValue.delete(),
          });
        });
      });
  };

  // Set false for new users
  static updateNewUser = async () => {
    let userEmail = await this.getCurrentUserEmail();
    await firebaseFirestore
      .collection("users")
      .where("email", "==", userEmail)
      .get()
      .then((snap) => {
        snap.forEach(async (document) => {
          let docRef = document.id;
          await firebaseFirestore.collection("users").doc(docRef).update({
            isNewUser: false,
          });
        });
      });
  };
}
