import React from "react";
import "./style.scss";
import Camaleao from "../../Assets/img/lettering-hazo.svg";

const HeaderResults = () => {
  return (
    <header className="HeaderResults">
      <div className="containerWidth">
        <section className="containerHeaderMain">
          <div className="contentHeader">
            <div className="logoArea">
              <img alt="business" className="logo" src={Camaleao} />
            </div>
            <div className="titleBody">
              <div className="titleHeaderMain">Análise de Dados</div>
            </div>
          </div>
        </section>
      </div>
    </header>
  );
};

export default HeaderResults;
