import { Modal, ModalHeader } from "reactstrap";
import Descending from "../../../Assets/img/iconsSort/descendingOrder.png";
import Ascending from "../../../Assets/img/iconsSort/ascendingOrder.png";
import Reset from "../../../Assets/img/iconsSort/reset.png";
import zToA from "../../../Assets/img/iconsSort/zToA.png";
import aToZ from "../../../Assets/img/iconsSort/aToZ.png";
function ModalSortData({
  openModal,
  setOpenModal,
  sortOrder,
  setSortOrder,
  questionString,
  sortType,
}) {
  const toggle = () => {
    setOpenModal(!openModal);
  };

  const handleSortOrder = (btnValue, newSortType) => {
    const filtered = sortOrder.filter(
      (x) => x.questionString !== questionString
    );
    setSortOrder([
      ...filtered,
      { questionString, btnValue, sortType: newSortType },
    ]);
    toggle();
  };

  return (
    <Modal
      className="compModalPresets"
      isOpen={openModal}
      centered={true}
      toggle={toggle}
    >
      <ModalHeader toggle={toggle}></ModalHeader>
      <div className="content">
        <div className="containerPreset">
          <div className="boxPresets">
            <div
              className="preset"
              onClick={() =>
                handleSortOrder(
                  "down",
                  sortType === "numerical" ? "numerical" : "alphabetic"
                )
              }
            >
              <img
                src={sortType === "numerical" ? Ascending : aToZ}
                alt="Ordem crescente"
              />
              <div className="groupTitles">
                <p className="pTitle">Ordem Crescente</p>
              </div>
            </div>
            <div
              className="preset"
              onClick={() =>
                handleSortOrder(
                  "up",
                  sortType === "numerical" ? "numerical" : "alphabetic"
                )
              }
            >
              <img
                src={sortType === "numerical" ? Descending : zToA}
                alt="Ordem decrescente"
              />
              <div className="groupTitles">
                <p className="pTitle">Ordem Decrescente</p>
              </div>
            </div>
            <div className="preset" onClick={() => handleSortOrder("reset")}>
              <img src={Reset} alt="Ordem decrescente" />
              <div className="groupTitles">
                <p className="pTitle">Ordem Original</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ModalSortData;
