import { firebaseFirestore } from "../Config/firebase.js";

export default class TemplateService {
  static getTemplates = async () => {
    let dataToReturn = [];
    await firebaseFirestore
      .collection("templates")
      .get()
      .then((snap) => {
        snap.forEach((document) => {
          dataToReturn.push(document.data());
        });
      });

    return dataToReturn;
  };
}
